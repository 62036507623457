import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {API_CALL, EMPTY_REPORT, Incident, Order, Report, Worker} from "../../types";
import {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    onlyPozitive,
    showErrorsInToast,
    textToFloat,
    translateError
} from "../../common";
import {API} from "../../api";
import {InputMachineFormProps} from "./InputPage";
import moment from "moment";
import {WorkersListComponent} from "../config/common/WorkersListComponent";
import {OrdersListComponent} from "./common/OrdersListComponent";
import {IncidentsListComponent} from "./common/IncidentsListComponent";
import {UpdateConfigurationButton} from "./common/UpdateConfigurationButton";
import {DeleteReportButton} from "./common/DeleteReportButton";
import {MoveReportButton} from "./common/MoveReportButton";
import {AlpaMachineReport, EMPTY_ALPAMACHINE_REPORT} from "./InputAlpaMachineForm";

export default function InputAlpaMachineFormOld({
                                                    viewMode,
                                                    report_date,
                                                    shift,
                                                    machine,
                                                    onReload
                                                }: InputMachineFormProps) {
    const _dateString = moment(report_date).format('DD.MM.YYYY');
    const [working, setWorking] = useState(false);
    const [loading, setLoading] = useState(false);
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [reportData, setReportData] = useState<AlpaMachineReport>({...EMPTY_ALPAMACHINE_REPORT});
    const [report, setReport] = useState<Report>({...EMPTY_REPORT});
    const [comments, setComments] = useState<string>('');


    useEffect(() => {
        if (!loading) {
            API.getReport(
                (apiCall: API_CALL) => {
                    setLoading(isLoading(apiCall));
                    if (isSuccess(apiCall)) {
                        const r = apiCall.data.data.report;
                        const rd = JSON.parse(r.request_data);
                        setWorkers(rd.workers || []);
                        setIncidents(rd.incidents || []);
                        setOrders(rd.orders || []);
                        setReportData(rd.data || {...EMPTY_ALPAMACHINE_REPORT});
                        setReport(r);
                        setComments(rd.comments);
                    }
                    if (isError(apiCall)) {
                        setWorkers([]);
                        setIncidents([]);
                        setOrders([]);
                        setReportData({...EMPTY_ALPAMACHINE_REPORT});
                        setReport({...EMPTY_REPORT});
                        setComments('');
                    }
                }, machine.id, shift.id, moment(report_date).format('YYYY-MM-DD')
            )
        }
    }, [])


    function saveReport() {
        const _r: any = {
            id: report.id,
            report_date: moment(report_date).format('YYYY-MM-DD'),
            machine: machine,
            shift: shift,
            man_cnt: workers.length,
            request_data: {
                data: reportData,
                workers: workers,
                orders: orders,
                incidents: incidents,
                comments: comments
            },
        };

        API.saveReport(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    onReload();
                }
            }, _r
        );
    }

    return machine.name !== 'Alpa' ? null :
        (
            <Card>
                <Card.Body className={"max-h-60vh scrollable"}>
                    {
                        loading ? <Spinner animation="border"/> :
                            <>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Брой стъкла</Form.Label>
                                                        <Form.Control type="number"
                                                                      value={reportData.glassCnt || ''}
                                                                      readOnly={viewMode}
                                                                      onChange={(e) => {
                                                                          setReportData({
                                                                              ...reportData,
                                                                              glassCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                          })
                                                                      }}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Л.м. кантиране правилни форми</Form.Label>
                                                        <Form.Control type="number"
                                                                      value={reportData.goodShapesLength || ''}
                                                                      readOnly={viewMode}
                                                                      onChange={(e) => {
                                                                          setReportData({
                                                                              ...reportData,
                                                                              goodShapesLength: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                          })
                                                                      }}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Л.м. кантиране правилни форми неполиран
                                                            кант</Form.Label>
                                                        <Form.Control type="number"
                                                                      value={reportData.goodShapesNotPolishLength || ''}
                                                                      readOnly={viewMode}
                                                                      onChange={(e) => {
                                                                          setReportData({
                                                                              ...reportData,
                                                                              goodShapesNotPolishLength: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                          })
                                                                      }}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Л.м. кантиране неправилни форми</Form.Label>
                                                        <Form.Control type="number"
                                                                      value={reportData.badShapesLength || ''}
                                                                      readOnly={viewMode}
                                                                      onChange={(e) => {
                                                                          setReportData({
                                                                              ...reportData,
                                                                              badShapesLength: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                          })
                                                                      }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Брой отвори до Ø200 мм</Form.Label>
                                                        <Form.Control type="number"
                                                                      value={reportData.smallHolesCnt || ''}
                                                                      readOnly={viewMode}
                                                                      onChange={(e) => {
                                                                          setReportData({
                                                                              ...reportData,
                                                                              smallHolesCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                          })
                                                                      }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Брой отвори над Ø200 мм</Form.Label>
                                                        <Form.Control type="number"
                                                                      value={reportData.bigHolesCnt || ''}
                                                                      readOnly={viewMode}
                                                                      onChange={(e) => {
                                                                          setReportData({
                                                                              ...reportData,
                                                                              bigHolesCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                          })
                                                                      }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Брой вътрешен нестандартен изрез до 200
                                                            мм</Form.Label>
                                                        <Form.Control type="number"
                                                                      value={reportData.innerNotStandartCnt || ''}
                                                                      readOnly={viewMode}
                                                                      onChange={(e) => {
                                                                          setReportData({
                                                                              ...reportData,
                                                                              innerNotStandartCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                          })
                                                                      }}
                                                        />
                                                    </Form.Group>
                                                    {/*<Form.Group className="mb-3">*/}
                                                    {/*    <Form.Label>Брой вътрешен нестандартен изрез над Ø110 мм</Form.Label>*/}
                                                    {/*    <Form.Control type="number"*/}
                                                    {/*                  value={reportData.innerNotStandartBigCnt || ''}*/}
                                                    {/*                  readOnly={viewMode}*/}
                                                    {/*                  onChange={(e) => {*/}
                                                    {/*                      setReportData({*/}
                                                    {/*                          ...reportData,*/}
                                                    {/*                          innerNotStandartBigCnt: onlyPozitive(textToFloat(e.target.value)) || 0*/}
                                                    {/*                      })*/}
                                                    {/*                  }}*/}
                                                    {/*    />*/}
                                                    {/*</Form.Group>*/}
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Брой външен стандартен изрез (панти) до 100
                                                            мм</Form.Label>
                                                        <Form.Control type="number"
                                                                      value={reportData.outerStandartSmallCnt || ''}
                                                                      readOnly={viewMode}
                                                                      onChange={(e) => {
                                                                          setReportData({
                                                                              ...reportData,
                                                                              outerStandartSmallCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                          })
                                                                      }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Брой външен стандартен изрез (панти) над 100
                                                            мм</Form.Label>
                                                        <Form.Control type="number"
                                                                      value={reportData.outerStandartBigCnt || ''}
                                                                      readOnly={viewMode}
                                                                      onChange={(e) => {
                                                                          setReportData({
                                                                              ...reportData,
                                                                              outerStandartBigCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                          })
                                                                      }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Л.м. изрез неправилна форма (сложни)</Form.Label>
                                                        <Form.Control type="number"
                                                                      value={reportData.hardShapesCnt || ''}
                                                                      readOnly={viewMode}
                                                                      onChange={(e) => {
                                                                          setReportData({
                                                                              ...reportData,
                                                                              hardShapesCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                          })
                                                                      }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Брой почиствания на масата</Form.Label>
                                                        <Form.Control type="number"
                                                                      value={reportData.workTableCleaningCnt || ''}
                                                                      readOnly={viewMode}
                                                                      onChange={(e) => {
                                                                          setReportData({
                                                                              ...reportData,
                                                                              workTableCleaningCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                          })
                                                                      }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Продължителност на смяната, Часове</Form.Label>
                                                        <Form.Control type="number"
                                                                      value={reportData.workHours || ''}
                                                                      readOnly={viewMode}
                                                                      onChange={(e) => {
                                                                          setReportData({
                                                                              ...reportData,
                                                                              workHours: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                          })
                                                                      }}
                                                        />
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col sm={12}>
                                                        <WorkersListComponent workers={workers} defWorkHours={8}
                                                                              forDate={moment(report_date).format('YYYY-MM-DD')}
                                                                              showWorkHours={true} viewMode={viewMode}
                                                                              onChange={(workers) => setWorkers(workers)}/>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <OrdersListComponent dim={'л.м.'} orders={orders}
                                                                             viewMode={viewMode}
                                                                             onChange={(orders => setOrders(orders))}/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <IncidentsListComponent incidents={incidents} machine={machine}
                                                                viewMode={viewMode}
                                                                onChange={(incidents) => setIncidents(incidents)}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className={"mt-3"}>
                                        <Form.Group className={" bg-secondary-light p-2"}>
                                            <Form.Label className={"text-primary font-weight-bold text-center w-100"}>Коментари
                                                и забележки</Form.Label>
                                            <Form.Control as={"textarea"}
                                                          value={comments || ''}
                                                          readOnly={viewMode}
                                                          onChange={(e) => setComments(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                    }
                </Card.Body>
                <Card.Footer>
                    {
                        !viewMode && (
                            working ? <Spinner animation="border"/> :
                                <>
                                    <Button onClick={() => saveReport()}><FontAwesomeIcon
                                        icon={faSave}/> Запази</Button>

                                    <span className={"float-right"}>
                                                <UpdateConfigurationButton reportId={report.id}/>
                                                <DeleteReportButton reportId={report.id} onDelete={onReload}/>
                                                <MoveReportButton reportId={report.id} onMoved={onReload}/>
                                            </span>
                                </>
                        )
                    }
                </Card.Footer>
            </Card>
        )
}

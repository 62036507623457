import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {API_CALL, EMPTY_REPORT, Incident, Order, Report, Worker} from "../../types";
import {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    onlyPozitive,
    showErrorsInToast,
    textToFloat,
    translateError
} from "../../common";
import {API} from "../../api";
import {InputMachineFormProps} from "./InputPage";
import moment from "moment";
import {WorkersListComponent} from "../config/common/WorkersListComponent";
import {OrdersListComponent} from "./common/OrdersListComponent";
import {IncidentsListComponent} from "./common/IncidentsListComponent";
import {UpdateConfigurationButton} from "./common/UpdateConfigurationButton";
import {DeleteReportButton} from "./common/DeleteReportButton";
import {MoveReportButton} from "./common/MoveReportButton";


export interface ProfileMachineReport {
    polishLength: number;
    notPolishLength: number;
    polishFormLength: number;
    notPolishFormLength: number;
    faskaLength: number;
    diffSizeCnt: number;
    detailsLifterCnt: number;
    workHours: number;
}

export const EMPTY_PROFILEMACHINE_REPORT = {
    polishLength: 0, notPolishLength: 0, polishFormLength: 0, notPolishFormLength: 0, faskaLength: 0, diffSizeCnt: 0, detailsLifterCnt: 0, workHours: 8
}

export default function InputProfileMachineForm({viewMode, report_date, shift, machine, onReload}: InputMachineFormProps) {
    const _dateString = moment(report_date).format('DD.MM.YYYY');
    const [working, setWorking] = useState(false);
    const [loading, setLoading] = useState(false);
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [reportData, setReportData] = useState<ProfileMachineReport>(EMPTY_PROFILEMACHINE_REPORT);
    const [report, setReport] = useState<Report>(EMPTY_REPORT);
    const [comments, setComments] = useState<string>('');


    useEffect(() => {
        if(!loading) {
            API.getReport(
                (apiCall: API_CALL) => {
                    setLoading(isLoading(apiCall));
                    if (isSuccess(apiCall)) {
                        const r = apiCall.data.data.report;
                        const rd = JSON.parse(r.request_data);
                        setWorkers(rd.workers || []);
                        setIncidents(rd.incidents || []);
                        setOrders(rd.orders || []);
                        setReportData(rd.data || EMPTY_PROFILEMACHINE_REPORT);
                        setReport(r);
                        setComments(rd.comments);
                    }
                    if (isError(apiCall)) {
                        setWorkers([]);
                        setIncidents([]);
                        setOrders([]);
                        setReportData(EMPTY_PROFILEMACHINE_REPORT);
                        setReport(EMPTY_REPORT);
                        setComments('');
                    }
                }, machine.id, shift.id, moment(report_date).format('YYYY-MM-DD')
            )
        }
    }, [])


    function saveReport() {
        const _r: any = {
            id: report.id,
            report_date: moment(report_date).format('YYYY-MM-DD'),
            machine: machine,
            shift: shift,
            man_cnt: workers.length,
            request_data: {data: reportData, workers: workers, orders: orders, incidents: incidents, comments: comments},
        };

        API.saveReport(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    onReload();
                }
            }, _r
        );
    }

    return machine.name !== 'Profile' ? null :
        (
            <Modal show={true} size={"xl"} >
                <Modal.Header>
                    <Row className={"w-100"}>
                        <Col>
                            <Modal.Title>
                                <Row>
                                    <Col><span>Отчет за <strong
                                        className={"text-primary"}>{machine.name}</strong></span></Col>
                                    <Col className={"text-right"} xs={"auto"}>
                                <span className={"font-weight-light small"}><i><b><span
                                    className={"mr-3"}>{_dateString}</span><span>{shift.name}</span></b></i></span></Col>
                                </Row>
                            </Modal.Title>
                        </Col>
                        <Col xs={"auto"}><CloseButton onClick={onReload}/></Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body className={"max-h-60vh scrollable"}>
                            {
                                loading ? <Spinner animation="border"/> :
                                    <>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Form>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Л.м. полиран материал</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.polishLength || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      polishLength: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Л.м. неполиран материал</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.notPolishLength || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      notPolishLength: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Л.м. полиран кант форми</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.polishFormLength || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      polishFormLength: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Л.м. неполиран кант форми</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.notPolishFormLength || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      notPolishFormLength: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Л.м. фаски</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.faskaLength || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      faskaLength: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            {/*<Form.Group className="mb-3">*/}
                                                            {/*    <Form.Label>Брой различни размери</Form.Label>*/}
                                                            {/*    <Form.Control type="number"*/}
                                                            {/*                  value={reportData.diffSizeCnt || ''}*/}
                                                            {/*                  onChange={(e) => {*/}
                                                            {/*                      setReportData({*/}
                                                            {/*                          ...reportData,*/}
                                                            {/*                          diffSizeCnt: onlyPozitive(textToFloat(e.target.value))||0*/}
                                                            {/*                      })                                                                      }}*/}
                                                            {/*    />*/}
                                                            {/*</Form.Group>*/}
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Брой детайли с кран</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.detailsLifterCnt || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      detailsLifterCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Продължителност на смяната, Часове</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.workHours || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      workHours: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                        </Form>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <WorkersListComponent workers={workers} defWorkHours={8}
                                                                                      forDate={moment(report_date).format('YYYY-MM-DD')}
                                                                                      showWorkHours={true} viewMode={viewMode}
                                                                                      onChange={(workers) => setWorkers(workers)}/>
                                                            </Col>
                                                            <Col sm={12}>
                                                                <OrdersListComponent dim={'л.м.'} orders={orders} viewMode={viewMode}
                                                                                     onChange={(orders => setOrders(orders))}/>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <IncidentsListComponent incidents={incidents} machine={machine} viewMode={viewMode}
                                                                        onChange={(incidents) => setIncidents(incidents)}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className={"mt-3"}>
                                                <Form.Group className={" bg-secondary-light p-2"}>
                                                    <Form.Label className={"text-primary font-weight-bold text-center w-100"}>Коментари и забележки</Form.Label>
                                                    <Form.Control as={"textarea"}
                                                                  value={comments || ''}
                                                                  readOnly={viewMode}
                                                                  onChange={(e) => setComments(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                            }
                        </Card.Body>
                        <Card.Footer>
                            {
                                !viewMode && (
                                    working ? <Spinner animation="border"/> :
                                        <>
                                            <Button onClick={() => saveReport()}><FontAwesomeIcon
                                                icon={faSave}/> Запази</Button>

                                            <span className={"float-right"}>
                                                <UpdateConfigurationButton reportId={report.id}/>
                                                <DeleteReportButton reportId={report.id} onDelete={onReload} />
                                                <MoveReportButton reportId={report.id} onMoved={onReload} />
                                            </span>
                                        </>
                                )
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>
        )
}

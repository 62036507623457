import {API_CALL, NET_STATUS} from "./types";

export class VALIDATION_CLASS {
    public validateLoginData(apiCall: API_CALL, email: string, password: string): boolean {
        apiCall.status = NET_STATUS.SUCCESS;
        apiCall.error = null;

        if (!email || email.trim().length === 0) {
            apiCall.status = NET_STATUS.ERROR;
            apiCall.error = [...apiCall.error, 'Липсва името на потребителя'];
        }
        if (!password || password.trim().length === 0) {
            apiCall.status = NET_STATUS.ERROR;
            apiCall.error = [...apiCall.error, 'Липсва паролата на потребителя'];
        }

        return apiCall.status === NET_STATUS.SUCCESS;
    }
}

export const VALIDATION = new VALIDATION_CLASS();

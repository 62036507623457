import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import React, {useState} from "react";
import {ConfirmModal} from "../../../common/ConfirmModal";
import {API} from "../../../api";
import {API_CALL} from "../../../types";
import {isError, isSuccess, showErrorsInToast, showMsgInToast, translateError} from "../../../common";

export interface UpdateConfigurationButtonProps {
    reportId: number
}

export function UpdateConfigurationButton({reportId}: UpdateConfigurationButtonProps) {
    const [showConfirm, setShowConfirm] = useState(false);
    const updateConfig = function(btn: string) {
        setShowConfirm(false);
        if(btn === "Потвърждавам") {
            API.updateReportConfig(
                (apiCall: API_CALL) => {
                    if(isSuccess(apiCall)) {
                        showMsgInToast("Конфигурацията е актуализирана успешно!");
                    }
                    if(isError(apiCall)) {
                        showErrorsInToast(apiCall, "Възникна грешка", translateError);
                    }
                }, reportId
            )
        }
    }

    if(reportId === -1 || API.user?.is_admin === 'N') return null;

    return (
        <>
            <Button onClick={() => setShowConfirm(true)}><FontAwesomeIcon
                icon={faSync}/> Актуализирай конфигурацията</Button>

            {
                showConfirm &&
                <>
                    <ConfirmModal
                        title={"Внимание"}
                        message={"Моля, потвърдете актуализирането на конфигурацията на този отчет!"}
                        buttons={["Потвърждавам", "Отказ"]}
                        primaryBtn={"Отказ"}
                        onButtonClick={updateConfig}
                        onClose={() => setShowConfirm(false)}
                    />
                </>
            }
        </>

    )
}
import ModalHeader from "react-bootstrap/ModalHeader";
import {
    Button,
    CloseButton,
    Col, Dropdown,
    Modal,
    ModalBody,
    ModalFooter,
    ModalTitle,
    Row, Spinner,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import {ReadOnlyDatePickerComponent} from "../../common/ReadOnlyDatePickerComponent";
import {
    dateToString,
    getShiftName,
    isLoading,
    isSuccess,
    zeroToEmptyString,
    zeroToEmptyStringAsNumber
} from "../../common";
import React, {useEffect, useState} from "react";
import {faCalculator, faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../../api";
import {API_CALL} from "../../types";
import moment from "moment";
import InputCutMachineForm from "../input/InputCutMachineForm";
import InputVXMachineForm from "../input/InputVXMachineForm";
import InputVEMachineForm from "../input/InputVEMachineForm";
import InputPackageMachineForm from "../input/InputPackageMachineForm";
import InputLaminationMachineForm from "../input/InputLaminationMachineForm";
import InputProfileMachineForm from "../input/InputProfileMachineForm";
import InputOvenMachineForm from "../input/InputOvenMachineForm";
import InputVertecMachineForm from "../input/InputVertecMachineForm";
import InputOtherActivitiesForm from "../input/InputOtherActivitiesForm";
import ExportButton from "../../common/ExportButton";


export interface OutputDetailedVXModalProps {
    onHide: () => any;
}


function ReportRow({dayData}: { dayData: any }) {
    console.log(dayData);
    return (
        <>
            <td>{dayData["1"].longSidesLength}</td>
            <td>{dayData["1"].longSidesPolishLength}</td>
            <td>{dayData["1"].diffSizeCnt}</td>
            <td>{dayData["1"].detailsRobotCnt}</td>
            <td>{dayData["1"].totalLength}</td>
            <td>{dayData["1"].workHours}</td>
            <td>{dayData["2"].longSidesLength}</td>
            <td>{dayData["2"].longSidesPolishLength}</td>
            <td>{dayData["2"].diffSizeCnt}</td>
            <td>{dayData["2"].detailsRobotCnt}</td>
            <td>{dayData["2"].totalLength}</td>
            <td>{dayData["2"].workHours}</td>
            <td>{dayData["3"].longSidesLength}</td>
            <td>{dayData["3"].longSidesPolishLength}</td>
            <td>{dayData["3"].diffSizeCnt}</td>
            <td>{dayData["3"].detailsRobotCnt}</td>
            <td>{dayData["3"].totalLength}</td>
            <td>{dayData["3"].workHours}</td>
            <td>{dayData["4"].longSidesLength}</td>
            <td>{dayData["4"].longSidesPolishLength}</td>
            <td>{dayData["4"].diffSizeCnt}</td>
            <td>{dayData["4"].detailsRobotCnt}</td>
            <td>{dayData["4"].totalLength}</td>
            <td>{dayData["4"].workHours}</td>
        </>
    )
}

export default function OutputDetailedVXModal({onHide}: OutputDetailedVXModalProps) {
    const now = new Date();
    const yearBefore = new Date();
    yearBefore.setFullYear(now.getFullYear() - 1);

    const [reportDateFrom, setReportDateFrom] = useState<Date>(yearBefore);
    const [reportDateTo, setReportDateTo] = useState<Date>(now);
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState<any>(null);

    function TableHeader() {
        return (
            <thead className={"center-content"}>
            <tr>
                <th rowSpan={2}>Дата</th>
                <th colSpan={6}>Първа смяна</th>
                <th colSpan={6}>Втора смяна</th>
                <th colSpan={6}>Редовна смяна</th>
                <th colSpan={6}>Общо за деня</th>
            </tr>
            <tr>
                <th title="Л.м. на дългите страни - неполиран/полиран кант">Н/П</th>
                <th title="Л.м. на дългите страни - триплекс полиран кант">ТР</th>
                <th title="Брой различни размери">РР</th>
                <th title="Брой стъкла с кран">СК</th>
                <th title="Общо л.м.">Общо</th>
                <th title="Работни часове">РЧ</th>
                <th title="Л.м. на дългите страни - неполиран/полиран кант">Н/П</th>
                <th title="Л.м. на дългите страни - триплекс полиран кант">ТР</th>
                <th title="Брой различни размери">РР</th>
                <th title="Брой стъкла с кран">СК</th>
                <th title="Общо л.м.">Общо</th>
                <th title="Работни часове">РЧ</th>
                <th title="Л.м. на дългите страни - неполиран/полиран кант">Н/П</th>
                <th title="Л.м. на дългите страни - триплекс полиран кант">ТР</th>
                <th title="Брой различни размери">РР</th>
                <th title="Брой стъкла с кран">СК</th>
                <th title="Общо л.м.">Общо</th>
                <th title="Работни часове">РЧ</th>
                <th title="Л.м. на дългите страни - неполиран/полиран кант">Н/П</th>
                <th title="Л.м. на дългите страни - триплекс полиран кант">ТР</th>
                <th title="Брой различни размери">РР</th>
                <th title="Брой стъкла с кран">СК</th>
                <th title="Общо л.м.">Общо</th>
                <th title="Работни часове">РЧ</th>
            </tr>
            </thead>
        )
    }

    const reload = function () {
        if (loading) return;
        API.getDetailedVX(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    setReport(apiCall.data.data.report);
                }
            }, moment(reportDateFrom).format('YYYY-MM-DD'),
                moment(reportDateTo).format('YYYY-MM-DD')
        );
    }

    useEffect(() => {
        reload()
    }, [reportDateFrom, reportDateTo]);

    return (
        <>
            <Modal onHide={() => onHide()} show={true} dialogClassName={"w-100 p-3 max-w-none"}>
                <ModalHeader>
                    <Row className={"w-100"}>
                        <Col>
                            <ModalTitle>Подробна справка за VX</ModalTitle>
                        </Col>
                        <Col className={"justify-content-end"} xs={"auto"}>
                            <Row className={"flex-nowrap"}>
                                <Col>
                                    <ReadOnlyDatePickerComponent onChange={(date: Date) => {
                                        setReportDateFrom(date)
                                    }} value={dateToString(reportDateFrom)}/>
                                </Col>
                                <Col>
                                    <ReadOnlyDatePickerComponent onChange={(date: Date) => {
                                        setReportDateTo(date)
                                    }} value={dateToString(reportDateTo)}/>
                                </Col>
                                <Col>
                                    <Button onClick={() => reload()} variant={"secondary"} size="sm" className={"mr-1"}
                                    title={"Презареди таблицата"}><FontAwesomeIcon
                                    icon={faSyncAlt}/></Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={"auto"}>
                            <CloseButton onClick={() => onHide()}/>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody style={{maxHeight: '70vh', overflow: 'auto'}}>
                    {
                        loading && <Spinner animation={"border"}/>
                    }
                    {
                        report && !loading &&
                        <div className={"table-responsive"} style={{maxHeight: '65vh'}}>

                            <div className="w-100 overflow-auto" style={{maxHeight: '60vh'}}>
                                <Table striped bordered hover size={"sm"} className={"small"} style={{height: '100%'}}
                                       id="dataTable">
                                    <TableHeader />
                                    <tbody className={"end-content"}>
                                    {
                                        Object.keys(report).map(
                                            date =>
                                                <tr key={date}>
                                                    <td>{date}</td>
                                                    <ReportRow dayData={report[date]} />
                                                </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    }
                    {
                        !loading && !report &&
                        <div><strong>Няма данни</strong></div>
                    }

                </ModalBody>
                <ModalFooter className={"justify-content-center"}>
                </ModalFooter>
            </Modal>
        </>
    )
}

import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {API_CALL, EMPTY_REPORT, Incident, Order, Report, Worker, WorkerActivity} from "../../types";
import {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    onlyPozitive,
    showErrorsInToast,
    textToFloat, textToInt,
    translateError
} from "../../common";
import {API} from "../../api";
import {InputMachineFormProps} from "./InputPage";
import moment from "moment";
import {WorkersListComponent} from "../config/common/WorkersListComponent";
import {OrdersListComponent} from "./common/OrdersListComponent";
import {IncidentsListComponent} from "./common/IncidentsListComponent";
import {UpdateConfigurationButton} from "./common/UpdateConfigurationButton";
import {DeleteReportButton} from "./common/DeleteReportButton";
import {MoveReportButton} from "./common/MoveReportButton";

export default function InputVertecMachineForm({
                                                   viewMode,
                                                   report_date,
                                                   shift,
                                                   machine,
                                                   onReload
                                               }: InputMachineFormProps) {
    const _dateString = moment(report_date).format('DD.MM.YYYY');
    const [working, setWorking] = useState(false);
    const [loading, setLoading] = useState(false);
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [activities, setActivities] = useState<WorkerActivity[]>([]);
    const [comments, setComments] = useState<string>('');
    const [report, setReport] = useState<Report>(EMPTY_REPORT);


    useEffect(() => {
        if (!loading) {
            API.getReport(
                (apiCall: API_CALL) => {
                    setLoading(isLoading(apiCall));
                    if (isSuccess(apiCall)) {
                        const r = apiCall.data.data.report;
                        const rd = JSON.parse(r.request_data);
                        setWorkers(rd.workers || []);
                        setActivities(rd.activities || []);
                        setComments(rd.comments);
                        setReport(r);
                    }
                    if (isError(apiCall)) {
                        setWorkers([]);
                        setComments('');
                        setReport(EMPTY_REPORT);
                    }
                }, machine.id, shift.id, moment(report_date).format('YYYY-MM-DD')
            )
        }
    }, [])




    function saveReport() {
        const _r: any = {
            id: report.id,
            report_date: moment(report_date).format('YYYY-MM-DD'),
            machine: machine,
            shift: shift,
            man_cnt: workers.length,
            request_data: {
                data: null,
                workers: workers,
                activities: activities.filter(a => {
                    return workers.findIndex(w => w.id === a.id) > -1
                }),
                comments: comments
            },
        };

        API.saveReport(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    onReload();
                }
            }, _r
        );
    }

    function getWorkerActivity(id: number) {
        const a = activities.find(a => a.id === id);
        return a ? a.name : '';
    }

    function setWorkerActivity(id: number, value: string) {
        const a = activities.find(a => a.id === id);
        if(a) a.name = value; else activities.push({id: id, name: value});

        setActivities([...activities]);
    }

    return machine.name !== 'Други дейности' ? null :
        (
            <Modal show={true} size={"lg"} >
                <Modal.Header>
                    <Row className={"w-100"}>
                        <Col>
                            <Modal.Title>
                                <Row>
                                    <Col xs={12}>
                                        <span>Отчет за <strong
                                        className={"text-primary"}>{machine.name}</strong></span></Col>
                                    <Col className={"text-right"} xs={"auto"}>
                                <span className={"font-weight-light"} style={{fontSize: "1rem"}} ><i><b><span
                                    className={"mr-3"}>{_dateString}</span><span>{shift.name}</span></b></i></span></Col>
                                </Row>
                            </Modal.Title>
                        </Col>
                        <Col xs={"auto"}><CloseButton onClick={onReload}/></Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body className={"max-h-60vh scrollable"}>
                            {
                                loading ? <Spinner animation="border"/> :
                                    <>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <WorkersListComponent workers={workers} defWorkHours={8}
                                                                                      forDate={moment(report_date).format('YYYY-MM-DD')}
                                                                                      viewMode={viewMode}
                                                                                      showWorkHours={true}
                                                                                      onChange={(workers) => setWorkers([...workers])}/>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className={"mt-3"}>
                                                <Form.Group className={" bg-secondary-light p-2"}>
                                                    <Form.Label
                                                        className={"text-primary font-weight-bold text-center w-100"}>Дейности</Form.Label>
                                                    <Table size={"sm"} className={"mb-1 w-100"}>
                                                        <thead>
                                                        <tr>
                                                            <th>Служител</th>
                                                            <th>Описание на извършената дейност</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            workers.length == 0 &&
                                                            <tr>
                                                                <td colSpan={2}>Няма избрани служители</td>
                                                            </tr>
                                                        }
                                                        {
                                                            workers.map(
                                                                w => <tr key={w.id}>
                                                                    <td>{ w.name }</td>
                                                                    <td>
                                                                        <Form.Control className={"w-100"}
                                                                                      value={getWorkerActivity(w.id)}
                                                                                      onChange={event => {
                                                                                          setWorkerActivity(w.id, event.target.value);
                                                                                      }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className={"mt-3"}>
                                                <Form.Group className={" bg-secondary-light p-2"}>
                                                    <Form.Label
                                                        className={"text-primary font-weight-bold text-center w-100"}>Коментари
                                                        и забележки</Form.Label>
                                                    <Form.Control as={"textarea"} readOnly={viewMode}
                                                                  value={comments || ''}
                                                                  onChange={(e) => setComments(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                            }
                        </Card.Body>
                        <Card.Footer>
                            {
                                !viewMode &&
                                (working ? <Spinner animation="border"/> :
                                        <>
                                            <Button onClick={() => saveReport()}><FontAwesomeIcon
                                                icon={faSave}/> Запази</Button>

                                            <span className={"float-right"}>
                                                <DeleteReportButton reportId={report.id} onDelete={onReload}/>
                                                <MoveReportButton reportId={report.id} onMoved={onReload}/>
                                            </span>
                                        </>
                                )
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>
        )
}

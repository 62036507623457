import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDolly} from "@fortawesome/free-solid-svg-icons";
import {Button, Card, CloseButton, Form, Modal, ModalTitle, Row, Spinner} from "react-bootstrap";
import React, {useState} from "react";
import {ConfirmModal} from "../../../common/ConfirmModal";
import {API} from "../../../api";
import {API_CALL} from "../../../types";
import {
    dateToString,
    getShiftName,
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    showMsgInToast,
    translateError
} from "../../../common";
import {BackgroundOverlay} from "./BackgroundOverlay";
import moment from "moment";
import {ReadOnlyDatePickerComponent} from "../../../common/ReadOnlyDatePickerComponent";

export interface MoveReportButtonProps {
    reportId: number
    onMoved: () => any
}

export function MoveReportButton({reportId, onMoved}: MoveReportButtonProps) {
    const [working, setWorking] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectNewDateShift, setSelectNewDateShift] = useState(false);

    const [newReportDate, setNewReportDate] = useState<Date>(moment().toDate());
    const [newReportShiftId, setNewReportShiftId] = useState<number>(1);

    const updateConfig = function (btn: string) {
        setShowConfirm(false);
        if (btn === "Потвърждавам") {
            API.updateReportConfig(
                (apiCall: API_CALL) => {
                    if (isSuccess(apiCall)) {
                        showMsgInToast("Цонфигурацията е актуализирана успешно!");
                    }
                    if (isError(apiCall)) {
                        showErrorsInToast(apiCall, "Възникна грешка", translateError);
                    }
                }, reportId
            )
        }
    }

    const moveReport = (btn: string) => {
        if(btn === "Потвърждавам")
            API.moveReport(
                (apiCall: API_CALL) => {
                    setWorking(isLoading(apiCall));

                    if(isSuccess(apiCall)) {
                        showMsgInToast('Преместването е успешно!');
                        onMoved();
                    }

                    if(isError(apiCall)) {
                        showErrorsInToast(apiCall, 'Грешка', translateError);
                    }
                }, reportId, newReportShiftId, moment(newReportDate).format('YYYY-MM-DD')
            )

        setShowConfirm(false)
    }


    if (reportId === -1) return null;

    if(working) return <Spinner animation={"border"} />

    return (
        <>
            &nbsp;<Button onClick={() => setSelectNewDateShift(true)}><FontAwesomeIcon
            icon={faDolly}/>&nbsp;&nbsp;Премести&nbsp;</Button>

            {
                showConfirm &&
                <>
                    <ConfirmModal
                        title={"Внимание"}
                        message={"Моля, потвърдете преместването на този отчет!"}
                        buttons={["Потвърждавам", "Отказ"]}
                        primaryBtn={"Отказ"}
                        onButtonClick={moveReport}
                        onClose={() => setShowConfirm(false)}
                    />
                </>
            }

            {
                selectNewDateShift &&
                <BackgroundOverlay>
                    <Modal show={true} onHide={() => setSelectNewDateShift(false)}
                           style={{marginTop: "25vh"}}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                Преместване на отчет
                            </Modal.Title>
                            <CloseButton onClick={() => setSelectNewDateShift(false)}/>
                        </Modal.Header>

                        <Modal.Body>
                            <Card>
                                <Card.Body>
                                    <div className={"mb-3"}>
                                        Изберете нова дата и смяна:
                                    </div>
                                    <Form>
                                        <Row>
                                            <ReadOnlyDatePickerComponent onChange={(date: Date) => {
                                                setNewReportDate(date)
                                            }} value={dateToString(newReportDate)}/>
                                        </Row>
                                        <Row>
                                            <Form.Control as="select" size={"sm"}
                                                          value={newReportShiftId}
                                                          onChange={(e) => setNewReportShiftId(parseInt(e.target.value))}
                                            >
                                                {
                                                    [1, 2, 3].map(
                                                        idx => <option key={idx} value={idx}>{ getShiftName(idx) }</option>
                                                    )
                                                }
                                            </Form.Control>
                                        </Row>
                                    </Form>
                                    <div>
                                        <i><small className={"text-danger"}>Внимание! Не може да има отчет за посочените дата и смяна!</small></i>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant={"primary"} onClick={() => setShowConfirm(true)}>Премести</Button>
                            <Button variant={"secondary"} onClick={() => setSelectNewDateShift(false)}>Откажи</Button>
                        </Modal.Footer>
                    </Modal>
                </BackgroundOverlay>
            }

        </>

    )
}
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Container, ListGroup, Row, Spinner, Tab} from "react-bootstrap";
import {faIndustry} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {API_CALL, Machine, Shift} from "../../types";
import {API} from "../../api";
import {dateToString, isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import {ReadOnlyDatePickerComponent} from "../../common/ReadOnlyDatePickerComponent";
import {SelectShiftComponent} from "./SelectShiftComponent";
import InputVXMachineForm from "./InputVXMachineForm";
import InputProfileMachineForm from "./InputProfileMachineForm";
import InputVEMachineForm from "./InputVEMachineForm";
import InputPackageMachineForm from "./InputPackageMachineForm";
import InputOvenMachineForm from "./InputOvenMachineForm";
import InputLaminationMachineForm from "./InputLaminationMachineForm";
import InputCutMachineForm from "./InputCutMachineForm";
import InputVertecMachineForm from "./InputVertecMachineForm";
import InputQualityForm from "./InputQualityForm";
import InputOtherActivitiesForm from "./InputOtherActivitiesForm";
import InputAlpaMachineForm from "./InputAlpaMachineForm";

export function InputPage() {
    const [machinesActiveTab, setMachinesActiveTab] = useState<string | null>(null);
    const [shifts, setShifts] = useState<Shift[]>([]);
    const [shift, setShift] = useState<Shift | null>(null);
    const [machines, setMachines] = useState<Machine[]>([]);
    const [machine, setMachine] = useState<Machine | null>(null);
    const [reportDate, setReportDate] = useState<Date>(new Date())
    const [loading, setLoading] = useState(0)

    useEffect(() => {
        setLoading(2);
        API.getMachines(
            (apiCall: API_CALL) => {
                if (!isLoading(apiCall)) setLoading(prevState => prevState - 1);
                if (isSuccess(apiCall)) setMachines(apiCall.data.data.machines)
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Проблем!', translateError)
            }
        )
    }, []);

    useEffect(() => {
        API.getShifts(
            (apiCall: API_CALL) => {
                if (!isLoading(apiCall)) setLoading(prevState => prevState - 1);
                if (isSuccess(apiCall)) {
                    setShifts(apiCall.data.data.shifts)
                }
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Проблем!', translateError)
            }, machine?.id || -1
        )
    }, [machine]);


    return (
        <>
            <Container fluid>
                <Tab.Container id="list-group-tabs-example" defaultActiveKey={undefined}>
                    <Row>
                        <Col xs={"auto"}>
                            {

                                <>
                                    <Row className={"pl-4"}>
                                        <ListGroup className={"w-300px"}
                                                   onSelect={eventKey => setMachinesActiveTab(eventKey)}
                                        >
                                            <ListGroup.Item className={"text-center bg-primary text-light pt-3"}>
                                                <h4>Отчети</h4>
                                            </ListGroup.Item>
                                            {
                                                loading > 0 ?
                                                    <ListGroup.Item className={"text-center"}>
                                                        <Spinner animation={"border"}></Spinner>
                                                    </ListGroup.Item>

                                                    :

                                                    <>
                                                        <ListGroup.Item>
                                                            <ReadOnlyDatePickerComponent onChange={(date: Date) => {
                                                                setReportDate(date)
                                                            }} value={dateToString(reportDate)}/>
                                                        </ListGroup.Item>

                                                        {
                                                            machines.map(
                                                                m =>
                                                                    <ListGroup.Item eventKey={'machine_' + m.id} action
                                                                                    active={machinesActiveTab === ('machine_' + m.id)}
                                                                                    onClick={() => setMachine(m)}>
                                                                        <FontAwesomeIcon icon={faIndustry}
                                                                                         className={"mr-2"}/> {m.name}
                                                                    </ListGroup.Item>
                                                            )
                                                        }
                                                        <ListGroup.Item eventKey={'quality'} action
                                                                        active={machinesActiveTab === ('quality')}
                                                                        onClick={() => setMachine({id: 999, name: 'quality'} as Machine)}>
                                                            <FontAwesomeIcon icon={faIndustry}
                                                                             className={"mr-2"}/> Качествени показатели
                                                        </ListGroup.Item>
                                                    </>
                                            }
                                        </ListGroup>
                                    </Row>

                                    {
                                        machine &&
                                        <Row className={"mt-3 pl-4"}>
                                            <SelectShiftComponent shifts={shifts} shift={shift}
                                                                  onSelect={(shift) => setShift(shift)}/>
                                        </Row>
                                    }
                                </>
                            }
                        </Col>

                        {
                            machine &&
                            <>
                                {
                                    shift &&
                                    <Col>
                                        {
                                            machine.name === 'quality' &&
                                            <InputQualityForm machine={machine} shift={shift} report_date={reportDate}
                                                              onReload={() => {
                                                                  setShift(null)
                                                              }}/>
                                        }

                                        {
                                            machine.name === 'VX' &&
                                            <InputVXMachineForm machine={machine} shift={shift} report_date={reportDate}
                                                                onReload={() => {
                                                                    setShift(null)
                                                                }}/>
                                        }

                                        {
                                            machine.name === 'VE' &&
                                            <InputVEMachineForm machine={machine} shift={shift} report_date={reportDate}
                                                                onReload={() => {
                                                                    setShift(null)
                                                                }}/>
                                        }

                                        {
                                            machine.name === 'Alpa' &&
                                            <InputAlpaMachineForm machine={machine} shift={shift}
                                                                  report_date={reportDate}
                                                                  onReload={() => {
                                                                         setShift(null)
                                                                     }}/>
                                        }

                                        {
                                            machine.name === 'Profile' &&
                                            <InputProfileMachineForm machine={machine} shift={shift}
                                                                     report_date={reportDate}
                                                                     onReload={() => {
                                                                         setShift(null)
                                                                     }}/>
                                        }

                                        {
                                            machine.name === 'Стъклопакет' &&
                                            <InputPackageMachineForm machine={machine} shift={shift}
                                                                     report_date={reportDate}
                                                                     onReload={() => {
                                                                         setShift(null)
                                                                     }}/>
                                        }

                                        {
                                            (machine.name === 'Малка печка' || machine.name === 'Голяма печка') &&
                                            <InputOvenMachineForm machine={machine} shift={shift}
                                                                  report_date={reportDate}
                                                                  onReload={() => {
                                                                      setShift(null)
                                                                  }}/>
                                        }
                                        {
                                            machine.name === 'Ламиниране' &&
                                            <InputLaminationMachineForm machine={machine} shift={shift}
                                                                        report_date={reportDate}
                                                                        onReload={() => {
                                                                            setShift(null)
                                                                        }}/>
                                        }
                                        {
                                            (machine.name === 'Рязане') &&
                                            <InputCutMachineForm machine={machine} shift={shift}
                                                                 report_date={reportDate}
                                                                 onReload={() => {
                                                                     setShift(null)
                                                                 }}/>
                                        }
                                        {
                                            machine.name === 'Vertec' &&
                                            <InputVertecMachineForm machine={machine} shift={shift}
                                                                    report_date={reportDate}
                                                                    onReload={() => {
                                                                        setShift(null)
                                                                    }}/>
                                        }
                                        {
                                            machine.name === 'Други дейности' &&
                                            <InputOtherActivitiesForm machine={machine} shift={shift}
                                                                    report_date={reportDate}
                                                                    onReload={() => {
                                                                        setShift(null)
                                                                    }}/>
                                        }
                                    </Col>
                                }
                            </>
                        }
                    </Row>
                </Tab.Container>


            </Container>
        </>
    )
}


export interface InputMachineFormProps {
    viewMode?: boolean;
    report_date: Date;
    machine: Machine;
    shift: Shift;
    onReload: () => any;
}

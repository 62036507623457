import {Shift} from "../../types";
import {ListGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBusinessTime, faIndustry} from "@fortawesome/free-solid-svg-icons";
import React from "react";


export interface SelectShiftComponentProps {
    shift: Shift|null;
    shifts: Shift[];
    onSelect: (shift: Shift) => any;
}

export function SelectShiftComponent({shifts, shift, onSelect}: SelectShiftComponentProps) {
    return (
        <ListGroup className={"w-300px"} >
            {
                shifts.map(
                    s =>
                        <ListGroup.Item eventKey={'shift_' + s.id} action
                                        onClick={() => onSelect(s)}>
                            <FontAwesomeIcon icon={faBusinessTime} className={"mr-2"}/> {s.name}
                        </ListGroup.Item>
                )
            }
        </ListGroup>
    )
}
import {API_CALL, NET_STATUS, PropertyValue} from "./types";
import {toast} from "react-toastify";
import moment, {Moment} from "moment";

export function isSuccess(apiCall: API_CALL|null) {
    return apiCall && (apiCall.status === NET_STATUS.SUCCESS) && (apiCall.data.data?.status === "ok");
}

export function isError(apiCall: API_CALL|null) {
    return apiCall && ((apiCall.status === NET_STATUS.ERROR) || (apiCall.data.data?.status === "error"));
}

export function isAuthError(apiCall: API_CALL|null) {
    return apiCall && apiCall.error?.response?.status === 401;
}

export function isLoading(apiCall: API_CALL|null): boolean {
    if(!apiCall) return false;
    return apiCall.status === NET_STATUS.LOADING;
}

export function mapToObject(map: any) {
    const obj: any = [];

    map.forEach(
        (k: string, v: any) => {
            obj[v] = k;
        }
    )
    return {...obj};
}

export function showErrorsInToast(apiCall: API_CALL|null, title: string, translateError?: any) {
    if(!apiCall) return;

    const errors = apiCall.error?.response?.data?.errors || null;
    const msgs: any = [];

    const toastMsg = function (title: string, errors: string[]) {
        return <>
            <div className={"font-weight-bold text-center w-100 border-bottom p-1 m-1"}>{title}</div>
            <ul className={"pt-3"}>
                {errors.map(e => e)}
            </ul>
        </>
    }

    if (apiCall.error?.response?.data?.errors) {
        Object.keys(errors).forEach(
            (k: string, kIdx: number) =>
                errors[k].forEach(
                    (m: string, mIdx: number) => msgs.push(<li key={kIdx * 100 + mIdx}>{translateError ? translateError(m) : m}</li>)
                )
        );
    } else if (apiCall.error) {
        msgs.push(<li key={0}>{apiCall.error.message}</li>);
    } else if (apiCall.data?.data?.message) {
        msgs.push(<li key={0}>{apiCall.data?.data?.message}</li>);
    }

    toast.error(toastMsg(title, msgs), {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
    });

}

export function showErrorsListInToast(title: string, errors: string[]) {
    const msgs: any = [];

    const toastMsg = function (title: string, errors: string[]) {
        return <>
            <div className={"font-weight-bold text-center w-100 border-bottom p-1 m-1"}>{title}</div>
            <ul className={"pt-3"}>
                {errors.map(e => e)}
            </ul>
        </>
    }

    errors.forEach(
        (m: string, mIdx: number) => msgs.push(<li key={mIdx}>{m}</li>)
    );

    toast.error(toastMsg(title, msgs), {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
    });

}

export function showMsgInToast(msg: string) {
    const toastMsg = function (msg: string) {
        return <>
            <div className={"font-weight-bold text-center w-100 p-1 m-1 text-center"}>{msg}</div>
        </>
    }

    toast.success(toastMsg(msg), {
        position: toast.POSITION.BOTTOM_CENTER
    });

}

export function getTypeName(types: PropertyValue[], typeId: number) {
    if (types && types[typeId]) {
        return types[typeId].value;
    } else {
        return '-'
    }
}

export const ARRAY22 = Array.of(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22);
export const ARRAY_MONTHS_SHORT = Array.of('ЯНУ', 'ФЕВ', 'МАР', 'АПР', 'МАЙ', 'ЮНИ', 'ЮЛИ', 'АВГ', 'СЕП', 'ОКТ', 'НОЕ', 'ДЕК');
export const ARRAY_MONTHS_FULL = Array.of('Януари', 'Февруари', 'Март', 'Април', 'Май', 'Юни', 'Юли', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември');

export const DICT = new Map<string, string>(
    [
        ['name', '< Име на служителя >'],
        ['period from', '< Час начало >'],
        ['period to', '< Час край >'],
        ['base info.report date', '< Отчет за дата >'],
        ['base info.shift id', '< Работна смяна >'],
        ['base info.man cnt', '< Брой служители >'],
        ['clay qty', '< Глина (кофи) >'],
        ['sludge qty', '< Шлам (кофи) >'],
        ["returned", "< Върнати палети >"],
        ["saleId", "< ID на експ.бележка >"],
        ["sales_date", "< Дата >"],
        ["client city", "< Град >"],
        ["client", "< Клиент >"],
        ["driver", "< Шофьор >"],
        ["repair type", "< Отдел >"],
        ["The name has already been taken.", "Това име вече съществува."],
        ["The selected month count is invalid.", "Избрания брой месеци е невалиден."],
        ["INCIDENT", "АВАРИЯ"],
        ["STOP", "ДРУГО СПИРАНЕ"],
        ["REPAIR", "ПЛАНИРАНИ РЕМОНТИ"],
    ]
);

export function translateError(e: string) {
    // debugger
    let err: string = e;
    // @ts-ignore
    for (const [key, value] of DICT.entries()) {
        err = err.replace(key, value);
    }

    return err;
}

export function translate(text: string) {
    return translateError(text);
}

export function minutesToHours(m: number, suffix: string = ""): string {
    if (m === 0) return '';

    const _h = Math.floor(m / 60);
    const _m = m % 60;

    return ("00" + _h).substr(-2) + ":" + ("00" + _m).substr(-2) + suffix;
}

export function zeroToEmptyString(text: string|number, suff: string = '') {
    return (!text || (text == "0") || (text == 0) || (text == "NaN")) ? "" : text + suff;
}

export function zeroToEmptyStringAsNumber(text: string|number, suff: string = '') {
    let newVal: string = zeroToEmptyString(text).toString();
    return (newVal && newVal !== "") ? Number.parseFloat(newVal).toFixed(2) + suff : '';
}

export function zeroToEmptyStringAsCurrency(text: string, suff: string = '') {
    let newVal: string = zeroToEmptyString(text).toString();
    return (newVal && newVal !== "") ? Number.parseFloat(newVal).toFixed(2) + suff : '';
}

export function zeroToEmptyStringAsCurrencyWithCommas(text: string, suff: string = '') {
    let newVal: string = zeroToEmptyString(text).toString();
    return (newVal && newVal !== "") ? numberWithCommas(Number.parseFloat(newVal).toFixed(2)) + suff : '';
}

export function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export function nullsToEmptyString(obj: any) {
    Object.keys(obj).forEach(
        $key => {
            if(obj[$key] === "null" || obj[$key] === null) obj[$key] = '';
        }
    );

    return obj;
}

export function textToFloat(value: string, defaultValue: any = null): number | null {
    const _v = Number.parseFloat(value.replace(',', '.'));
    return isNaN(_v) ? defaultValue : _v;
}
export function editTextToFloat(value: string): string {
    const _v = Number.parseFloat(value.replace(',', '.'));
    return isNaN(_v) ? '' : value;
}

export function textToInt(value: string, defaultValue: any = null): number | null {
    const _v = Number.parseInt(value);
    return isNaN(_v) ? defaultValue : _v;
}

export function onlyPozitive(value: number|null): number | null {
    return value !== null && value >= 0 ? value : null;
}
export function onlyPozitiveText(value: string|null): string | null {
    const _v = textToFloat(value || '');
    return _v !== null && _v >= 0 ? value : null;
}

export function momentToString(date: Moment) {
    return date.format('DD.MM.YYYY')
}

export function dateToString(date: Date) {
    return moment(date).format('DD.MM.YYYY')
}

export function isIterable(value: any) {
    return Symbol.iterator in Object(value);
}

export function getShiftName(id: number) {
    switch (id) {
        case 1: return "Първа смяна";
        case 2: return "Втора смяна";
        case 3: return "Редовна смяна";
    }
    return '';
}

export function getCutShiftName(id: number) {
    switch (id) {
        case 1: return "Първа смяна LISEC 1";
        case 2: return "Втора смяна LISEC 1";
        case 3: return "Редовна смяна LISEC 1";
        case 4: return "Първа смяна BAVELONI";
        case 5: return "Втора смяна BAVELONI";
        case 6: return "Редовна смяна BAVELONI";
        case 7: return "Първа смяна LISEC 2";
        case 8: return "Втора смяна LISEC 2";
        case 9: return "Редовна смяна LISEC 2";
    }
    return '';
}

export function getCutMachineName(id: string) {
    switch (id) {
        case "LISEC": return "LISEC 1";
        case "BAVELONI": return "BAVELONI";
        case "LISEC2": return "LISEC 2";
    }
    return id;
}

import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {API_CALL, EMPTY_REPORT, Incident, Order, Report, Worker} from "../../types";
import {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    onlyPozitive,
    showErrorsInToast,
    textToFloat,
    translateError
} from "../../common";
import {API} from "../../api";
import {InputMachineFormProps} from "./InputPage";
import moment from "moment";
import {WorkersListComponent} from "../config/common/WorkersListComponent";
import {OrdersListComponent} from "./common/OrdersListComponent";
import {IncidentsListComponent} from "./common/IncidentsListComponent";
import {UpdateConfigurationButton} from "./common/UpdateConfigurationButton";
import {DeleteReportButton} from "./common/DeleteReportButton";
import {MoveReportButton} from "./common/MoveReportButton";


export interface PackageMachineReport {
    oneChamberHandLng: number | null; // Еднокамерен с ръчен силикон
    twoChamberHandLng: number | null; //  Двукамерен с ръчен силикон
    oneChamberAutoCnt: number | null; // Еднокамерен с автоматично пълнене
    oneChamberAutoClipsCnt: number | null;
    twoChamberAutoCnt: number | null; //  Двукамерен с автоматично пълнене
    twoChamberAutoClipsCnt: number | null; //  Двукамерен с автоматично пълнене
    oneChamberRobotCnt: number | null; // Автоматично пълнене с робот - 10.5 мин / бр. - еднокамерен
    oneChamberClipsCnt: number | null; // Автоматично пълнене еднокамерен с клипси
    twoChamberRobotCnt: number | null; //  Автоматично пълнене с робот - 10.5 мин / бр. - двукамерен
    oneChamberSiliconCnt: number | null; // Л.м еднокамерен с ръчен силикон с профил/изтегляне - еднокамерен
    twoChamberSiliconCnt: number | null; //  Л.м двукамерен с ръчен силикон с профил/изтегляне - двукамерен
    oneChamberSiliconPaddingCnt: number | null; // Л.м еднокамерен с ръчен силикон с профил/изтегляне с oтстъп - еднокамерен
    twoChamberSiliconPaddingCnt: number | null; //  Л.м двукамерен с ръчен силикон с профил/изтегляне с oтстъп - двукамерен
    oneChamberBigRobotCnt: number | null; // Брой голям еднокамерен с автоматично пълнене
    oneChamberBigRobotClipsCnt: number | null; // Брой голям еднокамерен с автоматично пълнене
    twoChamberBigRobotCnt: number | null; // Брой голям двукамерен с автоматично пълнене
    twoChamberBigRobotClipsCnt: number | null; // Брой голям двукамерен с автоматично пълнене
    oneChamberExtraBigRobotCnt: number | null; // Брой голям еднокамерен с автоматично пълнене
    oneChamberExtraBigRobotClipsCnt: number | null; // Брой голям еднокамерен с автоматично пълнене
    twoChamberExtraBigRobotCnt: number | null; // Брой голям двукамерен с автоматично пълнене
    twoChamberExtraBigRobotClipsCnt: number | null; // Брой голям двукамерен с автоматично пълнене
    workHours: number;
    equalSqr: number;
}

export const EMPTY_PACKAGEMACHINE_REPORT = {
    oneChamberHandLng: 0,
    twoChamberHandLng: 0,
    oneChamberAutoCnt: 0,
    oneChamberAutoClipsCnt: 0,
    twoChamberAutoCnt: 0,
    twoChamberAutoClipsCnt: 0,
    equalSqr: 0,
    oneChamberRobotCnt: 0,
    oneChamberClipsCnt: 0,
    twoChamberRobotCnt: 0,
    oneChamberSiliconCnt: 0,
    twoChamberSiliconCnt: 0,
    oneChamberSiliconPaddingCnt: 0,
    twoChamberSiliconPaddingCnt: 0,
    oneChamberBigRobotCnt: 0,
    oneChamberBigRobotClipsCnt: 0,
    twoChamberBigRobotCnt: 0,
    twoChamberBigRobotClipsCnt: 0,
    oneChamberExtraBigRobotCnt: 0,
    oneChamberExtraBigRobotClipsCnt: 0,
    twoChamberExtraBigRobotCnt: 0,
    twoChamberExtraBigRobotClipsCnt: 0,
    workHours: 8
}

export default function InputPackageMachineForm({
                                                    viewMode,
                                                    report_date,
                                                    shift,
                                                    machine,
                                                    onReload
                                                }: InputMachineFormProps) {
    const _dateString = moment(report_date).format('DD.MM.YYYY');
    const [working, setWorking] = useState(false);
    const [loading, setLoading] = useState(false);
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [reportData, setReportData] = useState<PackageMachineReport>(EMPTY_PACKAGEMACHINE_REPORT);
    const [report, setReport] = useState<Report>(EMPTY_REPORT);
    const [comments, setComments] = useState<string>('');

    useEffect(() => {
        if (!loading) {
            API.getReport(
                (apiCall: API_CALL) => {
                    setLoading(isLoading(apiCall));
                    if (isSuccess(apiCall)) {
                        const r = apiCall.data.data.report;
                        const rd = JSON.parse(r.request_data);
                        setWorkers(rd.workers || []);
                        setIncidents(rd.incidents || []);
                        setOrders(rd.orders || []);
                        setReportData(rd.data || EMPTY_PACKAGEMACHINE_REPORT);
                        setReport(r);
                        setComments(rd.comments);
                    }
                    if (isError(apiCall)) {
                        setWorkers([]);
                        setIncidents([]);
                        setOrders([]);
                        setReportData(EMPTY_PACKAGEMACHINE_REPORT);
                        setReport(EMPTY_REPORT);
                        setComments('');
                    }
                }, machine.id, shift.id, moment(report_date).format('YYYY-MM-DD')
            )
        }
    }, [])


    function saveReport() {
        const _r: any = {
            id: report.id,
            report_date: moment(report_date).format('YYYY-MM-DD'),
            machine: machine,
            shift: shift,
            man_cnt: workers.length,
            request_data: {
                data: reportData,
                workers: workers,
                orders: orders,
                incidents: incidents,
                comments: comments
            },
        };

        API.saveReport(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    onReload();
                }
            }, _r
        );
    }

    return machine.name !== 'Стъклопакет' ? null :
        (
            <Modal show={true} size={"xl"}>
                <Modal.Header>
                    <Row className={"w-100"}>
                        <Col>
                            <Modal.Title>
                                <Row>
                                    <Col><span>Отчет за <strong
                                        className={"text-primary"}>{machine.name}</strong></span></Col>
                                    <Col className={"text-right"} xs={"auto"}>
                                <span className={"font-weight-light small"}><i><b><span
                                    className={"mr-3"}>{_dateString}</span><span>{shift.name}</span></b></i></span></Col>
                                </Row>
                            </Modal.Title>
                        </Col>
                        <Col xs={"auto"}><CloseButton onClick={onReload}/></Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body className={"max-h-60vh scrollable"}>
                            {
                                loading ? <Spinner animation="border"/> :
                                    <>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Form>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Л.м. еднокамерен с ръчен
                                                                    силикон</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.oneChamberHandLng || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      oneChamberHandLng: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Л.м. двукамерен с ръчен силикон</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.twoChamberHandLng || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      twoChamberHandLng: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Л.м еднокамерен с ръчен силикон с
                                                                    профил/изтегляне</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.oneChamberSiliconCnt || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      oneChamberSiliconCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Л.м двукамерен с ръчен силикон с
                                                                    профил/изтегляне</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.twoChamberSiliconCnt || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      twoChamberSiliconCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Л.м еднокамерен с ръчен силикон с
                                                                    профил/изтегляне <b>с отстъп</b></Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.oneChamberSiliconPaddingCnt || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      oneChamberSiliconPaddingCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Л.м двукамерен с ръчен силикон с
                                                                    профил/изтегляне <b>с отстъп</b></Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.twoChamberSiliconPaddingCnt || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      twoChamberSiliconPaddingCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Брой еднокамерен с авт. пълнене - <b>малки
                                                                    на сепараторни колички</b></Form.Label>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Control type="number"
                                                                                      value={reportData.oneChamberAutoCnt || ''}
                                                                                      readOnly={viewMode}
                                                                                      onChange={(e) => {
                                                                                          setReportData({
                                                                                              ...reportData,
                                                                                              oneChamberAutoCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                          })
                                                                                      }}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={"auto"}>
                                                                        <Row>
                                                                            <Col xs={"auto"}>
                                                                                <Col xs={"auto"}>
                                                                                    <Form.Label>с клипси</Form.Label>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col xs={"auto"}>
                                                                                <Form.Control type="number"
                                                                                              className={"w-120px"}
                                                                                              value={reportData.oneChamberAutoClipsCnt || ''}
                                                                                              readOnly={viewMode}
                                                                                              onChange={(e) => {
                                                                                                  setReportData({
                                                                                                      ...reportData,
                                                                                                      oneChamberAutoClipsCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                                  })
                                                                                              }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Брой двукамерен с авт. пълнене - <b>малки на
                                                                    сепараторни колички</b></Form.Label>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Control type="number"
                                                                                      value={reportData.twoChamberAutoCnt || ''}
                                                                                      readOnly={viewMode}
                                                                                      onChange={(e) => {
                                                                                          setReportData({
                                                                                              ...reportData,
                                                                                              twoChamberAutoCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                          })
                                                                                      }}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={"auto"}>
                                                                        <Row>
                                                                            <Col xs={"auto"}>
                                                                                <Col xs={"auto"}>
                                                                                    <Form.Label>с клипси</Form.Label>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col xs={"auto"}>
                                                                                <Form.Control type="number"
                                                                                              className={"w-120px"}
                                                                                              value={reportData.twoChamberAutoClipsCnt || ''}
                                                                                              readOnly={viewMode}
                                                                                              onChange={(e) => {
                                                                                                  setReportData({
                                                                                                      ...reportData,
                                                                                                      twoChamberAutoClipsCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                                  })
                                                                                              }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Брой еднокамерен с авт. пълнене - <strong>от
                                                                    1200-1800мм</strong></Form.Label>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Control type="number"
                                                                                      value={reportData.oneChamberBigRobotCnt || ''}
                                                                                      readOnly={viewMode}
                                                                                      onChange={(e) => {
                                                                                          setReportData({
                                                                                              ...reportData,
                                                                                              oneChamberBigRobotCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                          })
                                                                                      }}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={"auto"}>
                                                                        <Row>
                                                                            <Col xs={"auto"}>
                                                                                <Col xs={"auto"}>
                                                                                    <Form.Label>с клипси</Form.Label>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col xs={"auto"}>
                                                                                <Form.Control type="number"
                                                                                              className={"w-120px"}
                                                                                              value={reportData.oneChamberBigRobotClipsCnt || ''}
                                                                                              readOnly={viewMode}
                                                                                              onChange={(e) => {
                                                                                                  setReportData({
                                                                                                      ...reportData,
                                                                                                      oneChamberBigRobotClipsCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                                  })
                                                                                              }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Брой двукамерен с авт. пълнене - <strong>от
                                                                    1200-1800мм</strong></Form.Label>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Control type="number"
                                                                                      value={reportData.twoChamberBigRobotCnt || ''}
                                                                                      readOnly={viewMode}
                                                                                      onChange={(e) => {
                                                                                          setReportData({
                                                                                              ...reportData,
                                                                                              twoChamberBigRobotCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                          })
                                                                                      }}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={"auto"}>
                                                                        <Row>
                                                                            <Col xs={"auto"}>
                                                                                <Col xs={"auto"}>
                                                                                    <Form.Label>с клипси</Form.Label>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col xs={"auto"}>
                                                                                <Form.Control type="number"
                                                                                              className={"w-120px"}
                                                                                              value={reportData.twoChamberBigRobotClipsCnt || ''}
                                                                                              readOnly={viewMode}
                                                                                              onChange={(e) => {
                                                                                                  setReportData({
                                                                                                      ...reportData,
                                                                                                      twoChamberBigRobotClipsCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                                  })
                                                                                              }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Брой еднокамерен с авт. пълнене - <strong>от
                                                                    1800-2500мм</strong></Form.Label>
                                                                <Row>
                                                                    <Col>

                                                                        <Form.Control type="number"
                                                                                      value={reportData.oneChamberExtraBigRobotCnt || ''}
                                                                                      readOnly={viewMode}
                                                                                      onChange={(e) => {
                                                                                          setReportData({
                                                                                              ...reportData,
                                                                                              oneChamberExtraBigRobotCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                          })
                                                                                      }}
                                                                        />

                                                                    </Col>
                                                                    <Col xs={"auto"}>
                                                                        <Row>
                                                                            <Col xs={"auto"}>
                                                                                <Form.Label>с клипси</Form.Label>
                                                                            </Col>
                                                                            <Col xs={"auto"}>
                                                                                <Form.Control type="number"
                                                                                              className={"w-120px"}
                                                                                              value={reportData.oneChamberExtraBigRobotClipsCnt || ''}
                                                                                              readOnly={viewMode}
                                                                                              onChange={(e) => {
                                                                                                  setReportData({
                                                                                                      ...reportData,
                                                                                                      oneChamberExtraBigRobotClipsCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                                  })
                                                                                              }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Брой двукамерен с авт. пълнене - <strong>от
                                                                    1800-2500мм</strong></Form.Label>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Control type="number"
                                                                                      value={reportData.twoChamberExtraBigRobotCnt || ''}
                                                                                      readOnly={viewMode}
                                                                                      onChange={(e) => {
                                                                                          setReportData({
                                                                                              ...reportData,
                                                                                              twoChamberExtraBigRobotCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                          })
                                                                                      }}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={"auto"}>
                                                                        <Row>
                                                                            <Col xs={"auto"}>
                                                                                <Col xs={"auto"}>
                                                                                    <Form.Label>с клипси</Form.Label>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col xs={"auto"}>
                                                                                <Form.Control type="number"
                                                                                              className={"w-120px"}
                                                                                              value={reportData.twoChamberExtraBigRobotClipsCnt || ''}
                                                                                              readOnly={viewMode}
                                                                                              onChange={(e) => {
                                                                                                  setReportData({
                                                                                                      ...reportData,
                                                                                                      twoChamberExtraBigRobotClipsCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                                  })
                                                                                              }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Брой еднокамерен с автоматично пълнене с
                                                                    кран / стълба</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.oneChamberRobotCnt || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      oneChamberRobotCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Брой двукамерен с автоматично пълнене с
                                                                    кран / стълба</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.twoChamberRobotCnt || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      twoChamberRobotCnt: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                            {/*<Form.Group className="mb-3">*/}
                                                            {/*    <Form.Label>Автоматично пълнене еднокамерен с*/}
                                                            {/*        клипси</Form.Label>*/}
                                                            {/*    <Form.Control type="number"*/}
                                                            {/*                  value={reportData.oneChamberClipsCnt || ''}*/}
                                                            {/*                  readOnly={viewMode}*/}
                                                            {/*                  onChange={(e) => {*/}
                                                            {/*                      setReportData({*/}
                                                            {/*                          ...reportData,*/}
                                                            {/*                          oneChamberClipsCnt: onlyPozitive(textToFloat(e.target.value)) || 0*/}
                                                            {/*                      })*/}
                                                            {/*                  }}*/}
                                                            {/*    />*/}
                                                            {/*</Form.Group>*/}
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Продължителност на смяната,
                                                                    Часове</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={reportData.workHours || ''}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => {
                                                                                  setReportData({
                                                                                      ...reportData,
                                                                                      workHours: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                  })
                                                                              }}
                                                                />
                                                            </Form.Group>
                                                        </Form>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <Form>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Приравнени кв.м.</Form.Label>
                                                                        <Form.Control type="number"
                                                                                      value={reportData.equalSqr || ''}
                                                                                      readOnly={viewMode}
                                                                                      onChange={(e) => {
                                                                                          setReportData({
                                                                                              ...reportData,
                                                                                              equalSqr: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                          })
                                                                                      }}
                                                                        />
                                                                    </Form.Group>
                                                                </Form>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <WorkersListComponent workers={workers} defWorkHours={8}
                                                                                      forDate={moment(report_date).format('YYYY-MM-DD')}
                                                                                      showWorkHours={true}
                                                                                      viewMode={viewMode}
                                                                                      onChange={(workers) => setWorkers(workers)}/>
                                                            </Col>
                                                            <Col sm={12}>
                                                                <OrdersListComponent dim={'л.м.'} orders={orders}
                                                                                     viewMode={viewMode}
                                                                                     onChange={(orders => setOrders(orders))}/>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <IncidentsListComponent incidents={incidents} machine={machine}
                                                                        viewMode={viewMode}
                                                                        onChange={(incidents) => setIncidents(incidents)}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className={"mt-3"}>
                                                <Form.Group className={" bg-secondary-light p-2"}>
                                                    <Form.Label
                                                        className={"text-primary font-weight-bold text-center w-100"}>Коментари
                                                        и забележки</Form.Label>
                                                    <Form.Control as={"textarea"}
                                                                  value={comments}
                                                                  readOnly={viewMode}
                                                                  onChange={(e) => setComments(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                            }
                        </Card.Body>
                        <Card.Footer>
                            {
                                !viewMode && (
                                    working ? <Spinner animation="border"/> :
                                        <>
                                            <Button onClick={() => saveReport()}><FontAwesomeIcon
                                                icon={faSave}/> Запази</Button>

                                            <span className={"float-right"}>
                                                <UpdateConfigurationButton reportId={report.id}/>
                                                <DeleteReportButton reportId={report.id} onDelete={onReload}/>
                                                <MoveReportButton reportId={report.id} onMoved={onReload}/>
                                            </span>
                                        </>
                                )
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>
        )
}

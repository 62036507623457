import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {API_CALL, EMPTY_REPORT, Incident, Order, Report, Worker} from "../../types";
import {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    onlyPozitive,
    showErrorsInToast,
    textToFloat,
    translateError
} from "../../common";
import {API} from "../../api";
import {InputMachineFormProps} from "./InputPage";
import moment from "moment";
import InputAlpaMachineFormNew from "./InputAlpaMachineFormNew";
import InputAlpaMachineFormOld from "./InputAlpaMachineFormOld";

export const ALPA_MACHINE_ACTIVITY_NAMES = [
    'Кантиране - неполиран кант - 4 мм и 5 мм /л.м./',
    'Кантиране - неполиран кант - 6 мм /л.м./',
    'Кантиране - неполиран кант - 8 мм /л.м./',
    'Кантиране - неполиран кант - 10 мм /л.м./',
    'Кантиране - полиран кант - 4 мм и 5 мм /л.м./',
    'Кантиране - полиран кант - 6 мм /л.м./',
    'Кантиране - полиран кант - 8 мм /л.м./',
    'Кантиране - полиран кант - 10 мм /л.м./',
    'Външни изрези /л.м./',
    'Външен изрез за обков до 100 мм /брой/',
    'Външен изрез за обков над 100 мм /брой/',
    'Вътрешни изрези /л.м./',
    'Смяна на размер - подреждане на чашки /брой/',
    'Зареждане и оставяне на стъкло + настройки + изплакване < 0.75м2 /брой/',
    'Зареждане и оставяне на стъкло + настройки + изплакване > 0.75м2 /брой/',
    'Измиване на стъкла с меко покритие /брой/',
    'Вземане на инструмент от магазина /брой/',
    'Допълнителна обработка на ъгъл при изрези /брой/',
    'Направа на технологичен отвор /брой/',
    'Смяна на полирка + калибриране + полиране за настройка /брой/',
    'Почистване на масата /брой/',
];

export interface AlpaMachineReport {
    glassCnt: number;
    goodShapesLength: number;
    goodShapesNotPolishLength: number;
    badShapesLength: number;
    bigHolesCnt: number;
    smallHolesCnt: number;
    innerNotStandartCnt: number;
    // innerNotStandartBigCnt: number;
    outerStandartSmallCnt: number;
    outerStandartBigCnt: number;
    hardShapesCnt: number;
    workTableCleaningCnt: number;
    workHours: number;
    /// Стандарти след 11.03.2024
    // 1	Кантиране - неполиран кант - 4 мм и 5 мм /л.м./
    // 2	Кантиране - неполиран кант - 6 мм /л.м./
    // 3	Кантиране - неполиран кант - 8 мм /л.м./
    // 4	Кантиране - неполиран кант - 10 мм /л.м./
    // 5	Кантиране - полиран кант - 4 мм и 5 мм /л.м./
    // 6	Кантиране - полиран кант - 6 мм /л.м./
    // 7	Кантиране - полиран кант - 8 мм /л.м./
    // 8	Кантиране - полиран кант - 10 мм /л.м./
    // 9	Външни изрези /л.м./
    // 10	Външен изрез за обков до 100 мм /брой/
    // 11	Външен изрез за обков над 100 мм /брой/
    // 12	Вътрешни изрези /л.м./
    // 13	Смяна на размер - подреждане на чашки /брой/
    // 14	Зареждане и оставяне на стъкло + настройки + изплакване < 0.75м2 /брой/
    // 15	Зареждане и оставяне на стъкло + настройки + изплакване > 0.75м2 /брой/
    // 16	Измиване на стъкла с меко покритие /брой/
    // 17	Вземане на инструмент от магазина /брой/
    // 18	Допълнителна обработка на ъгъл при изрези /брой/
    // 19	Направа на технологичен отвор /брой/
    // 20	Смяна на полирка + калибриране + полиране за настройка /брой/
    // 21	Почистване на масата /брой/

    values: number[];
}

export const EMPTY_ALPAMACHINE_REPORT = {
    glassCnt: 0,
    goodShapesLength: 0,
    goodShapesNotPolishLength: 0,
    badShapesLength: 0,
    smallHolesCnt: 0,
    bigHolesCnt: 0,
    innerNotStandartCnt: 0,
    // innerNotStandartBigCnt: 0,
    outerStandartSmallCnt: 0,
    outerStandartBigCnt: 0,
    hardShapesCnt: 0,
    workTableCleaningCnt: 0,
    workHours: 8,
    values: [],
}

export default function InputAlpaMachineForm({viewMode, report_date, shift, machine, onReload}: InputMachineFormProps) {
    const _dateString = moment(report_date).format('DD.MM.YYYY');
    const newDate = new Date(2024, 2, 10, 0, 0, 0, 0);
    const isNew = moment(newDate).isBefore(report_date);

    console.log('isNew', isNew);
    console.log('report_date', report_date);
    console.log('newDate', newDate);
    console.log(_dateString);

    return machine.name !== 'Alpa' ? null :
        (
            <Modal show={true} size={"xl"} >
                <Modal.Header>
                    <Row className={"w-100"}>
                        <Col>
                            <Modal.Title>
                                <Row>
                                    <Col><span>Отчет за <strong
                                        className={"text-primary"}>{machine.name}
                                        {
                                            !isNew ? (
                                                <span className={"text-danger"}
                                                      style={{
                                                          fontSize: "0.75em",
                                                          fontWeight: "normal",
                                                          fontStyle: "italic"
                                                      }}> (стар формат)</span>
                                            ) : null
                                        }
                                    </strong></span></Col>
                                    <Col className={"text-right"} xs={"auto"}>
                                <span className={"font-weight-light small"}><i><b><span
                                    className={"mr-3"}>{_dateString}</span><span>{shift.name}</span></b></i></span></Col>
                                </Row>
                            </Modal.Title>
                        </Col>
                        <Col xs={"auto"}><CloseButton onClick={onReload}/></Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    {
                        isNew ? (
                            <InputAlpaMachineFormNew viewMode={viewMode} report_date={report_date} shift={shift} machine={machine}
                                                onReload={onReload}/>
                        ) : (
                            <InputAlpaMachineFormOld viewMode={viewMode} report_date={report_date} shift={shift} machine={machine}
                                                onReload={onReload}/>
                        )
                    }
                </Modal.Body>
            </Modal>
        )
}

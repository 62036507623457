import {Button, CloseButton, Col, Container, ListGroup, Modal, Row, Spinner, Tab} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIndustry, faTimes, faUser} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import {Machine} from "../../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../../common";
import {API_PATHS} from "../../../paths";
import ConfigIncidentsEditForm from "./ConfigIncidentsEditForm";
import useGetApiCall from "../../../hooks/useGetApiCall";

export default function ConfigIncidentsPage() {

    const [activeKey, setActiveKey] = useState("notSet");
    const [loading, setLoading] = useState(false);
    const [machines, setMachines] = useState<Machine[]>([]);
    const [machine, setMachine] = useState<Machine | null>(null);

    const {apiCall, reload} = useGetApiCall(API_PATHS.machines, {});

    useEffect(() => {
        setLoading(isLoading(apiCall));
        if (!isLoading(apiCall)) {
            if (isError(apiCall)) showErrorsInToast(apiCall, 'Възникна грешка', translateError);
            if (isSuccess(apiCall)) {
                const ms = apiCall?.data.data.machines.map(
                    (m: any) => {
                        return {id: m.id, name: m.name, params: m.config ? JSON.parse(m.config.config) : []}
                    }) || [];
                setMachines(ms);
            }
        }
    }, [apiCall]);


    function reloadMachines() {
        setMachine(null);
        setActiveKey("notSet");

        reload();
    }

    return (
        <>
            {
                loading ? <Spinner animation="border"/> :

                    <Row>
                        <Col>
                            <div className={"overflow-auto"} style={{maxHeight: "100%"}}>
                                <ListGroup style={{maxWidth: 400}}>
                                    {
                                        machines.map(m =>
                                            <ListGroup.Item action href={"#" + m.name} key={"link_" + m.name}
                                                            onClick={() => setMachine(m)}>
                                                <div className={"text-truncate"}>
                                                    <FontAwesomeIcon icon={faIndustry} className={"mr-2"}/>
                                                    {m.name}
                                                </div>
                                            </ListGroup.Item>
                                        )
                                    }
                                </ListGroup>
                            </div>
                        </Col>
                    </Row>
            }
            {
                machine &&
                <ConfigIncidentsEditForm machine={machine} onReload={() => setMachine(null)}/>
            }
        </>
    )
}

export interface ConfigMachineFormProps {
    machine: Machine;
    onReload: () => any;
}
import {Button, Form} from "react-bootstrap";
import {onlyPozitive, textToFloat, textToInt} from "../../../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Glass, GlassSize} from "../../../types";
import {useState} from "react";
import {CutMachineReportItem} from "../InputCutMachineForm";
import GlassNameComponent from "./GlassNameComponent";
import GlassSizeComponent from "./GlassSizeComponent";

export interface EditCutMachineReportItemComponentProps {
    glasses: Array<Glass>;
    glassSizes: Array<GlassSize>;
    reportItem: CutMachineReportItem;
    onChange: (newItem: CutMachineReportItem) => any
    onAdd?: () => any
    onRemove?: () => any
}

export default function EditCutMachineReportItemComponent(
    {reportItem, onChange, glasses, glassSizes, onAdd, onRemove}: EditCutMachineReportItemComponentProps) {

    const [cutCntFocused, setCutCntFocused] = useState<boolean>(false);

    const updateReportItem = function (reportItem: CutMachineReportItem) {
        const sizeId = reportItem.sizeId;
        const cnt = reportItem.sheetCnt;

        if (sizeId && cnt) {
            glassSizes.forEach(
                s => {
                    if (s.id === sizeId) {
                        const area = parseFloat((s.area * cnt).toFixed(2));
                        if (!cutCntFocused && (area !== reportItem.cutCnt)) {
                            reportItem.cutCnt = area;
                        }
                    }
                }
            )
        }
        onChange(reportItem);
    }

    let viewMode = true;
    if(onAdd || onRemove) viewMode = false;

    return (
        viewMode ?
                <tr>
                <td>{reportItem.machineId}</td>
                <td>
                    <GlassNameComponent
                        glasses={glasses}
                        glassId={reportItem.glassId}/>
                </td>
                <td>
                    <GlassSizeComponent
                        glassSizes={glassSizes}
                        sizeId={reportItem.sizeId}/></td>
                <td className={"text-right"}>{reportItem.sheetCnt}</td>
                <td className={"text-right"}>{reportItem.cutCnt ? reportItem.cutCnt.toFixed(2) : ''}</td>
                <td className={"text-right"}>{reportItem.formCnt}</td>
                    <td className={"text-right"}></td>
            </tr>
    :
        <tr>
            <td className={"font-weight-bold small text-nowrap"}>
                <Form.Control as="select" size={"sm"}
                              value={reportItem.machineId || undefined}
                              onChange={(e) => updateReportItem({
                                  ...reportItem,
                                  machineId:
                                      e.target.value === 'LISEC' ? 'LISEC' :
                                          (e.target.value === 'LISEC2' ? 'LISEC2' :
                                          (e.target.value === 'BAVELLONI' ? 'BAVELLONI' : null))
                              })}
                >
                    { onAdd && <option/> }
                    <option
                        selected={'LISEC' === reportItem.machineId}
                        value='LISEC'>LISEC 1
                    </option>
                    <option
                        selected={'LISEC2' === reportItem.machineId}
                        value='LISEC2'>LISEC 2
                    </option>
                    <option
                        selected={'BAVELLONI' === reportItem.machineId}
                        value='BAVELLONI'>BAVELLONI
                    </option>
                </Form.Control>
            </td>
            <td className={"font-weight-bold small text-nowrap"}>
                <Form.Control as="select" size={"sm"}
                              disabled={!reportItem.machineId}
                              value={reportItem.glassId || undefined}
                              onChange={(e) => updateReportItem({
                                  ...reportItem,
                                  glassId: textToInt(e.target.value)
                              })}
                >
                    { onAdd && <option/> }
                    {
                        glasses.filter(g => g.oven_type === reportItem.machineId)
                            .map(
                                g => <option key={"g_" + g.id} value={g.id}>{g.name}</option>
                            )
                    }
                </Form.Control>
            </td>
            <td className={"font-weight-bold small text-nowrap"}>
                <Form.Control as="select" size={"sm"}
                              disabled={!reportItem.glassId}
                              value={reportItem.sizeId || undefined}
                              onChange={(e) => updateReportItem({
                                  ...reportItem,
                                  sizeId: textToInt(e.target.value)
                              })}
                >
                    <option/>
                    {
                        glassSizes.map(
                            s => <option key={"s_" + s.id}
                                         selected={s.id === reportItem.sizeId}
                                         value={s.id}>{s.name}</option>
                        )
                    }
                </Form.Control>
            </td>
            <td>
                <Form.Control size="sm" type="number"
                              value={reportItem.sheetCnt || ''}
                              onChange={(e) => updateReportItem({
                                  ...reportItem,
                                  sheetCnt: onlyPozitive(textToFloat(e.target.value))
                              })}
                />
            </td>
            <td>
                <Form.Control size="sm" type="number"
                              onFocus={() => setCutCntFocused(true)}
                              onBlur={() => setCutCntFocused(false)}
                              value={cutCntFocused ? reportItem.cutCnt + ''
                                  : reportItem.cutCnt?.toFixed(2) + ''}
                              onChange={(e) => updateReportItem({
                                  ...reportItem,
                                  cutCnt: onlyPozitive(textToFloat(e.target.value))
                              })}
                />
            </td>
            <td>
                <Form.Control size="sm" type="number"
                              value={reportItem.formCnt || ''}
                              onChange={(e) => updateReportItem({
                                  ...reportItem,
                                  formCnt: onlyPozitive(textToFloat(e.target.value))
                              })}
                />
            </td>
            <td className={"text-right w-50px"}>
                {
                    onAdd &&
                    <Button onClick={onAdd} size={"sm"}>
                        <FontAwesomeIcon icon={faPlus} size={"sm"}/>
                    </Button>
                }
                {
                    onRemove &&
                    <Button size={"sm"} onClick={() => onRemove()}>
                        <FontAwesomeIcon icon={faMinus} size={"sm"}/>
                    </Button>
                }
            </td>
        </tr>
    )
}

import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../api";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {Badge} from "react-bootstrap";



export class UserNameComponent extends React.Component {

    render() {
        return (
            <>
                {
                    !API.user &&
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                }
                {
                    API.user &&
                    <Badge  className={"p-2"} style={{fontSize: "1rem"}} variant={"primary"}>
                        <FontAwesomeIcon icon={faUser} size="sm" /> <strong>{API.user.name}</strong>
                    </Badge>
                }
            </>
        );
    }

}

import {GlassSize} from "../../../types";

export interface GlassSizeComponentProps {
    glassSizes: GlassSize[];
    sizeId: number|null;
}

export default function GlassSizeComponent({glassSizes, sizeId}: GlassSizeComponentProps) {
    if(glassSizes.length > 0) {
        if(sizeId) {
            const g = glassSizes.find(i => i.id === sizeId);
            if(g) return (<>{g.name}</>)
        }
    }
    return null
}
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    Col,
    Container,
    ListGroup,
    Row,
    Tab
} from "react-bootstrap";
import {faPoll} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import OutputProductivityModal from "../output/OutputProductivityModal";
import OutputQualityModal from "../output/OutputQualityModal";
import OutputIncidentsModal from "../output/OutputIncidentsModal";
import OutputQuantitiesModal from "../output/OutputQuantitiesModal";
import OutputDetailedModal from "../output/OutputDatiledModal";
import OutputDetailedVXModal from "../output/OutputDatiledModalVX";
import CutOtherActivitiesModal from "../output/CutOtherActivitiesModal";

export function OutputPage() {
    const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(0)

    const toggleKey = function (key: string) {
        setActiveKey(activeKey => (activeKey && (activeKey === key)) ? undefined : key);
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs={"auto"}>
                        <Tab.Container>

                            <ListGroup className={"w-250px ml-3"} activeKey={activeKey}>
                                <ListGroup.Item className={"text-center bg-primary text-light pt-3"}>
                                    <h4>Справки</h4>
                                </ListGroup.Item>

                                <ListGroup.Item
                                    active={activeKey === 'productivity'}
                                    onClick={() => toggleKey('productivity')}>
                                    <FontAwesomeIcon icon={faPoll} className={"mr-2"}/> Справка производителност
                                </ListGroup.Item>

                                <ListGroup.Item
                                    active={activeKey === 'quality'}
                                    onClick={() => toggleKey('quality')}>
                                    <FontAwesomeIcon icon={faPoll} className={"mr-2"}/> Справка качество
                                </ListGroup.Item>

                                <ListGroup.Item
                                    active={activeKey === 'incidents'}
                                    onClick={() => toggleKey('incidents')}>
                                    <FontAwesomeIcon icon={faPoll} className={"mr-2"}/> Справка аварии
                                </ListGroup.Item>

                                <ListGroup.Item
                                    active={activeKey === 'quantities'}
                                    onClick={() => toggleKey('quantities')}>
                                    <FontAwesomeIcon icon={faPoll} className={"mr-2"}/> Количествени справки
                                </ListGroup.Item>

                                <ListGroup.Item
                                    active={activeKey === 'detailed'}
                                    onClick={() => toggleKey('detailed')}>
                                    <FontAwesomeIcon icon={faPoll} className={"mr-2"}/> Подробни справки
                                </ListGroup.Item>

                                <ListGroup.Item
                                    active={activeKey === 'detailedVX'}
                                    onClick={() => toggleKey('detailedVX')}>
                                    <FontAwesomeIcon icon={faPoll} className={"mr-2"}/> Подробна справка VX
                                </ListGroup.Item>

                                <ListGroup.Item
                                    active={activeKey === 'cutOtherActivities'}
                                    onClick={() => toggleKey('cutOtherActivities')}>
                                    <FontAwesomeIcon icon={faPoll} className={"mr-2"}/> Други дейности - Рязане
                                </ListGroup.Item>

                            </ListGroup>

                        </Tab.Container>
                    </Col>
                </Row>
            </Container>

            { activeKey === 'productivity' && <OutputProductivityModal onHide={() => setActiveKey(undefined)} /> }
            { activeKey === 'quality' && <OutputQualityModal onHide={() => setActiveKey(undefined)} /> }
            { activeKey === 'incidents' && <OutputIncidentsModal onHide={() => setActiveKey(undefined)} /> }
            { activeKey === 'cutOtherActivities' && <CutOtherActivitiesModal onHide={() => setActiveKey(undefined)} /> }
            { activeKey === 'quantities' && <OutputQuantitiesModal onHide={() => setActiveKey(undefined)} /> }
            { activeKey === 'detailed' && <OutputDetailedModal onHide={() => setActiveKey(undefined)} /> }
            { activeKey === 'detailedVX' && <OutputDetailedVXModal onHide={() => setActiveKey(undefined)} /> }
        </>
    )
}

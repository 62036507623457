import ModalHeader from "react-bootstrap/ModalHeader";
import {
    Button,
    CloseButton,
    Col, DropdownButton,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalTitle,
    Row, Spinner,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import {
    ARRAY_MONTHS_FULL,
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    showMsgInToast,
    translateError
} from "../../common";
import React, {useEffect, useState} from "react";
import {SalaryConfig, SalaryConfigItem} from "../input/SalaryPage";
import SalaryConfigEditItemModal from "./SalaryConfigEditItemModal";
import moment from "moment";
import {API} from "../../api";
import {API_CALL} from "../../types";


export default function SalaryConfigModal({
                                              salary,
                                              onHide,
                                              onSave,
                                              onSetConfigValue,
                                              onSetBaseHours,
                                              onSetNormativeHours,
                                              onSetExtraWorkRate,
                                              onSetPeriod
                                          }: SalaryModalProps) {
    const [working, setWorking] = useState(false);
    const [activeTab, setActiveTab] = useState<string>("Всички");
    const [departments, setDepartments] = useState<Array<string>>(['Всички']);
    const [editSalaryConfigItem, setEditSalaryConfigItem] = useState<SalaryConfigItem | null>(null);

    const today = moment([salary.year, salary.month, 1]).subtract(1, 'month');

    const [month, setMonth] = useState(today.month());
    const [year, setYear] = useState(today.year());

    function TableHeader({row}: any) {
        return (
            <thead>
            <tr>
                <th>Служител</th>
                <th>Длъжност</th>
                <th className={"text-right"}>Ставка<br/>ефективни часове</th>
                <th className={"text-right"}>Базова<br/>производителност (%)</th>
                <th className={"text-right"}>Коефициент<br/>производителност</th>
                <th className={"text-right"}>Договорен<br/>доход</th>
            </tr>
            </thead>
        )
    }


    useEffect(() => {
        if (config) {
            const dep = Array<string>();

            salary.config.forEach(
                (c: SalaryConfigItem) => {
                    if (dep.indexOf(c.employee.department) === -1) dep.push(c.employee.department);
                }
            )

            dep.sort();

            setDepartments(dep);
        }
    }, [salary])


    let currentDep = "";

    const printDepartment = function (uDep: string) {
        if (currentDep !== uDep) {
            currentDep = uDep;

            return <>
                <tr>
                    <td colSpan={6}>&nbsp;</td>
                </tr>
                <tr className={"text-white bg-secondary font-weight-bold"}>
                    <td colSpan={6}>{uDep}</td>
                </tr>
            </>
        }

        return null;
    }

    const editConfigItem = function (item: SalaryConfigItem) {
        if (!onSetConfigValue) return;
        setEditSalaryConfigItem(item);
    }

    const selectPrevMonth = () => {
        let m = salary.month;
        let y = salary.year;

        m -= 1;
        if (m < 0) {
            m = 11;
            y -= 1;
        }

        onSetPeriod(m, y);
    }

    const selectNextMonth = () => {
        let m = salary.month;
        let y = salary.year;

        m += 1;
        if (m > 11) {
            m = 0;
            y += 1;
        }

        onSetPeriod(m, y);
    }

    const config = salary?.config || [];

    const saveSalaryConfig = () => {
        if (onSave) onSave();
    }

    function onSaveSalaryConfigItem(newValue: SalaryConfigItem) {
        setEditSalaryConfigItem(null);
        onSetConfigValue(newValue);
    }

    const copyConfiguration = () => {
        if (month !== salary.month || year !== salary.year)
            API.copySalaryConfigConfiguration(
                (apiCall: API_CALL) => {
                    setWorking(isLoading(apiCall));
                    if (isSuccess(apiCall)) {
                        onSetPeriod(salary.month, salary.year);
                        showMsgInToast('Конфигурацията е копирана успешно.');
                    }
                    if (isError(apiCall)) showErrorsInToast(apiCall, 'Възникна грешка', translateError)
                },
                month, year, salary.month, salary.year
            );
    }

    return (
        <>
            <Modal onHide={() => onHide()} show={true} dialogClassName={"w-1200px p-3 max-w-none"}>
                <ModalHeader>
                    <Row className={"w-100"}>
                        <Col xs={"auto"}>
                            <ModalTitle>Ставки и нормативни часове за </ModalTitle>
                        </Col>
                        <Col xs={"auto"}><Button onClick={selectPrevMonth}>&#8882;</Button></Col>
                        <Col xs={"auto"} className={"p-2 bg-primary text-light font-weight-bold"}>
                            {ARRAY_MONTHS_FULL[salary.month].toUpperCase()},&nbsp;&nbsp;{salary.year}
                        </Col>
                        <Col xs={"auto"}><Button onClick={selectNextMonth}>&#8883;</Button></Col>
                        <Col>
                            {/*<Button variant={"secondary"}>Авт.попълване</Button>*/}
                        </Col>
                        <Col xs={"auto"}>
                            <CloseButton onClick={() => onHide()}/>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody style={{maxHeight: '70vh', overflow: 'auto'}}>
                    {
                        config?.length > 0 &&
                        <div className={"table-responsive"} style={{maxHeight: '90%h'}}>

                            <Tabs activeKey={activeTab} onSelect={(e) => setActiveTab(e || "Всички")}>
                                <Tab title={"Всички"} eventKey={"Всички"}/>
                                {departments.map((k: string) => <Tab key={k} title={k} eventKey={k}/>)}
                            </Tabs>

                            <div className="w-100 overflow-auto" style={{maxHeight: '60vh'}}>
                                <Table striped bordered hover size={"sm"} className={"small"} style={{height: '90%'}}
                                       id="tableForm76">
                                    <TableHeader/>
                                    <tbody>
                                    {
                                        config
                                            .filter(
                                                (u: SalaryConfigItem) =>
                                                    ((activeTab === "Всички") ||
                                                        (u.employee.department == activeTab))
                                            )
                                            .map(
                                                (u: SalaryConfigItem) => {
                                                    return <>
                                                        {
                                                            printDepartment(u.employee.department)
                                                        }
                                                        <tr key={u.employee.id} onClick={() => editConfigItem(u)}>
                                                            <td>{u.employee.name}</td>
                                                            <td>{u.employee.position}</td>
                                                            <td className={"text-right"}>{u.effectiveHoursRate}</td>
                                                            <td className={"text-right"}>{u.productionBase}</td>
                                                            <td className={"text-right"}>{u.prodIdx?.toFixed(1)}</td>
                                                            <td className={"text-right"}>{u.minSallary?.toFixed(1)}</td>
                                                        </tr>
                                                    </>
                                                }
                                            )
                                    }
                                    </tbody>
                                </Table>
                            </div>

                        </div>
                    }
                    {
                        (salary.config.length === 0) &&
                        <div><strong>Няма данни</strong></div>
                    }

                </ModalBody>
                <ModalFooter className={"justify-content-center"}>
                    <Row className={"w-100"}>
                        <Col xs={"auto"} className={"pt-2"}>
                            Базови часове:
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Control type="number" disabled={!onSetBaseHours}
                                          className={"w-120px text-right"}
                                          value={salary.baseMonthHours}
                                          onChange={(e) => onSetBaseHours(e.target.value)}
                            />
                        </Col>
                        <Col xs={"auto"} className={"pt-2"}>
                            Нормативни часове:
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Control type="number" disabled={!onSetNormativeHours}
                                          className={"w-120px text-right"}
                                          value={salary.normativeMonthHours}
                                          onChange={(e) => onSetNormativeHours(e.target.value)}
                            />
                        </Col>
                        <Col xs={"auto"} className={"pt-2"}>
                            Коефициент извънреден труд:
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Control type="number" disabled={!onSetExtraWorkRate}
                                          className={"w-120px text-right"}
                                          value={salary.extraWorkRate}
                                          onChange={(e) => onSetExtraWorkRate(e.target.value)}
                            />
                        </Col>
                        <Col className={"pl-0 ml-0 mt-2"}>
                            <Row>
                                <Col>
                                    <DropdownButton id="dropdown-basic-button" title="Копиране на конфигурацията от">

                                                <Form.Group className={"p-3 pb-0 mb-0"}>
                                                    <Row>
                                                        <Col>
                                                            <Form.Control as="select"
                                                                          value={month}
                                                                          onChange={(e: any) => {
                                                                              setMonth(parseInt(e.target.value));
                                                                          }}>

                                                                {
                                                                    ARRAY_MONTHS_FULL.map(
                                                                        (m, idx) =>
                                                                            <option value={idx} key={idx}>{m}</option>
                                                                    )
                                                                }
                                                            </Form.Control>

                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="number"
                                                                          value={year}
                                                                          onChange={(e) => {
                                                                              const y = parseInt(e.target.value);
                                                                              if (!isNaN(y)) setYear(y);
                                                                          }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className={"text-right pt-2 mb-0 pb-0"}>
                                                        <Col>
                                                            {
                                                                working ? <Spinner animation={"border"}></Spinner> :
                                                                    <Button variant={"outline-primary"} size={"sm"}
                                                                            onClick={copyConfiguration}
                                                                    >
                                                                        Копирай</Button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                    </DropdownButton>
                                </Col>
                                <Col className={"text-right"}>
                                    {
                                        onSave &&
                                        <>
                                            <Button onClick={saveSalaryConfig}>Запази</Button>&nbsp;
                                        </>
                                    }
                                    <Button variant={"secondary"} onClick={() => onHide()}>Затвори</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>


            {
                editSalaryConfigItem &&
                <SalaryConfigEditItemModal editItem={editSalaryConfigItem}
                                           onSave={onSaveSalaryConfigItem}
                                           onHide={() => setEditSalaryConfigItem(null)}/>
            }
        </>
    )
}


export interface SalaryModalProps {
    salary: SalaryConfig;
    onSetNormativeHours: (newValue: string) => any;
    onSetBaseHours: (newValue: string) => any;
    onSetExtraWorkRate: (newValue: string) => any;
    onSetConfigValue: (newValue: SalaryConfigItem) => any;
    onSetPeriod: (newMonth: number, newYear: number) => any;
    onSave?: () => any;
    onHide: () => any;
}
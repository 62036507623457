import {Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import {
    API_CALL, CutMachineOtherActivity,
    CutMachineOtherActivityValue
} from "../../types";
import {useEffect, useState} from "react";
import {
    isError, isIterable,
    isLoading,
    isSuccess,
    onlyPozitive,
    showErrorsInToast,
    textToFloat,
    translateError
} from "../../common";
import {API} from "../../api";

export interface InputCutMachineOtherActivitiesFormProps {
    items: CutMachineOtherActivityValue[];
    viewMode?: boolean;
    onChange: (newList: CutMachineOtherActivityValue[]) => void;
}

export default function InputCutMachineOtherActivitiesForm({items, viewMode, onChange}: InputCutMachineOtherActivitiesFormProps) {
    const [working, setWorking] = useState(false);
    const [activities, setActivities] = useState<CutMachineOtherActivity[]>([]);

    useEffect(() => {
        API.getCutOtherActivities(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setActivities(api_call.data.data.activities.filter(
                        (i: any) => {
                            return (!i.deleted_at) || (getValue(i.id) !== '')
                        }
                    ));
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на другите дейности!', translateError)
            }
        )
    }, [])

    function changeValue(item: CutMachineOtherActivityValue) {
        for (let i = 0; i < items.length; i++) {
            if (items[i].id === item.id) {
                items[i].value = item.value;
                onChange([...items]);
                return;
            }
        }
        onChange([...items, {...item}]);
    }

    function getValue(id: number) {
        for (let i = 0; i < items.length; i++) {
            if (items[i].id === id) {
                return items[i].value.toString();
            }
        }

        return '';
    }

    if(!isIterable(items)) {
        onChange([]);
    }

    return (
        <>
            {
                working ? <Spinner animation="border"/> :
                    <>
                        <Row>
                            <Col sm={12}>
                                <Card.Title>Други дейности</Card.Title>
                                <Table size={"sm"}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <Table size={"sm"} bordered className={"small"}>
                                                <thead>
                                                <tr className={"text-center"}>
                                                    <th>Дейност</th>
                                                    <th>МЕ</th>
                                                    <th className={"w-100px"}>Брой</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    activities.map(
                                                        (a, idx) =>
                                                            (idx < (activities.length / 2)) ?
                                                                <tr key={idx}>
                                                                    <td>{a.title}</td>
                                                                    <td className={"text-nowrap"}>{a.dimens}</td>
                                                                    <td>
                                                                        <Form.Control type="text" value={getValue(a.id)}
                                                                                      readOnly={viewMode}
                                                                                      className={"text-right"}
                                                                                      onChange={(e) => {
                                                                                          changeValue({
                                                                                              id: a.id,
                                                                                              value: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                          })
                                                                                      }}
                                                                        />
                                                                    </td>
                                                                </tr> : null
                                                    )
                                                }
                                                </tbody>
                                            </Table>
                                        </td>
                                        <td>
                                            <Table size={"sm"} bordered className={"small"}>
                                                <thead>
                                                <tr className={"text-center"}>
                                                    <th>Дейност</th>
                                                    <th>МЕ</th>
                                                    <th className={"w-100px"}>Брой</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    activities.map(
                                                        (a, idx) =>
                                                            (idx >= (activities.length / 2)) ?
                                                                <tr key={idx}>
                                                                    <td>{a.title}</td>
                                                                    <td className={"text-nowrap"}>{a.dimens}</td>
                                                                    <td>
                                                                        <Form.Control type="text" value={getValue(a.id)}
                                                                                      className={"text-right"}
                                                                                      readOnly={viewMode}
                                                                                      onChange={(e) => {
                                                                                          changeValue({
                                                                                              id: a.id,
                                                                                              value: onlyPozitive(textToFloat(e.target.value)) || 0
                                                                                          })
                                                                                      }}
                                                                        />
                                                                    </td>
                                                                </tr> : null
                                                    )
                                                }
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>

                        </Row>
                    </>
            }
        </>
    )
}

export enum PATHS {
    home= "/",
    login="/login",
    config="/config",
    input="/input",
    salary="/salary",
    output="/output",
    accounts="/admin/accounts",
    account="/admin/accounts/",
}

export enum API_PATHS {
    login="login",
    logout="user/logout",
    allUsersNotSigned="user/all",
    accounts="admin/accounts",
    glasses="admin/glasses",
    teams="admin/teams",
    machines="admin/machines",
    input="admin/input",
    shifts="admin/shifts",
    appModules="admin/app-modules",
    validateReport="admin/report-validate",
    user="user",
    user_login="user/login",
    properties="admin/properties",
    expeditions_notes="admin/expedition_notes",
    salary="admin/salary",
    clients="admin/clients",
    client="admin/client",
    logistics="admin/logistics",
    grinding="admin/grinding",
    heater="admin/heater",
    ordering="admin/ordering",
    oven="admin/oven",
    unloading="admin/unloading",
    daily_report="admin/daily_reports",
    full_daily_report="admin/dailyfullreport",
    repairs="admin/repairs",
    extruder="admin/extruder",
    refueling="admin/refueling",
    standarts="admin/standarts",
    production_report="admin/reports/production",
    repairs_report="admin/reports/repairs",
    bricks_produced="admin/reports/bricks-produced",
    bricks_waste="admin/reports/bricks-waste",
    bricks_waste_by_type="admin/reports/bricks-waste-by-type",
    fuel_report="admin/reports/fuel",
    materials_report="admin/reports/materials",
    comments_report="admin/reports/comments",

    performance="admin/output/performance",
    detailed="admin/output/detailed",
    detailedVX="admin/output/detailedVX",
    incidents="admin/output/incidents",
    quantities="admin/output/quantities",
    stops="admin/output/stops",
    repairsref="admin/output/repairs",
    quality="admin/quality",
    cutOtherActivities="admin/output/cutting/others",
}

import ModalHeader from "react-bootstrap/ModalHeader";
import {
    Button,
    CloseButton,
    Col, Dropdown, Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalTitle,
    Row, Spinner,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import {ReadOnlyDatePickerComponent} from "../../common/ReadOnlyDatePickerComponent";
import {
    ARRAY_MONTHS_FULL,
    dateToString,
    getShiftName, isError,
    isLoading,
    isSuccess, showErrorsInToast, translateError, zeroToEmptyString,
    zeroToEmptyStringAsNumber
} from "../../common";
import React, {useEffect, useState} from "react";
import {faCalculator, faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../../api";
import {API_CALL, Machine, Shift} from "../../types";
import moment from "moment";
import InputCutMachineForm from "../input/InputCutMachineForm";
import InputVXMachineForm from "../input/InputVXMachineForm";
import InputVEMachineForm from "../input/InputVEMachineForm";
import InputPackageMachineForm from "../input/InputPackageMachineForm";
import InputLaminationMachineForm from "../input/InputLaminationMachineForm";
import InputProfileMachineForm from "../input/InputProfileMachineForm";
import InputOvenMachineForm from "../input/InputOvenMachineForm";
import InputVertecMachineForm from "../input/InputVertecMachineForm";
import InputQualityForm, {QualityIndicator} from "../input/InputQualityForm";
import {SelectMonthYear} from "../../common/SelectMonthYear";
import ExportButton from "../../common/ExportButton";


export interface OutputQualityModalProps {
    onHide: () => any;
}


export default function OutputQualityModal({onHide}: OutputQualityModalProps) {

    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<string>("Всички показатели");


    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(2022);
    const [monthDays, setMonthDays] = useState(30);
    const [indicators, setIndicators] = useState<Array<QualityIndicator>>([]);
    const [departments, setDepartments] = useState<Array<string>>(['Всички']);
    const [showReportForDate, setShowReportForDate] = useState<any>(null);


    useEffect(() => {
        setMonth(moment().month());
        setYear(moment().year());
        loadIndicators();
    }, [])

    useEffect(() => {
        const DAYS_IN_MONTH = moment().day(1).month(month).year(year).daysInMonth();
        setMonthDays(DAYS_IN_MONTH);
        loadData()
    }, [month, year])

    function loadIndicators() {
        API.getQualityIndicators(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    const _indicators: Array<QualityIndicator> = apiCall.data.data.indicators;

                    if (_indicators.length > 0) {
                        _indicators.forEach(
                            i => {
                                i.workers = [];
                                i.comments = '';
                            }
                        )
                    }

                    setIndicators(_indicators);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Неуспешно зареждане на качествените показатели.', translateError);
                }
            }
        );
    }

    const loadData = () => {
        setShowReportForDate(null);
        setReport(null);

        if (year < 2020) return;

        API.getMonthQualityReport(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setReport(apiCall.data.data.report);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'ГРЕШКА', translateError);
                }
            }, month, year
        );
    }


    function TableHeader() {
        return (
            <thead>
            <tr>
                <th rowSpan={2}>Служител</th>
                <th rowSpan={2}>Длъжност</th>
                <th colSpan={4} className={"text-center"}>Всичко</th>
                <th colSpan={monthDays} className={"text-center"}>Дни</th>
            </tr>
            <tr>
                <th className={"text-right"}>П</th>
                <th className={"text-right"}>Р</th>
                <th className={"text-right"}>К</th>
                <th className={"text-right"}>Вс</th>
                {
                    Array(monthDays).fill(1)
                        .map((k, idx) => {
                            return (<th key={idx} rowSpan={2}
                                        className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}
                            >{(idx + 1).toString().padStart(2, '0')}</th>)
                        })

                }
            </tr>
            </thead>
        )
    }


    useEffect(() => {
        if (report) {
            setDepartments(Object.keys(report));
        } else {
            setDepartments([]);
        }
    }, [report])


    function showReport(reportDate: string, shift_id: number) {
        setShowReportForDate({reportDate: reportDate, shiftId: shift_id});
    }

    function getIndicatorName(id: number) {
        let name = '';

        indicators.forEach( i => {
            if(i.id === id) {
                name = i.name;
            }
        })

        return name;
    }

    function Day({day, reportDate}: any) {
        if(!day) return null;

        day = day?.filter(
            (d: any) => (activeTab === "Всички показатели") || (d.indicator_id.toString() === activeTab)
        )

        if (day && day.length > 0) {
            if(day.length === 1) {
                return (
                    <Button className={"w-100"} onClick={() => showReport(reportDate, day[0].shift_id)}
                            title={getIndicatorName(day[0].indicator_id) + ' [' + getShiftName(day[0].shift_id).toLowerCase() + ']'}
                            style={{fontSize: "12px", maxWidth: "3rem"}}>1</Button> )
            } else {
                return <Dropdown>
                    <Dropdown.Toggle variant="primary" style={{fontSize: "12px", maxWidth: "3rem"}}>
                        {day.length}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            day.map(
                                (r: any) =>
                                    <Dropdown.Item
                                        onClick={() => showReport(reportDate, r.shift_id)}>
                                        {getIndicatorName(r.indicator_id)}&nbsp;
                                        [{getShiftName(r.shift_id).toLowerCase()}]</Dropdown.Item>
                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>;
            }

        }

        return null;
    }

    function Department({department}: any) {
        if (!report || !report[department]) return null;
        const dep = Object.keys(report[department]).map(k => report[department][k]);

        dep.forEach(
            (worker: any) => {
                worker.total = 0;
                worker.subTotals = [0, 0, 0];

                const dayKeys = Object.keys(worker.days);
                dayKeys.forEach(
                    (day: any, idx: number) => {
                        if(worker.days[day]) {
                            const _day = worker.days[day].filter(
                                (d: any) => (activeTab === "Всички показатели") || (d.indicator_id.toString() === activeTab)
                            )
                            worker.total += _day.length;
                            if(_day)
                                _day.forEach(
                                    (d: any) => worker.subTotals[d.indicator_id-1] += 1
                                )
                        }
                    }
                );
            }
        );

        return (
            <>
                <tr className={"bg-secondary text-light"}>
                    <th colSpan={monthDays + 7}>{department.toUpperCase()}</th>
                </tr>
                {
                    dep.map(
                        (d: any) => <tr key={department + '_' + d.name}>
                            <td>{d.name}</td>
                            <td>{d.position}</td>
                            <td className={"text-right bg-secondary text-light"}
                                style={{lineHeight: "1.75rem"}}>{zeroToEmptyString(d.subTotals[0])}&nbsp;</td>
                            <td className={"text-right bg-secondary text-light"}
                                style={{lineHeight: "1.75rem"}}>{zeroToEmptyString(d.subTotals[1])}&nbsp;</td>
                            <td className={"text-right bg-secondary text-light"}
                                style={{lineHeight: "1.75rem"}}>{zeroToEmptyString(d.subTotals[2])}&nbsp;</td>
                            <td className={"text-right font-weight-bold bg-secondary text-light"}
                                style={{fontSize: "14px", lineHeight: "1.75rem"}}>{zeroToEmptyString(d.total)}&nbsp;</td>
                            {
                                Object.keys(d.days).map(
                                    (day: any, idx: number) =>
                                        <td key={department + '_' + d.name + '_' + idx} className={"text-center"}>
                                            <Day day={d.days[day]} reportDate={year + '-' + (month+1) + '-' + day} />
                                        </td>
                                )

                            }
                        </tr>
                    )
                }
            </>
        )
    }

    function findIndicatorName(activeTab: string) {
        const indicator = indicators.find(i => i.id.toString() === activeTab);
        return indicator ? indicator.name : activeTab;
    }

    return (
        <>
            <Modal onHide={() => onHide()} show={true} dialogClassName={"w-100 p-3 max-w-none"}>
                <ModalHeader>
                    <Row className={"w-100"}>
                        <Col>
                            <ModalTitle>Справка качество</ModalTitle>
                        </Col>
                        <Col className={"justify-content-end"} xs={"auto"}>
                            <Row className={"flex-nowrap"}>
                                <Col>
                                    <SelectMonthYear month={month} year={year} onChange={
                                        (month, year) => {
                                            setMonth(month);
                                            setYear(year);
                                        }
                                    }/>
                                </Col>
                                <Col>
                                    <Button onClick={() => loadData()} variant={"secondary"} size="sm" className={"mr-1"}
                                            title={"Презареди таблицата"}><FontAwesomeIcon
                                        icon={faSyncAlt}/></Button>

                                    <ExportButton  table={"dataTable"} filename={"report-quality-" +
                                        findIndicatorName(activeTab) +
                                        "-" + (month+1).toString().padStart(2, '0') + '-' + year.toString()} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={"auto"}>
                            <CloseButton onClick={() => onHide()}/>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody style={{maxHeight: '70vh', overflow: 'auto'}}>
                    {
                        loading && <Spinner animation={"border"}/>
                    }
                    {
                        !loading &&
                        <div className={"table-responsive"} style={{maxHeight: '65vh'}}>

                            <Tabs activeKey={activeTab} onSelect={(e) => setActiveTab(e || "Всички показатели")}>
                                <Tab title={"Всички показатели"} eventKey={"Всички показатели"}/>
                                {indicators.map(
                                    k =>
                                        <Tab key={k.id} title={k.name} eventKey={k.id}/>
                                )}
                            </Tabs>

                            <div className="w-100 overflow-auto" style={{maxHeight: '60vh'}}>
                                <Table striped bordered hover size={"sm"} className={"small"} style={{height: '100%'}}
                                       id="dataTable">
                                    <TableHeader/>
                                    <tbody>
                                    {
                                        departments.map(
                                            d => <Department department={d}/>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal>

            {
                showReportForDate &&
                <InputQualityForm machine={{} as Machine}
                                  shift={{id: showReportForDate.shiftId, name: getShiftName(showReportForDate.shiftId)} as Shift}
                                  report_date={showReportForDate.reportDate}
                                  onReload={() => {
                                      loadData();
                                  }}/>
            }

        </>
    )
}
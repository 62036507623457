import {Modal} from "react-bootstrap";
import React from "react";

interface ConfirmModalProps {
    title: string;
    message: string;
    buttons: string[];
    primaryBtn: string;

    onButtonClick: (title: string) => any;
    onClose: () => any;
}


export class ConfirmModal extends React.Component<ConfirmModalProps> {

    closeForm() {
        this.props.onClose();
    }

    clickButton(button: string) {
        this.props.onButtonClick(button);
    }


    render() {
        return (
            <>
                <div style={{position: "fixed", left: 0, top: 0, width: "100vw", height: "100vh", backgroundColor: "black",
                    zIndex: 1500, display: "block", opacity: 0.5}}>
                </div>
                <Modal
                    show={true}
                    onHide={() => this.closeForm()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>{this.props.message}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        {
                            this.props.buttons.map(
                                b => <button type={"button"} key={b}
                                             className={"btn" + (b === this.props.primaryBtn ? " btn-primary" : " btn-secondary")}
                                             onClick={() => this.clickButton(b)}>{b}</button>
                            )
                        }
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}

import {Button, Card, CloseButton, Col, Form, ListGroup, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {Account, API_CALL, EMPTY_REPORT, Incident, Order, Report, Worker} from "../../types";
import {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    onlyPozitive,
    showErrorsInToast, showMsgInToast,
    textToFloat,
    translateError
} from "../../common";
import {API} from "../../api";
import {VXMachineParams} from "../config/machines/ConfigVXMachineForm";
import {InputMachineFormProps} from "./InputPage";
import moment from "moment";
import {WorkersListComponent} from "../config/common/WorkersListComponent";
import {OrdersListComponent} from "./common/OrdersListComponent";
import {IncidentsListComponent} from "./common/IncidentsListComponent";
import {UpdateConfigurationButton} from "./common/UpdateConfigurationButton";


export interface QualityIndicator {
    id: number;
    name: string;
    workers: Array<Worker>;
    comments: string;
}


export interface QualityReport {
    report_date: string;
    shift_id: number;
    indicators: Array<QualityIndicator>;
}


export default function InputQualityForm({viewMode, report_date, shift, onReload}: InputMachineFormProps) {
    const _dateString = moment(report_date).format('DD.MM.YYYY');
    const [working, setWorking] = useState(false);
    const [loading, setLoading] = useState(false);
    const [indicator, setIndicator] = useState<number | null>(null);
    const [indicators, setIndicators] = useState<Array<QualityIndicator>>([]);

    useEffect(() => {

        API.getQualityIndicators(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    const _indicators: Array<QualityIndicator> = apiCall.data.data.indicators;

                    if (_indicators.length > 0) {
                        _indicators.forEach(
                            i => {
                                i.workers = [];
                                i.comments = '';
                            }
                        )
                        setIndicator(0);

                        loadReports();
                    }

                    setIndicators(_indicators);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Неуспешно зареждане на качествените показатели.', translateError);
                }
            }
        );


    }, [])


    function loadReports() {
        API.getQualityReport(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setIndicators(apiCall.data.data.report.indicators);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Неуспешно зареждане на отчета.', translateError);
                }
            }, moment(report_date).format('YYYY-MM-DD'), shift.id
        );
    }


    function saveReport() {
        API.saveQualityReport(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    showMsgInToast('Отчета е запазен успешно.');
                    onReload();
                }
            }, {
                report_date: moment(report_date).format('YYYY-MM-DD'),
                shift_id: shift.id,
                indicators: indicators
            } as QualityReport
        );
    }

    return (
        <Modal show={true} size={"xl"} >
            <Modal.Header>
                <Row className={"w-100"}>
                    <Col>
                        <Modal.Title>
                            <Row>
                                <Col><span>Отчет за <strong
                                    className={"text-primary"}>качество</strong></span></Col>
                                <Col className={"text-right"} xs={"auto"}>
                                <span className={"font-weight-light small"}><i><b><span
                                    className={"mr-3"}>{_dateString}</span><span>{shift.name}</span></b></i></span></Col>
                            </Row>
                        </Modal.Title>
                    </Col>
                    <Col xs={"auto"}><CloseButton onClick={onReload}/></Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Body className={"max-h-60vh scrollable"}>
                        {
                            loading || working ? <Spinner animation="border"/> :
                                <>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <div
                                                        className={"text-primary font-weight-bold text-center w-100 bg-secondary-light p-2 mb-1"}>Изберете
                                                        индикатор:
                                                    </div>
                                                    <ListGroup>
                                                        {
                                                            indicators?.map(
                                                                (i, idx) =>
                                                                    <ListGroup.Item action
                                                                                    active={((indicator !== null) && indicator === idx) || false}
                                                                                    onClick={() => setIndicator(idx)}
                                                                                    key={i.id + '_' + i.name}>
                                                                        {i.name}
                                                                    </ListGroup.Item>
                                                            )
                                                        }
                                                    </ListGroup>
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col sm={12}>
                                                            {
                                                                (indicator !== null) && indicators &&
                                                                <WorkersListComponent
                                                                    workers={indicators[indicator]?.workers || []}
                                                                    forDate={moment(report_date).format('YYYY-MM-DD')}
                                                                    defWorkHours={8}
                                                                    viewMode={viewMode}
                                                                    showWorkHours={false}
                                                                    onChange={(workers) => {
                                                                        indicators[indicator].workers = workers;
                                                                        setIndicators([...indicators]);
                                                                    }}/>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} className={"mt-3"}>
                                            {
                                                (indicator !== null) && indicators &&
                                                <Form.Group className={" bg-secondary-light p-2"}>
                                                    <Form.Label
                                                        className={"text-primary font-weight-bold text-center w-100"}>Коментари
                                                        и забележки</Form.Label>
                                                    <Form.Control as={"textarea"} readOnly={viewMode}
                                                                  value={indicators[indicator]?.comments || ''}
                                                                  onChange={(e) => {
                                                                      indicators[indicator].comments = e.target.value;
                                                                      setIndicators([...indicators]);
                                                                  }}
                                                    />
                                                </Form.Group>
                                            }
                                        </Col>
                                    </Row>
                                </>
                        }
                    </Card.Body>
                    <Card.Footer>
                        {
                            !viewMode &&
                            (working ? <Spinner animation="border"/> :
                                    <>
                                        <Button onClick={() => saveReport()}><FontAwesomeIcon
                                            icon={faSave}/> Запази</Button>
                                    </>
                            )
                        }
                    </Card.Footer>
                </Card>
            </Modal.Body>
        </Modal>
    )
}

import {
    Account,
    API_CALL,
    EMPTY_INCIDENT,
    Incident,
    Machine,
    MachineIncident,
    Order,
    Team,
    Worker
} from "../../../types";
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalTitle, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBox,
    faFireAlt,
    faMinus, faPen,
    faPlus,
    faUser,
    faUserMinus,
    faUserPlus,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    onlyPozitive,
    showErrorsInToast,
    textToInt,
    translateError
} from "../../../common";
import {TimePickerComponent} from "../../../common/TimePickerComponent";
import {API} from "../../../api";

export interface IncidentsListComponentProps {
    viewMode?: boolean;
    className?: any;
    incidents: Incident[];
    machine: Machine;
    onChange: (orders: Incident[]) => any;
}

export function IncidentsListComponent({viewMode, className, machine, incidents, onChange}: IncidentsListComponentProps) {

    const [showNewIncident, setShowNewIncident] = useState(false);
    const [working, setWorking] = useState(false);
    const [showTimePickerForFrom, setShowTimePickerForFrom] = useState(false);
    const [showTimePickerForTo, setShowTimePickerForTo] = useState(false);
    const [incident, setIncident] = useState<Incident|null>(null);
    const [editedIncident, setEditedIncident] = useState<Incident|null>(null);
    const [machineIncidents, setMachineIncidents] = useState<MachineIncident[]>([]);
    const [selectedIncident, setSelectedIncident] = useState<string>('');
    const [incidentComment, setIncidentComment] = useState<string>('');

    function addIncident(incident: Incident|null) {
        if(!incident) return;

        machineIncidents.forEach(
            i => {
                if(i.id.toString() === selectedIncident) incident.description = i.name
            }
        )
        incident.comment = incidentComment;

        if(editedIncident) {
            editedIncident.from = incident.from;
            editedIncident.to = incident.to;
            editedIncident.description = incident.description;
            editedIncident.comment = incident.comment;
        } else {
            incidents.push(incident);
        }

        setShowNewIncident(false);
        setIncident(null);
        setSelectedIncident('-1');
        setIncidentComment('');
        onChange(incidents);
    }

    function removeIncident(incident: Incident) {
        onChange(incidents.filter(u => !((u.from === incident.from) && (u.to === incident.to) && (u.description === incident.description) && (u.comment === incident.comment))));
    }

    function editIncident(incident: Incident) {
        setIncident(incident);
        setEditedIncident(incident);

        const mInc: MachineIncident | undefined = machineIncidents.find(
            mi => mi.name === incident.description
        );

        setSelectedIncident(mInc?.id.toString() || '-1');
        setIncidentComment(incident.comment);

        setShowNewIncident(true);
    }


    useEffect(() => {
        API.getMachineIncidents(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setMachineIncidents(api_call.data.data.incidents);
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на списъка с аварии!', translateError)
            }, machine.id
        );

    }, []);

    const createNewIncident = function() {
        setIncident(null);
        setEditedIncident(null);
        setSelectedIncident('-1');
        setIncidentComment('');
        setShowNewIncident(true);
    }

    return (
        <>
            <Form.Group className={className + " bg-secondary-light p-2"}>
                <Form.Label className={"text-primary font-weight-bold text-center w-100"}>Аварии / друг вид престои</Form.Label>
                {
                    working && <Spinner animation={"border"} />
                }
                {
                    !working &&
                    <Table size={"sm"} className={"mb-1"}>
                        <tbody>
                        {
                            incidents.length === 0 && <tr>
                                <td colSpan={2}>
                                    <div><strong>Няма аварии / друг вид престои</strong></div>
                                </td>
                            </tr>
                        }
                        {
                            incidents.map(
                                (u, idx) =>
                                    <tr key={idx}>
                                        <td>
                                            <FontAwesomeIcon icon={faFireAlt}/>
                                        </td>
                                        <td>
                                            {u.from}
                                        </td>
                                        <td>
                                            {u.to}
                                        </td>
                                        <td>
                                            {u.description}
                                        </td>
                                        <td>
                                            {u.comment}
                                        </td>
                                        {
                                            !viewMode && <td className={"text-right text-nowrap"}>
                                                <Button type={"button"} size={"sm"} onClick={() => editIncident(u)}>
                                                    <FontAwesomeIcon icon={faPen}/>
                                                </Button>&nbsp;
                                                <Button type={"button"} size={"sm"} onClick={() => removeIncident(u)}>
                                                    <FontAwesomeIcon icon={faMinus}/>
                                                </Button>
                                            </td>
                                        }
                                    </tr>
                            )
                        }

                        {
                            !viewMode && <tr>
                                <td className={"text-right"} colSpan={6}>
                                    <Button className={"w-100"} type={"button"} size={"sm"}
                                            onClick={() => createNewIncident()}><FontAwesomeIcon
                                        icon={faPlus}/> Добави авария/престой</Button>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                }
            </Form.Group>

            <Modal centered show={showNewIncident}>
                <Modal.Header>
                    <ModalTitle>Авария/престой</ModalTitle>
                </Modal.Header>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Начало</Form.Label>
                                    <Form.Control value={incident?.from || ''} readOnly onClick={()=>setShowTimePickerForFrom(true)} />
                                    {
                                        showTimePickerForFrom &&
                                        <TimePickerComponent
                                            onSelect={(t: string) => setIncident(i => {
                                                setShowTimePickerForFrom(false);
                                                return {...(i ? i : EMPTY_INCIDENT), from: t}
                                            })}
                                            value={incident?.from || ''}/>
                                    }
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Край</Form.Label>
                                    <Form.Control value={incident?.to || ''} readOnly onClick={()=>setShowTimePickerForTo(true)} />
                                    {
                                        showTimePickerForTo &&
                                        <TimePickerComponent
                                            onSelect={(t: string) => setIncident(i => {
                                                setShowTimePickerForTo(false);
                                                return {...(i ? i : EMPTY_INCIDENT), to: t}
                                            })}
                                            value={incident?.to || ''}/>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Категория</Form.Label>
                            <Form.Control as="select"
                                          value={selectedIncident}
                                          onChange={(e) => setSelectedIncident(e.target.value)}
                            >
                                <option></option>
                                {
                                    machineIncidents.map(
                                        i =>
                                            <option value={i.id} key={i.id}>{i.name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Описание</Form.Label>
                            <Form.Control as="textarea"
                                          value={incidentComment}
                                          onChange={(e) => setIncidentComment(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button type={"button"} onClick={() => addIncident(incident)}>Запази</Button>&nbsp;
                    <Button type={"button"} onClick={() => setShowNewIncident(false)}>Откажи</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
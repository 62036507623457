import {Account, Team, Worker} from "../../../types";
import {Button, CloseButton, Col, Form, Modal, ModalBody, ModalTitle, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faUserMinus, faUserPlus, faUsers} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import SelectUserComponent from "../users/SelectUserComponent";
import SelectTeamComponent from "../teams/SelectTeamComponent";
import {onlyPozitive, textToInt} from "../../../common";

export interface WorkersListComponentProps {
    forDate: string;
    viewMode?: boolean;
    className?: any;
    workers: Worker[];
    defWorkHours?: number;
    showWorkHours?: boolean;
    onChange: (workers: Worker[]) => any;
}

export function WorkersListComponent({
                                         forDate,
                                         viewMode,
                                         className,
                                         workers,
                                         onChange,
                                         defWorkHours,
                                         showWorkHours
                                     }: WorkersListComponentProps) {

    const [showUsersList, setShowUsersList] = useState(false);
    const [showTeamsList, setShowTeamsList] = useState(false);

    function addWorker(user: Account) {
        if (workers.findIndex(
            value => value.id === user.id
        ) === -1) workers.push({id: user.id, name: user.name, workHours: defWorkHours || 8, workMinutes: 0});

        setShowUsersList(false);
        onChange(workers);
    }

    function addTeam(team: Team) {
        setShowTeamsList(false);

        team.usersList.forEach(
            u => {
                if (workers.findIndex(w => w.id === u.id) === -1) workers.push({...u, workHours: 8});
            }
        )

        onChange(workers);
    }

    function removeWorker(user: Worker) {
        onChange(workers.filter(u => u.id !== user.id));
    }


    return (
        <div style={{minWidth: 400}}>
            <Form.Group className={className + " bg-secondary-light p-2"}>
                <Form.Label className={"text-primary font-weight-bold text-center w-100"}>Служители</Form.Label>
                <Table size={"sm"} className={"mb-1 w-100"}>
                    <tbody>
                    {
                        workers.length === 0 && <tr>
                            <td colSpan={3}>
                                <div><strong>Няма избрани служители</strong></div>
                            </td>
                        </tr>
                    }
                    {
                        workers.map(
                            u =>
                                <tr key={u.id}>
                                    <td>
                                        <FontAwesomeIcon icon={faUser}/>&nbsp;{u.name}
                                    </td>
                                    <td className={"w-75px"}>
                                        {showWorkHours &&
                                            <Row className={"justify-content-end"}>
                                                <Form.Control className={"w-50"} value={u.workHours || ''}
                                                              readOnly={viewMode}
                                                              onChange={event => {
                                                                  u.workHours = onlyPozitive(textToInt(event.target.value));
                                                                  onChange(workers.concat([]));
                                                              }}
                                                /> <span className={"pt-2 pl-1 pr-1 small"}>часа</span> </Row>}
                                    </td>
                                    <td className={"w-75px"}>
                                        {showWorkHours &&
                                            <Row className={"justify-content-end pl-1 pr-1"}>
                                                <Form.Control className={"w-50"} value={u.workMinutes || ''}
                                                              readOnly={viewMode}
                                                              onChange={event => {
                                                                  u.workMinutes = onlyPozitive(textToInt(event.target.value));
                                                                  onChange(workers.concat([]));
                                                              }}
                                                /> <span className={"pt-2 pl-1 small"}>мин</span> </Row>}
                                    </td>

                                    {
                                        !viewMode && <td className={"text-right"}>
                                            <Button type={"button"} size={"sm"} onClick={() => removeWorker(u)}>
                                                <FontAwesomeIcon icon={faUserMinus}/>
                                            </Button>
                                        </td>
                                    }
                                </tr>
                        )
                    }

                    {
                        !viewMode && <tr>
                            <td className={"text-right"} colSpan={2}>
                                <Row>
                                    <Col className={"text-nowrap"} md="12" lg="6">
                                        <Button className={"w-100"} type={"button"} size={"sm"}
                                                onClick={() => setShowUsersList(true)}><FontAwesomeIcon
                                            icon={faUserPlus}/> Добави служител</Button></Col>
                                    <Col className={"text-nowrap"} md="12" lg="6">
                                        <Button className={"w-100"} type={"button"} size={"sm"}
                                                onClick={() => setShowTeamsList(true)}><FontAwesomeIcon
                                            icon={faUsers}/> Добави екип</Button></Col>
                                </Row>
                            </td>
                        </tr>
                    }
                    </tbody>
                </Table>
            </Form.Group>

            <Modal centered size="lg" show={showUsersList} onHide={() => setShowUsersList(false)}>
                <Modal.Header closeButton>
                    <ModalTitle>Изберете служител</ModalTitle>
                </Modal.Header>
                <ModalBody>
                    <SelectUserComponent onSelect={(u: Account) => addWorker(u)} forDate={forDate}
                                         onCancel={() => setShowUsersList(false)}/>
                </ModalBody>
            </Modal>

            <Modal centered size="lg" show={showTeamsList} onHide={() => setShowTeamsList(false)}>
                <Modal.Header closeButton>
                    <ModalTitle>Изберете екип</ModalTitle>
                </Modal.Header>
                <ModalBody>
                    <SelectTeamComponent forDate={forDate} onSelect={(t: Team) => addTeam(t)} onCancel={() => setShowTeamsList(false)}/>
                </ModalBody>
            </Modal>
        </div>
    )
}
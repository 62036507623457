import axios, {AxiosRequestConfig} from "axios";
import {API_CALL, NET_STATUS, Property, PropertyValue, SALE_TYPE, Account} from "./types";
import {defaultAxiosResponse, FACTORY} from "./factories";
import {VALIDATION} from "./validations";
import {isAuthError, isLoading, isSuccess} from "./common";
import {API_PATHS, PATHS} from "./paths";
import {SalaryConfig} from "./pages/input/SalaryPage";


export const API_CLASS = class {
    public site = 'https://glasko.vivisoft-bg.com/';
    private host = 'https://glasko.vivisoft-bg.com/api/api/';


    // public site = 'http://localhost:3000/';
    // private host = 'http://localhost:8000/';

    private token: string | null = null;
    public user: Account|null = null;

    constructor(token: string = '', userid = -1) {
        if (token.length > 0) {
            this.token = token;
            // localStorage.setItem('t', this.token);
        } else {
            // this.token = localStorage.getItem('t');

            const search = window.location.search;
            const params = new URLSearchParams(search);
            this.token = params.get('t');
        }
    }

    public getToken(): string|null {
        return this.token;
    }

    public getAPITokenAsQuery (query: string = ""): string {
        return (API.getToken() ? (query.length > 0 ? query + "&t=" : "?t=") + API.getToken() : "")
    }


    private checkForAuthError(apiCall: API_CALL) {
        if (isAuthError(apiCall)) {
            window.location.href = PATHS.login;
        }
    }

    private callOnChangeEvent(apiCall: API_CALL) {
        this.checkForAuthError(apiCall);
        if (apiCall && apiCall.onChange) {
            apiCall.onChange(apiCall);
        }
    }

    private callOnChangeEventWithError(apiCall: API_CALL, err: any) {
        apiCall.status = NET_STATUS.ERROR;
        apiCall.data = defaultAxiosResponse;
        apiCall.error = err;

        this.callOnChangeEvent(apiCall);
    }

    private callOnChangeEventWithSuccess(apiCall: API_CALL, res: any) {
        apiCall.status = NET_STATUS.SUCCESS;
        apiCall.data = res;
        apiCall.error = null;
        this.callOnChangeEvent(apiCall);
    }

    public doGetRequest(onChange: any, url: string, requestConfig: AxiosRequestConfig = {}) {
        const me = this;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        apiCall.onChange = onChange;
        me.callOnChangeEvent(apiCall);
        if (me.token) {
            requestConfig = {...requestConfig, headers: {...requestConfig.headers, 'Authorization': 'Bearer ' + me.token}};
        }
        axios.get(this.host + url, requestConfig)
            .then(res => {
                me.callOnChangeEventWithSuccess(apiCall, res)
            }, err => {
                me.callOnChangeEventWithError(apiCall, err)
            });
    }

    public doDeleteRequest(onChange: any, url: string, data: any, requestConfig: AxiosRequestConfig = {}) {
        const me = this;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        apiCall.onChange = onChange;
        me.callOnChangeEvent(apiCall);
        if (me.token) {
            requestConfig = {...requestConfig, headers: {...requestConfig.headers, 'Authorization': 'Bearer ' + me.token}};
        }
        axios.delete(this.host + url, {...requestConfig, data: data})
            .then(res => {
                me.callOnChangeEventWithSuccess(apiCall, res)
            }, err => {
                me.callOnChangeEventWithError(apiCall, err)
            });
    }

    public doPostRequest(onChange: any, url: string, data: any, requestConfig: AxiosRequestConfig = {}) {
        const me = this;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        apiCall.onChange = onChange;
        me.callOnChangeEvent(apiCall);
        if (me.token) {
            requestConfig = {...requestConfig, headers: {...requestConfig.headers, 'Authorization': 'Bearer ' + me.token}};
        }

        axios.post(this.host + url, data, requestConfig)
            .then(res => {
                me.callOnChangeEventWithSuccess(apiCall, res)
            }, err => {
                me.callOnChangeEventWithError(apiCall, err)
            });
    }

    public doPutRequest(onChange: any, url: string, data: any, requestConfig: AxiosRequestConfig = {}) {
        const me = this;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        apiCall.onChange = onChange;
        me.callOnChangeEvent(apiCall);
        if (me.token) {
            requestConfig = {...requestConfig, headers: {...requestConfig.headers, 'Authorization': 'Bearer ' + me.token}};
        }

        axios.put(this.host + url, data, requestConfig)
            .then(res => {
                me.callOnChangeEventWithSuccess(apiCall, res)
            }, err => {
                me.callOnChangeEventWithError(apiCall, err)
            });
    }

    public createFormData(data: any) {
        const fd = new FormData();
        Object.keys(data).forEach(
            k => fd.append(k, data[k])
        );
        return fd;
    }

    public getAllUsersNotSigned(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.allUsersNotSigned, {});
    }

    public getAllAccounts(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.accounts, {});
    }

    public getAccount(onChange: any, accountId: number) {
        this.doGetRequest(onChange, API_PATHS.accounts + '/' + accountId, {});
    }

    public saveAccount(onChange: any, data: any) {
        const fd = this.createFormData(data);
        this.doPostRequest(onChange, API_PATHS.accounts, fd);
    }

    public deleteAccount(onChange: any, accountId: number) {
        this.doDeleteRequest(onChange, API_PATHS.accounts + "/" + accountId, {});
    }

    public getMachines(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.machines);
    }

    public getShifts(onChange: any, machineId: number) {
        this.doGetRequest(onChange, API_PATHS.shifts + `?machineId=${machineId}`);
    }

    public saveMachine(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.machines, this.createFormData(data));
    }

    public getReport(onChange: any, machine_id: number, shift_id: number, report_date: string) {
        this.doGetRequest(onChange, API_PATHS.input+'?m=' + machine_id + '&s=' + shift_id + '&d=' + report_date );
    }

    public moveReport(onChange: any, report_id: number, shift_id: number, report_date: string) {
        this.doPostRequest(onChange, API_PATHS.input+'/' + report_id, {report_date: report_date, shift_id: shift_id});
    }

    public removeReport(onChange: any, report_id: number) {
        this.doDeleteRequest(onChange, API_PATHS.input+'/' + report_id, null );
    }

    public saveReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.input, data);
    }

    public updateReportConfig(onChange: any, reportId: number) {
        this.doGetRequest(onChange, API_PATHS.input + "/" + reportId.toString() + '/config');
    }

    public saveTeam(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.teams, this.createFormData(data));
    }

    public deleteTeam(onChange: any, teamId: number) {
        this.doDeleteRequest(onChange, API_PATHS.teams + "/" + teamId, {});
    }

    public getGlasses(onChange: any, ovenType: 'BIG'|'SMALL'|'BAVELLONI'|'LISEC'|'LISEC2'|'NONE'|'cut') {
        const url = API_PATHS.glasses + '/' + ovenType.toLocaleLowerCase();
        this.doGetRequest(onChange, url, {});
    }

    public getCutOtherActivities(onChange: any) {
        const url = API_PATHS.glasses + '/cut/activities';
        this.doGetRequest(onChange, url, {});
    }

    public getMachineIncidents(onChange: any, machine_id: number) {
        const url = API_PATHS.machines + '/incidents/' + machine_id;
        this.doGetRequest(onChange, url, {});
    }

    public saveMachineIncident(onChange: any, data: any) {
        const url = API_PATHS.machines + '/incidents';
        this.doPostRequest(onChange, url, data);
    }

    public deleteMachineIncident(onChange: any, id: number) {
        const url = API_PATHS.machines + '/incidents/' + id;
        this.doDeleteRequest(onChange, url, {});
    }

    public getGlassSizes(onChange: any) {
        const url = API_PATHS.glasses + '/sizes';
        this.doGetRequest(onChange, url, {});
    }

    public getAppModules(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.appModules, {});
    }

    public getReportCount(onChange: any, reportId: number, report: string, date: string, shift_id: number) {
        this.doGetRequest(onChange, API_PATHS.validateReport + '?report=' + report + '&report_id=' + reportId + '&date=' + date + '&shift=' + shift_id, {});
    }

    public postUserLogin(onChange: any, email: string, password: string) {
        const me = this;
        const _onChange = onChange;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        if (!VALIDATION.validateLoginData(apiCall, email, password)) {
            this.callOnChangeEvent(apiCall);
            return;
        }

        this.doPostRequest(
            (apiCall: API_CALL) => {
                if (isLoading(apiCall)) return;

                if (isSuccess(apiCall) && apiCall.data.data.status === "ok") {
                    me.token = apiCall.data?.data?.token;
                    localStorage.setItem('t', me.token || '');
                } else {
                }

                apiCall.onChange = _onChange;
                me.callOnChangeEvent(apiCall);
            }, API_PATHS.user_login, {email: email, password: password}, {});
    }

    public postUserLoginByKey(onChange: any, key: string) {
        const me = this;
        const _onChange = onChange;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);

        this.doGetRequest(
            (apiCall: API_CALL) => {
                if (isLoading(apiCall)) return;

                if (isSuccess(apiCall) && apiCall.data.data.status === "ok") {
                    me.token = apiCall.data?.data?.token;
                    localStorage.setItem('t', me.token || '');
                } else {
                }

                apiCall.onChange = _onChange;
                me.callOnChangeEvent(apiCall);
            }, API_PATHS.user_login + '?key=' + key);
    }

    public getUserData(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.user, {});
    }

    public getUserLogout(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.logout, {});
    }


    // PARAMETERS

    public getParameterValues(onChange: any, parameterId: number) {
        const afterLoaded = function (apiCall: API_CALL) {
            if (isSuccess(apiCall) && apiCall.data.data.status === "ok") {
                apiCall.data.data.items.descriptor = apiCall.data.data.items.descriptorJSON ? JSON.parse(apiCall.data.data.items.descriptorJSON) : {};
                apiCall.data.data.items.values = (apiCall.data.data.items.valuesJSON ? JSON.parse(apiCall.data.data.items.valuesJSON) : []).map(
                    (item: any, idx: number) => {
                        const newPropVal: PropertyValue = {
                            id: idx,
                            value: item['value'],
                            description: {...item}
                        } as PropertyValue;
                        delete newPropVal.description['value'];
                        return newPropVal;
                    }
                );
            }
            onChange(apiCall);
        }
        this.doGetRequest(afterLoaded, API_PATHS.properties + '/' + parameterId, {});
    }
    public setParameterValues(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.properties, this.createFormData(data));
    }
    public deleteParameterValues(onChange: any, propertyId: number, valueId: number) {
        this.doDeleteRequest(onChange, API_PATHS.properties + '/' + propertyId, {valueId: valueId});
    }


    // CLIENTS
    public getClient(onChange: any, id: number = 0) {
        this.doGetRequest(onChange,API_PATHS.client + '/' + id);
    }

    public getClients(onChange: any, filter: any, pageIdx: number = 0, pageSize: number = 10) {
        let query_str = "?";
        Object.keys(filter).forEach(
            k => query_str += k + "=" + encodeURI(filter[k]) + "&"
        );
        query_str += "page_idx="+pageIdx+"&page_size="+pageSize;
        this.doGetRequest(onChange,API_PATHS.clients + query_str);
    }

    public saveClientData(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.clients, {...data});
    }

    public deleteClientData(onChange: any, clientId: number) {
        this.doDeleteRequest(onChange, API_PATHS.clients + '/' + clientId, {});
    }

    public getClientAccounts(onChange: any, clientId: number) {
        this.doGetRequest(onChange,API_PATHS.clients + '/accounts/' + clientId);
    }

    public getClientPaletList(onChange: any, clientId: number) {
        this.doGetRequest(onChange,API_PATHS.clients + '/palets/' + clientId);
    }

    public setClientPaletStartBalance(onChange: any, clientId: number, balance: number) {
        this.doPostRequest(onChange,API_PATHS.clients + '/palets/balance/' + clientId, {balance: balance});
    }



    // CLIENT OPERATIONS

    public getClientOperations(onChange: any, clientId: number) {
        this.doGetRequest(onChange,API_PATHS.clients + '/accounts/' + clientId);
    }

    public saveClientOperation(onChange: any, data: any, clientId: number, operationId: number) {
        this.doPostRequest(onChange,API_PATHS.clients + '/accounts/' + operationId, {...data, client_id: clientId});
    }

    public deleteClientOperation(onChange: any, operationId: number) {
        this.doDeleteRequest(onChange,API_PATHS.clients + '/accounts/' + operationId, {});
    }



    // SALARY OPERATIONS

    public getSalaryReport(onChange: any, month: number, year: number) {
        this.doGetRequest(onChange,API_PATHS.salary + '/' + month.toString() + '/' + year.toString());
    }

    public setSalaryReport(onChange: any, salaryConfig: SalaryConfig | null) {
        if(!salaryConfig) return;
        this.doPostRequest(onChange,API_PATHS.salary+'/config', salaryConfig );
    }

    public copySalaryConfigConfiguration(onChange: any, fromMonth: number, fromYear: number, toMonth: number, toYear: number) {
        this.doPostRequest(onChange,API_PATHS.salary+'/copy-config', {
            from_month: fromMonth, from_year: fromYear,
            to_month: toMonth, to_year: toYear
        } );
    }

    // STANDARTS

    public postStandarts(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.standarts, data);
    }

    public getStandarts(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.standarts);
    }

    // REPORTS


    public getQuantitiesReport(onChange: any, month: number, year: number) {
        this.doGetRequest(onChange, API_PATHS.quantities + "/" + month + "/" + year );
    }

    public getIncidentsReport(onChange: any, month: number, year: number) {
        this.doGetRequest(onChange, API_PATHS.incidents + "/" + month + "/" + year );
    }

    public getStopsReport(onChange: any, month: number, year: number) {
        this.doGetRequest(onChange, API_PATHS.stops + "/" + month + "/" + year );
    }

    public getRepairsReport(onChange: any, month: number, year: number) {
        this.doGetRequest(onChange, API_PATHS.repairsref + "/" + month + "/" + year );
    }

    public getPerformance(onChange: any, mode: string, date: string) {
        this.doGetRequest(onChange, API_PATHS.performance + "?mode=" + mode + "&date=" + date);
    }

    public getDetailed(onChange: any, mode: string, date: string) {
        this.doGetRequest(onChange, API_PATHS.detailed + "?mode=" + mode + "&date=" + date);
    }

    public getDetailedVX(onChange: any, dateFrom: string, dateTo: string) {
        this.doGetRequest(onChange, API_PATHS.detailedVX + "?dateFrom=" + dateFrom + "&dateTo=" + dateTo);
    }

    public getUserReports(onChange: any, id: number, date: string) {
        this.doGetRequest(onChange, API_PATHS.input + "/" + id + "/" + date);
    }

    public recalculatePerformance(onChange: any, mode: string, date: string) {
        this.doGetRequest(onChange, API_PATHS.performance + "?recalc=true&mode=" + mode + "&date=" + date);
    }

    public getCutOtherActivitiesReport(onChange: any, month: number, year: number) {
        this.doGetRequest(onChange, API_PATHS.cutOtherActivities + "/" + month + "/" + year);
    }

    // QUALITY

    public getQualityIndicators(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.quality + '/indicators');
    }

    public getQualityReport(onChange: any, report_date: string, shift_id: number) {
        this.doGetRequest(onChange, API_PATHS.quality + '/' + report_date + '/'+shift_id);
    }

    public saveQualityReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.quality, data);
    }

    public getMonthQualityReport(onChange: any, month: number, year: number) {
        this.doGetRequest(onChange, API_PATHS.quality + "/report/" + month + "/" + year);
    }

}

export const API = new API_CLASS();

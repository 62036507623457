import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Container, ListGroup, Row, Tab} from "react-bootstrap";
import {faFireAlt, faIndustry, faUserFriends, faUsers} from "@fortawesome/free-solid-svg-icons";
import React, {Suspense, useState} from "react";
import ConfigIncidentsPage from "./incidents/ConfigIncidentsPage";

export function ConfigPage() {
    const ConfigUsersPage = React.lazy(() => import("./users/ConfigUsersPage"));
    const ConfigTeamsPage = React.lazy(() => import("./teams/ConfigTeamsPage"));
    const ConfigMachinesPage = React.lazy(() => import("./machines/ConfigMachinesPage"));

    enum CONFIG_TABS {
        'USERS' = 'users',
        'MACHINES' = 'machines',
        'USER_GROUPS' = 'user_groups',
        'INCIDENTS' = 'incidents',
    }

    const defaultActiveKey = CONFIG_TABS.USERS;

    const [activeTab, setActiveTab] = useState<string | null>(null);


    return (
        <>
            <Container fluid>
                <Tab.Container id="list-group-tabs-example" defaultActiveKey={undefined}>
                    <Row>
                        <Col xs={"auto"}>
                            <Row>

                                <ListGroup className={"w-320px ml-4"} onSelect={eventKey => setActiveTab(eventKey)}>
                                    <ListGroup.Item className={"text-center bg-primary text-light pt-3"}>
                                        <h4>Номенклатури</h4>
                                    </ListGroup.Item>

                                    <ListGroup.Item eventKey={CONFIG_TABS.USERS} action>
                                        <FontAwesomeIcon icon={faUsers} className={"mr-2"}/>Потребители
                                    </ListGroup.Item>
                                    <ListGroup.Item eventKey={CONFIG_TABS.USER_GROUPS} action>
                                        <FontAwesomeIcon icon={faUserFriends} className={"mr-2"}/>Екипи
                                    </ListGroup.Item>
                                    <ListGroup.Item eventKey={CONFIG_TABS.MACHINES} action>
                                        <FontAwesomeIcon icon={faIndustry} className={"mr-3"}/>Машини
                                    </ListGroup.Item>
                                    <ListGroup.Item eventKey={CONFIG_TABS.INCIDENTS} action>
                                        <FontAwesomeIcon icon={faFireAlt} className={"mr-3"}/>Аварии / друг вид престои
                                    </ListGroup.Item>
                                </ListGroup>
                            </Row>
                        </Col>

                        <Col>
                            <Suspense fallback={() => <div>Зареждам...</div>}>
                                {activeTab === CONFIG_TABS.USERS && <ConfigUsersPage/>}
                                {activeTab === CONFIG_TABS.USER_GROUPS && <ConfigTeamsPage/>}
                                {activeTab === CONFIG_TABS.MACHINES && <ConfigMachinesPage/>}
                                {activeTab === CONFIG_TABS.INCIDENTS && <ConfigIncidentsPage/>}
                            </Suspense>
                        </Col>
                    </Row>
                </Tab.Container>


            </Container>
        </>
    )
}

import ModalHeader from "react-bootstrap/ModalHeader";
import {
    Button,
    CloseButton,
    Col,
    Modal,
    ModalBody,
    ModalTitle,
    Row, Spinner, Tab,
    Table, Tabs
} from "react-bootstrap";
import {
    isError,
    isLoading,
    isSuccess, minutesToHours, showErrorsInToast, translateError, zeroToEmptyString, zeroToEmptyStringAsCurrency,
} from "../../common";
import React, {useEffect, useState} from "react";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../../api";
import {API_CALL, Machine} from "../../types";
import moment from "moment";
import {SelectMonthYear} from "../../common/SelectMonthYear";
import ExportButton from "../../common/ExportButton";


export interface CutOtherActivitiesModalProps {
    onHide: () => any;
}

type OtherActivity = {
    id: string;
    title: string;
}


export default function CutOtherActivitiesModal({onHide}: CutOtherActivitiesModalProps) {

    const [loading, setLoading] = useState(false);
    const [report, setReprot] = useState<any>(null);
    const [activities, setActivities] = useState<Array<OtherActivity>>([]);

    const [month, setMonth] = useState(moment().month());
    const [year, setYear] = useState(moment().year());
    const [monthDays, setMonthDays] = useState(30);

    useEffect(() => {
        setMonth(moment().month());
        setYear(moment().year());
    }, [])

    useEffect(() => {
        const DAYS_IN_MONTH = moment().day(1).month(month).year(year).daysInMonth();
        setMonthDays(DAYS_IN_MONTH);
        loadData()
    }, [month, year])


    const loadData = () => {
        if (year < 2020) return;
        setReprot(null);

        API.getCutOtherActivitiesReport(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setReprot(apiCall.data.data.report);
                    setActivities(apiCall.data.data.otherActivities);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'ГРЕШКА', translateError);
                }
            }, month, year
        );
    }

    function TableHeader() {
        return (
            <thead>
            <tr>
                <th rowSpan={2} style={{ minWidth: "280px"}}>Дейност</th>
                {
                    Array(monthDays).fill(1)
                        .map((k, idx) => {
                            return (<th key={idx} colSpan={6}
                                        className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}
                            >{(idx + 1).toString().padStart(2, '0')}</th>)
                        })

                }
            </tr>
            <tr>
                {
                    Array(monthDays).fill(1)
                        .map((k, idx) => {
                            return (
                                <>
                                    <th key={`${idx}-0`}
                                        className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}>LП
                                    </th>
                                    <th key={`${idx}-1`}
                                        className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}>LВ
                                    </th>
                                    <th key={`${idx}-2`}
                                        className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}>LР
                                    </th>
                                    <th key={`${idx}-3`}
                                        className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}>VП
                                    </th>
                                    <th key={`${idx}-4`}
                                        className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}>VВ
                                    </th>
                                    <th key={`${idx}-5`}
                                        className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}>VР
                                    </th>
                                </>
                            )
                        })
                }
            </tr>
            </thead>
        )
    }

    return (
        <>
            <Modal onHide={() => onHide()} show={true} dialogClassName={"w-100 p-3 max-w-none"}>
                <ModalHeader>
                    <Row className={"w-100"}>
                        <Col>
                            <ModalTitle>Справка Други дейности в отдел Рязане</ModalTitle>
                        </Col>
                        <Col className={"justify-content-end"} xs={"auto"}>
                            <Row className={"flex-nowrap"}>
                                <Col>
                                    <SelectMonthYear month={month} year={year} onChange={
                                        (month, year) => {
                                            setMonth(month);
                                            setYear(year);
                                        }
                                    }/>
                                </Col>
                                <Col>
                                    <Button onClick={() => loadData()} variant={"secondary"} size="sm"
                                            title={"Презареди таблицата"}><FontAwesomeIcon
                                        icon={faSyncAlt}/></Button>

                                    <ExportButton tab={"Други дейности в Рязане"}
                                                  table={"tableCutOthers"}
                                                  suffix={month.toString() + '.' + year.toString()}/>

                                </Col>
                            </Row>
                        </Col>
                        <Col xs={"auto"}>
                            <CloseButton onClick={() => onHide()}/>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody style={{maxHeight: '70vh', overflow: 'auto'}}>
                    {
                        loading && <Spinner animation={"border"}/>
                    }
                    {
                        !loading && report && activities &&
                        <div className={"table-responsive"} style={{maxHeight: '65vh'}}>

                            <div className="w-100 overflow-auto"
                                 style={{maxHeight: '60vh'}}>

                                <Table striped bordered hover size={"sm"} className={"small"}
                                       style={{height: '100%'}}
                                       id="tableCutOthers">
                                    <TableHeader/>
                                    <tbody>
                                    {
                                        activities && activities.map((activity, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{activity.title}</td>
                                                    {
                                                        Array(monthDays).fill(1)
                                                            .map((k, idx) => {
                                                                return (
                                                                    <>
                                                                        <td key={`${idx}-0`}
                                                                            className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}>
                                                                            {zeroToEmptyString(report[activity.id][idx][0])}
                                                                        </td>
                                                                        <td key={`${idx}-1`}
                                                                            className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}>
                                                                            {zeroToEmptyString(report[activity.id][idx][1])}
                                                                        </td>
                                                                        <td key={`${idx}-2`}
                                                                            className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}>
                                                                            {zeroToEmptyString(report[activity.id][idx][2])}
                                                                        </td>
                                                                        <td key={`${idx}-3`}
                                                                            className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}>
                                                                            {zeroToEmptyString(report[activity.id][idx][3])}
                                                                        </td>
                                                                        <td key={`${idx}-4`}
                                                                            className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}>
                                                                            {zeroToEmptyString(report[activity.id][idx][4])}
                                                                        </td>
                                                                        <td key={`${idx}-5`}
                                                                            className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}>
                                                                            {zeroToEmptyString(report[activity.id][idx][5])}
                                                                        </td>
                                                                    </>
                                                                )
                                                            })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal>
        </>
    )
}

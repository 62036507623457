import React from "react";
import {PATHS} from "../paths";
import {Link} from "react-router-dom";
import {API} from "../api";
import {API_CALL} from "../types";
import {isSuccess} from "../common";
import {UserNameComponent} from "./UserNameComponent";
import {
    faClipboardList,
    faCogs,
    faDollarSign,
    faHome,
    faMoneyBill,
    faPoll,
    faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import {Nav, Navbar} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function HeaderComponent(props: any) {

    const logout = function () {
        API.getUserLogout(
            (api: API_CALL) => {
                if (isSuccess(api)) {
                    API.user = null;
                    window.location.href = "/login";
                }
            }
        )
    }

    console.log("HeaderComponent", API.user);

    return (
        <Navbar bg="white" expand="lg" className={"w-100"}>
            <Navbar.Brand>
                <Link to={PATHS.home + API.getAPITokenAsQuery()} className={"text-dark text-nowrap"}>
                    <img src='/imgs/logo.png' style={{height: "2em"}}/>
                    <span style={{fontFamily: "monospace", fontSize: "15px", fontStyle: "italic"}}> Иновативни решения от стъкло</span>
                </Link>
            </Navbar.Brand>
            {
                API.user &&
                <>
                    <Navbar.Toggle aria-controls="navbarScroll"/>
                    <Navbar.Collapse id="navbarScroll" className="justify-content-end">
                        <Nav
                            className="mr-0 my-2 my-lg-0"
                            navbarScroll
                        >
                            <Nav.Link href={PATHS.home + API.getAPITokenAsQuery()}><FontAwesomeIcon
                                icon={faHome}/> Начало</Nav.Link>
                            {
                                API.user.id === 66 ?
                                    <Nav.Link href={PATHS.salary + API.getAPITokenAsQuery()}><FontAwesomeIcon
                                        icon={faDollarSign}/> Заплати</Nav.Link>
                                    :
                                    <>
                                        <Nav.Link href={PATHS.input + API.getAPITokenAsQuery()}><FontAwesomeIcon
                                            icon={faClipboardList}/> Отчети</Nav.Link>
                                        {
                                            API.user.is_super_operator === 'Y' &&
                                            <Nav.Link href={PATHS.salary + API.getAPITokenAsQuery()}><FontAwesomeIcon
                                                icon={faDollarSign}/> Заплати</Nav.Link>
                                        }
                                        <Nav.Link href={PATHS.output + API.getAPITokenAsQuery()}><FontAwesomeIcon
                                            icon={faPoll}/> Справки</Nav.Link>
                                        {
                                            API.user.isNomenclature === 1 &&
                                            <Nav.Link href={PATHS.config + API.getAPITokenAsQuery()}><FontAwesomeIcon
                                                icon={faCogs}/> Номенклатури</Nav.Link>
                                        }
                                    </>
                            }
                            <Nav.Link href="/"><FontAwesomeIcon icon={faSignOutAlt} size="sm"/> Изход</Nav.Link>
                        </Nav>
                        <Navbar.Text>
                            {window.location.href.indexOf(PATHS.login) === -1 && <UserNameComponent/>}
                        </Navbar.Text>
                    </Navbar.Collapse>
                </>
            }
        </Navbar>
    )
}

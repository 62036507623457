import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {API_CALL, EMPTY_REPORT, Incident, Order, Report, Worker} from "../../types";
import {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    onlyPozitive,
    showErrorsInToast,
    textToFloat,
    translateError
} from "../../common";
import {API} from "../../api";
import {VXMachineParams} from "../config/machines/ConfigVXMachineForm";
import {InputMachineFormProps} from "./InputPage";
import moment from "moment";
import {WorkersListComponent} from "../config/common/WorkersListComponent";
import {OrdersListComponent} from "./common/OrdersListComponent";
import {IncidentsListComponent} from "./common/IncidentsListComponent";
import {UpdateConfigurationButton} from "./common/UpdateConfigurationButton";
import {DeleteReportButton} from "./common/DeleteReportButton";
import {MoveReportButton} from "./common/MoveReportButton";


export interface LaminationMachineReport {
    dblGlassSmallCnt: number | null;
    dblGlassBigCnt: number | null;
    dblGlassClrCnt: number | null;
    dblGlassHolesClrCnt: number | null;
    trplGlassSmallCnt: number | null;
    trplGlassBigCnt: number | null;
    trplGlassClrCnt: number | null;
    trplGlassHolesClrCnt: number | null;
    laminiraneCnt: number | null;
    clearingCnt: number | null;
    workHours: number;
}

export const EMPTY_LAMINATIONMACHINE_REPORT = {
    dblGlassSmallCnt: 0, dblGlassBigCnt: 0, dblGlassClrCnt: 0, dblGlassHolesClrCnt: 0,
    trplGlassSmallCnt: 0, trplGlassBigCnt: 0, trplGlassClrCnt: 0, trplGlassHolesClrCnt: 0,
    clearingCnt: 0, laminiraneCnt: 0, workHours: 8
}

export default function InputLaminationMachineForm({
                                                       viewMode,
                                                       report_date,
                                                       shift,
                                                       machine,
                                                       onReload
                                                   }: InputMachineFormProps) {
    const _dateString = moment(report_date).format('DD.MM.YYYY');
    const [working, setWorking] = useState(false);
    const [loading, setLoading] = useState(false);
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [reportData, setReportData] = useState<any>(EMPTY_LAMINATIONMACHINE_REPORT);
    const [report, setReport] = useState<Report>(EMPTY_REPORT);
    const [comments, setComments] = useState<string>('');

    useEffect(() => {
        if (!loading) {
            API.getReport(
                (apiCall: API_CALL) => {
                    setLoading(isLoading(apiCall));
                    if (isSuccess(apiCall)) {
                        const r = apiCall.data.data.report;
                        const rd = JSON.parse(r.request_data);
                        setWorkers(rd.workers || []);
                        setIncidents(rd.incidents || []);
                        setOrders(rd.orders || []);
                        setReportData(rd.data || EMPTY_LAMINATIONMACHINE_REPORT);
                        setReport(r);
                        setComments(rd.comments);
                    }
                    if (isError(apiCall)) {
                        setWorkers([]);
                        setIncidents([]);
                        setOrders([]);
                        setReportData(EMPTY_LAMINATIONMACHINE_REPORT);
                        setReport(EMPTY_REPORT);
                        setComments('');
                    }
                }, machine.id, shift.id, moment(report_date).format('YYYY-MM-DD')
            )
        }
    }, [])


    function saveReport() {
        const _r: any = {
            id: report.id,
            report_date: moment(report_date).format('YYYY-MM-DD'),
            machine: machine,
            shift: shift,
            man_cnt: workers.length,
            request_data: {
                data: reportData,
                workers: workers,
                orders: orders,
                incidents: incidents,
                comments: comments
            },
        };

        API.saveReport(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    onReload();
                }
            }, _r
        );
    }

    return machine.name !== 'Ламиниране' ? null :
        (
            <Modal show={true} size={"xl"} >
                <Modal.Header>
                    <Row className={"w-100"}>
                        <Col>
                            <Modal.Title>
                                <Row>
                                    <Col><span>Отчет за <strong
                                        className={"text-primary"}>{machine.name}</strong></span></Col>
                                    <Col className={"text-right"} xs={"auto"}>
                                <span className={"font-weight-light small"}><i><b><span
                                    className={"mr-3"}>{_dateString}</span><span>{shift.name}</span></b></i></span></Col>
                                </Row>
                            </Modal.Title>
                        </Col>
                        <Col xs={"auto"}><CloseButton onClick={onReload}/></Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body className={"max-h-60vh scrollable"}>
                            {
                                loading ? <Spinner animation="border"/> :
                                    <>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Form>
                                                            <h6 className={"text-primary font-weight-bold mb-0"}>Двойно
                                                                стъкло</h6>
                                                            <section className={"border p-2 pb-1 mt-0 mb-3 bg-light "}>
                                                                <Form.Group>
                                                                    <Form.Label className={"mb-0"}>Брой под 1.5 кв.м.
                                                                        (малка маса)</Form.Label>
                                                                    <Form.Control type="number"
                                                                                  value={reportData.dblGlassSmallCnt || ''}
                                                                                  readOnly={viewMode}
                                                                                  onChange={(e) => {
                                                                                      setReportData({
                                                                                          ...reportData,
                                                                                          dblGlassSmallCnt: onlyPozitive(textToFloat(e.target.value))
                                                                                      })
                                                                                  }}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Label className={"mb-0"}>Брой над 1.5 кв.м.
                                                                        (голяма маса)</Form.Label>
                                                                    <Form.Control type="number"
                                                                                  value={reportData.dblGlassBigCnt || ''}
                                                                                  readOnly={viewMode}
                                                                                  onChange={(e) => {
                                                                                      setReportData({
                                                                                          ...reportData,
                                                                                          dblGlassBigCnt: onlyPozitive(textToFloat(e.target.value))
                                                                                      })
                                                                                  }}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Label className={"mb-0"}>Брой
                                                                        почистване</Form.Label>
                                                                    <Form.Control type="number"
                                                                                  value={reportData.dblGlassClrCnt || ''}
                                                                                  readOnly={viewMode}
                                                                                  onChange={(e) => {
                                                                                      setReportData({
                                                                                          ...reportData,
                                                                                          dblGlassClrCnt: onlyPozitive(textToFloat(e.target.value))
                                                                                      })
                                                                                  }}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Label className={"mb-0"}>Брой
                                                                        почистване <strong>с
                                                                            отвори</strong></Form.Label>
                                                                    <Form.Control type="number"
                                                                                  value={reportData.dblGlassHolesClrCnt || ''}
                                                                                  readOnly={viewMode}
                                                                                  onChange={(e) => {
                                                                                      setReportData({
                                                                                          ...reportData,
                                                                                          dblGlassHolesClrCnt: onlyPozitive(textToFloat(e.target.value))
                                                                                      })
                                                                                  }}
                                                                    />
                                                                </Form.Group>
                                                            </section>

                                                            <h6 className={"text-primary font-weight-bold mb-0"}>Тройно
                                                                стъкло</h6>
                                                            <section className={"border p-2 pb-1 mt-0 mb-3 bg-light "}>
                                                                <Form.Group>
                                                                    <Form.Label className={"mb-0"}>Брой под 1.5
                                                                        кв.м.</Form.Label>
                                                                    <Form.Control type="number"
                                                                                  value={reportData.trplGlassSmallCnt || ''}
                                                                                  readOnly={viewMode}
                                                                                  onChange={(e) => {
                                                                                      setReportData({
                                                                                          ...reportData,
                                                                                          trplGlassSmallCnt: onlyPozitive(textToFloat(e.target.value))
                                                                                      })
                                                                                  }}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Label className={"mb-0"}>Брой над 1.5
                                                                        кв.м.</Form.Label>
                                                                    <Form.Control type="number"
                                                                                  value={reportData.trplGlassBigCnt || ''}
                                                                                  readOnly={viewMode}
                                                                                  onChange={(e) => {
                                                                                      setReportData({
                                                                                          ...reportData,
                                                                                          trplGlassBigCnt: onlyPozitive(textToFloat(e.target.value))
                                                                                      })
                                                                                  }}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Label className={"mb-0"}>Брой
                                                                        почистване</Form.Label>
                                                                    <Form.Control type="number"
                                                                                  value={reportData.trplGlassClrCnt || ''}
                                                                                  readOnly={viewMode}
                                                                                  onChange={(e) => {
                                                                                      setReportData({
                                                                                          ...reportData,
                                                                                          trplGlassClrCnt: onlyPozitive(textToFloat(e.target.value))
                                                                                      })
                                                                                  }}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Label className={"mb-0"}>Брой
                                                                        почистване <strong>с
                                                                            отвори</strong></Form.Label>
                                                                    <Form.Control type="number"
                                                                                  value={reportData.trplGlassHolesClrCnt || ''}
                                                                                  readOnly={viewMode}
                                                                                  onChange={(e) => {
                                                                                      setReportData({
                                                                                          ...reportData,
                                                                                          trplGlassHolesClrCnt: onlyPozitive(textToFloat(e.target.value))
                                                                                      })
                                                                                  }}
                                                                    />
                                                                </Form.Group>
                                                            </section>

                                                        </Form>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <Form>
                                                                    <h6 className={"text-primary font-weight-bold mb-0"}>Други</h6>
                                                                    <section
                                                                        className={"border p-2 pb-1 mt-0 mb-3 bg-light "}>
                                                                        <Form.Group>
                                                                            <Form.Label className={"mb-0"}>Ламиниране
                                                                                кв.м.</Form.Label>
                                                                            <Form.Control type="number"
                                                                                          value={reportData.laminiraneCnt || ''}
                                                                                          readOnly={viewMode}
                                                                                          onChange={(e) => {
                                                                                              setReportData({
                                                                                                  ...reportData,
                                                                                                  laminiraneCnt: onlyPozitive(textToFloat(e.target.value))
                                                                                              })
                                                                                          }}
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group>
                                                                            <Form.Label className={"mb-0"}>Почистване
                                                                                кв.м.</Form.Label>
                                                                            <Form.Control type="number"
                                                                                          value={reportData.clearingCnt || ''}
                                                                                          readOnly={viewMode}
                                                                                          onChange={(e) => {
                                                                                              setReportData({
                                                                                                  ...reportData,
                                                                                                  clearingCnt: onlyPozitive(textToFloat(e.target.value))
                                                                                              })
                                                                                          }}
                                                                            />
                                                                        </Form.Group>
                                                                    </section>
                                                                </Form>
                                                            </Col>


                                                            <Col sm={12}>
                                                                <WorkersListComponent workers={workers} defWorkHours={8}
                                                                                      forDate={moment(report_date).format('YYYY-MM-DD')}
                                                                                      showWorkHours={true}
                                                                                      viewMode={viewMode}
                                                                                      onChange={(workers) => setWorkers(workers)}/>
                                                            </Col>
                                                            <Col sm={12}>
                                                                <OrdersListComponent dim={'кв.м.'} orders={orders}
                                                                                     viewMode={viewMode}
                                                                                     onChange={(orders => setOrders(orders))}/>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <IncidentsListComponent incidents={incidents} machine={machine}
                                                                        viewMode={viewMode}
                                                                        onChange={(incidents) => setIncidents(incidents)}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className={"mt-3"}>
                                                <Form.Group className={" bg-secondary-light p-2"}>
                                                    <Form.Label
                                                        className={"text-primary font-weight-bold text-center w-100"}>Коментари
                                                        и забележки</Form.Label>
                                                    <Form.Control as={"textarea"}
                                                                  value={comments}
                                                                  readOnly={viewMode}
                                                                  onChange={(e) => setComments(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                            }
                        </Card.Body>
                        <Card.Footer>
                            {
                                !viewMode && (
                                    working ? <Spinner animation="border"/> :
                                        <>
                                            <Button onClick={() => saveReport()}><FontAwesomeIcon
                                                icon={faSave}/> Запази</Button>

                                            <span className={"float-right"}>
                                                <UpdateConfigurationButton reportId={report.id}/>
                                                <DeleteReportButton reportId={report.id} onDelete={onReload} />
                                                <MoveReportButton reportId={report.id} onMoved={onReload} />
                                            </span>
                                        </>
                                )
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>
        )
}

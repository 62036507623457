import ModalHeader from "react-bootstrap/ModalHeader";
import {
    Button,
    CloseButton,
    Col,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalTitle,
    Row,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import {
    ARRAY_MONTHS_FULL,
    zeroToEmptyString,
    zeroToEmptyStringAsCurrencyWithCommas,
    zeroToEmptyStringAsNumber
} from "../../common";
import React, {useEffect, useState} from "react";
import {SalaryConfig, SalaryConfigItem} from "../input/SalaryPage";
import ExportButton from "../../common/ExportButton";

interface AccountData {
    workHoursCorr: number|null;
    extraHoursCorr: number|null;
}
const EMPTY_ACCOUNT_DATA = {workHoursCorr: 0, extraHoursCorr: 0} as AccountData;

export default function SalaryReportModal({
                                              salary,
                                              onSetConfigValue,
                                              onSave,
                                              onHide,
                                              onSetPeriod,
                                          }: SalaryReportModalProps) {


    const [activeTab, setActiveTab] = useState<string>("Всички");
    const [departments, setDepartments] = useState<Array<string>>(['Всички']);
    const [totals, setTotals] = useState<Map<string, number>>(new Map<string, number>());


    function TableHeader({row}: any) {

        return (
            <thead>
            <tr>
                <th>Служител</th>
                <th>Длъжност</th>
                <th className={"text-center"}>%<br/>произв.</th>
                <th className={"text-center"}>Отр.<br/>часове</th>
                <th className={"text-center"}>Корекция</th>
                <th className={"text-center"}>Изв.<br/>труд</th>
                <th className={"text-center"}>Корекция</th>
                <th className={"text-center"}>Ефект.<br/>ЧЧ</th>
                <th className={"text-center"}>Коеф.<br/>изв.труд</th>
                <th className={"text-center"}>Общо<br/>еф.ЧЧ</th>
                <th className={"text-center"}>Ставка<br/>еф.часове</th>
                <th className={"text-center"}>Кор.с-ка<br/>еф.ч.</th>
                <th className={"text-center"}>Сума</th>
            </tr>
            </thead>
        )
    }


    function EmployeeSalaryData(props: { exportMode: boolean, userId: number, department: string, data: AccountData, onChangeFormData: (newData: AccountData) => any }) {
        const [whCorr, setWHCorr] = useState<string>(props.data.workHoursCorr?.toString() || '');
        const [ehCorr, setEHCorr] = useState<string>(props.data.extraHoursCorr?.toString() || '');
        const data = salary.data[props.userId];
        const exportMode = props.exportMode;

        const updateConfig = () => {
            let _wh: number|null = parseFloat(whCorr); if(isNaN(_wh)) _wh = null;
            let _eh: number|null = parseFloat(ehCorr); if(isNaN(_eh)) _eh = null;

            props.onChangeFormData({workHoursCorr: _wh, extraHoursCorr: _eh})
        }

        return data ?
            (
                <>
                    <td className={"text-center"}>{zeroToEmptyStringAsNumber(data.performance.toString())}</td>
                    <td className={"text-right"}>{zeroToEmptyString(data.$workHours)}</td>
                    <td className={"w-100px"}>
                        {
                            exportMode ? whCorr :
                                <Form.Control size={"sm"}
                                              value={whCorr}
                                              onChange={(e) => setWHCorr(e.target.value)}
                                              onBlur={updateConfig}
                                              className={"w-100px text-right"}/>
                        }
                    </td>
                    <td className={"text-right"}>{zeroToEmptyStringAsNumber(data.$extraHours)}</td>
                    <td className={"w-100px"}>
                        {
                            exportMode ? ehCorr :
                                <Form.Control size={"sm"}
                                              value={ehCorr}
                                              onChange={(e) => setEHCorr(e.target.value)}
                                              onBlur={updateConfig}
                                              className={"w-100px text-right"}/>
                        }
                    </td>
                    <td className={"text-right"}>{zeroToEmptyStringAsNumber(data.efectiveMH)}</td>
                    <td className={"text-right"}>{zeroToEmptyStringAsNumber(data.extraWorkMH)}</td>
                    <td className={"text-right"}>{zeroToEmptyStringAsNumber(data.totalMH)}</td>
                    <td className={"text-right"}>{zeroToEmptyStringAsNumber(data.effectiveHoursRate)}</td>
                    <td className={"text-right"}>{zeroToEmptyStringAsNumber(data.fixedEffectiveHoursRate)}</td>
                    <td className={"text-right text-light font-weight-bold bg-secondary"}>{zeroToEmptyStringAsCurrencyWithCommas(data?.sallary?.toString())}</td>
                </>
            ) : (
                exportMode ? <>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td></>
                    : <td colSpan={13}/>
            );
    }

    useEffect(() => {
        if (config) {
            const dep = Array<string>();

            salary.config.forEach(
                (c: SalaryConfigItem) => {
                    if (dep.indexOf(c.employee.department) === -1) dep.push(c.employee.department);
                }
            )

            dep.sort();

            setDepartments(dep);
        }

        config.sort(
            (c1, c2) => {
                if (c1.employee.department < c2.employee.department) return -1;
                if (c1.employee.department > c2.employee.department) return 1;

                if (c1.employee.position?.toLowerCase().indexOf('началник') > -1) return -1;
                if (c2.employee.position?.toLowerCase().indexOf('началник') > -1) return 1;

                if (c1.employee.name < c2.employee.name) return -1;
                if (c1.employee.name > c2.employee.name) return 1;
                return 0;
            }
        )

        const datas = salary.data;
        const _totals = new Map<string, number>();
        const datasKeys = Object.keys(datas);

        if (datas) {
            datasKeys.forEach(
                (userId: any) => {
                    const data = datas[userId];
                    if (data) {
                        let dep = null;
                        if (config) {
                            salary.config.forEach(
                                (c: SalaryConfigItem) => {
                                    if (c.employee.id == userId) {
                                        dep = c.employee.department;
                                    }
                                }
                            )

                            if (dep) {
                                let total = _totals.get('total') || 0;
                                total += data.sallary;
                                _totals.set('total', total);

                                let depTotal = _totals.get(dep) || 0;
                                depTotal += data.sallary;
                                _totals.set(dep, depTotal);
                            }
                        }
                    }
                }
            )
        }

        setTotals(_totals);

    }, [salary])


    let currentDep = "";

    const printDepartment = function (uDep: string) {
        if (currentDep !== uDep) {
            currentDep = uDep;

            return <>
                <tr>
                    <td colSpan={13}>&nbsp;</td>
                </tr>
                <tr className={"text-white bg-secondary font-weight-bold"}>
                    <td colSpan={12}>{uDep}</td>
                    <td className={"text-right"}>{zeroToEmptyStringAsCurrencyWithCommas((totals.get(uDep) || '0').toString())}</td>
                </tr>
            </>
        }

        return null;
    }

    const selectPrevMonth = () => {
        let m = salary.month;
        let y = salary.year;

        m -= 1;
        if (m < 0) {
            m = 11;
            y -= 1;
        }

        onSetPeriod(m, y);
    }

    const selectNextMonth = () => {
        let m = salary.month;
        let y = salary.year;

        m += 1;
        if (m > 11) {
            m = 0;
            y += 1;
        }

        onSetPeriod(m, y);
    }

    const config = salary?.config || [];

    return (
        <>
            <Modal onHide={() => onHide()} show={true} dialogClassName={"p-5 max-w-none w-100vw"}>
                <ModalHeader>
                    <Row className={"w-100"}>
                        <Col xs={"auto"}>
                            <ModalTitle>Заплати за </ModalTitle>
                        </Col>
                        <Col xs={"auto"}><Button onClick={selectPrevMonth}>&#8882;</Button></Col>
                        <Col xs={"auto"} className={"p-2 bg-primary text-light font-weight-bold"}>
                            {ARRAY_MONTHS_FULL[salary.month].toUpperCase()},&nbsp;&nbsp;{salary.year}
                        </Col>
                        <Col xs={"auto"}><Button onClick={selectNextMonth}>&#8883;</Button></Col>
                        <Col>
                            {/*<Button variant={"secondary"}>Авт.попълване</Button>*/}
                            <ExportButton  table={"dataTable"} filename={"report-salary-" +
                                (salary.month+1).toString().padStart(2, '0') + '-' + salary.year.toString()} />
                        </Col>
                        <Col xs={"auto"}>
                            <CloseButton onClick={() => onHide()}/>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody style={{maxHeight: '70vh', overflow: 'auto'}}>
                    {
                        config?.length > 0 &&
                        <div className={"table-responsive"} style={{maxHeight: '90%h'}}>

                            <Tabs activeKey={activeTab} onSelect={(e) => setActiveTab(e || "Всички")}>
                                <Tab title={"Всички"} eventKey={"Всички"}/>
                                {departments.map((k: string) => <Tab key={k} title={k} eventKey={k}/>)}
                            </Tabs>

                            <div className="w-100 overflow-auto" style={{maxHeight: '60vh'}}>
                                <Table striped bordered hover size={"sm"} className={"small"} style={{height: '90%'}}
                                       id="viewTable">
                                    <TableHeader/>
                                    <tbody>
                                    <tr className={"text-light font-weight-bold bg-secondary"}>
                                        <td colSpan={12}>ВСИЧКО</td>
                                        <td className={"text-right"}>{zeroToEmptyStringAsCurrencyWithCommas((totals.get('total') || '0.00').toString())}</td>
                                    </tr>
                                    {
                                        config
                                            .filter(
                                                (u: SalaryConfigItem) =>
                                                    ((activeTab === "Всички") ||
                                                        (u.employee.department == activeTab))
                                            )
                                            .map(
                                                (u: SalaryConfigItem) => {
                                                    return <>
                                                        {
                                                            printDepartment(u.employee.department)
                                                        }
                                                        <tr key={u.employee.id}>
                                                            <td>{u.employee.name}</td>
                                                            <td>{u.employee.position}</td>
                                                            <EmployeeSalaryData userId={u.employee.id}
                                                                                exportMode={false}
                                                                                department={u.employee.department}
                                                                                data={{workHoursCorr: u.workHoursCorr, extraHoursCorr: u.extraHoursCorr} as AccountData}
                                                                                onChangeFormData={nd => {
                                                                                    u.extraHoursCorr = nd.extraHoursCorr;
                                                                                    u.workHoursCorr = nd.workHoursCorr;
                                                                                    if(onSetConfigValue) onSetConfigValue(u);
                                                                                }}
                                                            />
                                                        </tr>
                                                    </>
                                                }
                                            )
                                    }
                                    </tbody>
                                </Table>


                                <Table striped bordered hover size={"sm"} className={"small d-none"} style={{height: '90%'}}
                                       id="dataTable">
                                    <TableHeader/>
                                    <tbody>
                                    {
                                        [...config]
                                            .sort(
                                                (u1: SalaryConfigItem, u2: SalaryConfigItem) =>
                                                    (u1.employee.department?.localeCompare(u2.employee.department) ||
                                                        u1.employee.name?.localeCompare(u2.employee.name)
                                                    )
                                            )
                                            .map(
                                                (u: SalaryConfigItem) => {
                                                    return <>
                                                        <tr key={u.employee.id}>
                                                            <td>{u.employee.name}</td>
                                                            <td>{u.employee.position}</td>
                                                            <EmployeeSalaryData userId={u.employee.id}
                                                                                exportMode={true}
                                                                                department={u.employee.department}
                                                                                data={{workHoursCorr: u.workHoursCorr, extraHoursCorr: u.extraHoursCorr} as AccountData}
                                                                                onChangeFormData={nd => {
                                                                                    u.extraHoursCorr = nd.extraHoursCorr;
                                                                                    u.workHoursCorr = nd.workHoursCorr;
                                                                                    if(onSetConfigValue) onSetConfigValue(u);
                                                                                }}
                                                            />
                                                        </tr>
                                                    </>
                                                }
                                            )
                                    }
                                    </tbody>
                                </Table>
                            </div>

                        </div>
                    }
                    {
                        (salary.config.length === 0) &&
                        <div><strong>Няма данни</strong></div>
                    }

                </ModalBody>
                <ModalFooter className={"justify-content-center"}>
                    <Row className={"w-100"}>
                        <Col className={"text-right"}>
                            {
                                onSave &&
                                <>
                                    <Button onClick={onSave}>Запази</Button>&nbsp;
                                </>
                            }
                            <Button variant={"secondary"} onClick={() => onHide()}>Затвори</Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    )
}


export interface SalaryReportModalProps {
    salary: SalaryConfig;
    onSetPeriod: (newMonth: number, newYear: number) => any;
    onSetConfigValue?: (newValue: SalaryConfigItem) => any;
    onSave?: () => any;
    onHide: () => any;
}

import {Button, Col, Form, Row} from "react-bootstrap";
import {ARRAY_MONTHS_FULL} from "../common";
import React from "react";

export interface SelectMonthYearProps {
    month: number,
    year: number,
    onChange: (month: number, year: number) => any
}

export function SelectMonthYear({month, year, onChange}: SelectMonthYearProps) {

    const selectPrevMonth = () => {
        let m = month;
        let y = year;

        m -= 1;
        if (m < 0) {
            m = 11;
            y -= 1;
        }

        onChange(m, y);
    }

    const selectNextMonth = () => {
        let m = month;
        let y = year;

        m += 1;
        if (m > 11) {
            m = 0;
            y += 1;
        }

        onChange(m, y);
    }

    return (
        <Form.Group>
            <Row className={"w-300px"}>
                <Col xs={"auto"}><Button onClick={selectPrevMonth}>&#8882;</Button></Col>
                <Col
                    className={"p-2 bg-primary text-light font-weight-bold w-180px text-center"}>
                    {ARRAY_MONTHS_FULL[month].toUpperCase()},&nbsp;&nbsp;{year}
                </Col>
                <Col xs={"auto"}><Button onClick={selectNextMonth}>&#8883;</Button></Col>

            </Row>
        </Form.Group>
    )
}
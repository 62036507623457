import {Account, Order, Team, Worker} from "../../../types";
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalTitle, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBox, faMinus, faPlus, faUser, faUserMinus, faUserPlus, faUsers} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {onlyPozitive, textToInt} from "../../../common";

export interface OrdersListComponentProps {
    viewMode?: boolean;
    className?: any;
    dim: string;
    orders: Order[];
    onChange: (orders: Order[]) => any;
}

export function OrdersListComponent({viewMode, className, dim, orders, onChange}: OrdersListComponentProps) {

    const [showNewOrder, setShowNewOrder] = useState(false);
    const [order, setOrder] = useState<Order|null>(null);

    function addOrder(order: Order|null) {
        if(!order) return;

        if (orders.findIndex(
            value => value.id === order.id
        ) === -1) orders.push(order);

        setShowNewOrder(false);
        setOrder(null);
        onChange(orders);
    }

    function removeOrder(order: Order) {
        onChange(orders.filter(u => u.id !== order.id));
    }


    return (
        <>
            <Form.Group className={className + " bg-secondary-light p-2"}>
                <Form.Label className={"text-primary font-weight-bold text-center w-100"}>Поръчки</Form.Label>
                <Table size={"sm"} className={"mb-1"}>
                    <tbody>
                    {
                        orders.length === 0 && <tr>
                            <td colSpan={2}>
                                <div><strong>Няма поръчки</strong></div>
                            </td>
                        </tr>
                    }
                    {
                        orders.map(
                            u =>
                                <tr key={u.id}>
                                    <td>
                                        <FontAwesomeIcon icon={faBox}/>
                                    </td>
                                    <td>
                                        No: {u.id}
                                    </td>
                                    <td>
                                        {u.value} {u.dim}
                                    </td>
                                    {
                                        !viewMode && <td className={"text-right"}>
                                            <Button type={"button"} size={"sm"} onClick={() => removeOrder(u)}>
                                                <FontAwesomeIcon icon={faMinus}/>
                                            </Button>
                                        </td>
                                    }
                                </tr>
                        )
                    }

                    {
                        !viewMode && <tr>
                            <td className={"text-right"} colSpan={4}>
                                <Button className={"w-100"} type={"button"} size={"sm"}
                                        onClick={() => setShowNewOrder(true)}><FontAwesomeIcon
                                    icon={faPlus}/> Добави поръчка</Button>
                            </td>
                        </tr>
                    }
                    </tbody>
                </Table>
            </Form.Group>

            <Modal centered size="sm" show={showNewOrder}>
                <Modal.Header>
                    <ModalTitle>Добави поръчка</ModalTitle>
                </Modal.Header>
                <ModalBody>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Номер на поръчка</Form.Label>
                            <Form.Control type="number" value={order?.id || ''}
                                          onChange={(e) => {
                                              setOrder({
                                                  id: e.target.value || '',
                                                  value: order?.value || 0,
                                                  dim: order?.dim || dim
                                              })
                                          }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Изпълнени {dim} по поръчката</Form.Label>
                            <Form.Control type="number" value={order?.value || ''}
                                          onChange={(e) => {
                                              setOrder({
                                                  id: order?.id || '',
                                                  value: onlyPozitive(textToInt(e.target.value)) || 0,
                                                  dim: order?.dim || dim
                                              })
                                          }}
                            />
                        </Form.Group>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button type={"button"} onClick={() => addOrder(order)}>Запази</Button>&nbsp;
                    <Button type={"button"} onClick={() => setShowNewOrder(false)}>Откажи</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
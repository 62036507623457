import ModalHeader from "react-bootstrap/ModalHeader";
import {
    Button,
    CloseButton,
    Col, Dropdown,
    Modal,
    ModalBody,
    ModalFooter,
    ModalTitle,
    Row, Spinner,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import {ReadOnlyDatePickerComponent} from "../../common/ReadOnlyDatePickerComponent";
import {
    dateToString, getCutMachineName,
    getShiftName,
    isLoading,
    isSuccess,
    zeroToEmptyString,
    zeroToEmptyStringAsNumber
} from "../../common";
import React, {useEffect, useState} from "react";
import {faCalculator, faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../../api";
import {API_CALL} from "../../types";
import moment from "moment";
import InputCutMachineForm from "../input/InputCutMachineForm";
import InputVXMachineForm from "../input/InputVXMachineForm";
import InputVEMachineForm from "../input/InputVEMachineForm";
import InputPackageMachineForm from "../input/InputPackageMachineForm";
import InputLaminationMachineForm from "../input/InputLaminationMachineForm";
import InputProfileMachineForm from "../input/InputProfileMachineForm";
import InputOvenMachineForm from "../input/InputOvenMachineForm";
import InputVertecMachineForm from "../input/InputVertecMachineForm";
import InputOtherActivitiesForm from "../input/InputOtherActivitiesForm";
import ExportButton from "../../common/ExportButton";


export interface OutputDetailedModalProps {
    onHide: () => any;
}


export default function OutputDetailedModal({onHide}: OutputDetailedModalProps) {

    const [reportDate, setReportDate] = useState<Date>(new Date());
    const [mode, setMode] = useState<string | null>("mode1");
    const [loading, setLoading] = useState(false);
    const [loadingReports, setLoadingReports] = useState(false);
    const [report, setReport] = useState<any>(null);
    const [userReports, setUserReports] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<string>("cut");
    const [showReports, setShowReports] = useState<{ id: number, date: string } | null>(null);


    function tableHeaderCell(k: string) {
        if (mode === "mode2") {
            const w = parseInt(k);
            if (isNaN(w)) return k;

            return moment(reportDate).startOf('year').add(parseInt(k), 'weeks').startOf('week').add(1, 'day').format('DD.MM') + ' - ' +
                moment(reportDate).startOf('year').add(parseInt(k), 'weeks').endOf('week').add(1, 'day').format('DD.MM.YYYY');
        } else return k;
    }

    function TableHeader({row}: any) {
        return (
            <thead>
            <tr>
                <th rowSpan={2}>Вид машина</th>
                <th>Вид стъкло</th>
                <th colSpan={2} className={"text-center bg-info text-light font-weight-bold"}>За периода</th>
                {
                    extractDataKeys(row).filter(k => k !== 'За периода').map(
                        (k, idx) => <th key={k}
                                        className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}
                                        colSpan={2}>{tableHeaderCell(k)}</th>
                    )
                }
            </tr>
            <tr>
                <th>
                    {
                        activeTab === 'cut' ? 'Кв.м. рязане и брой форми' : 'Кв.м. зареждане и  брой цикли (нормален + ускорен режим)'
                    }
                </th>
                <th className={"text-center bg-info text-light font-weight-bold "}>кв.м.</th>
                <th className={"text-center bg-info text-light font-weight-bold "}>бр.</th>
                {
                    extractDataKeys(row).filter(k => k !== 'За периода').map(
                        (k, idx) =>
                            <>
                                <th className={"text-center " + (idx % 2 === 0 ? "bg-secondary-light" : "")}>кв.м.</th>
                                <th className={"text-center " + (idx % 2 === 0 ? "bg-secondary-light" : "")}>бр.</th>
                            </>
                    )
                }
            </tr>
            </thead>
        )
    }

    const reload = function () {
        if (loading) return;
        API.getDetailed(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    setReport(apiCall.data.data);
                }
            }, mode || 'mode1', moment(reportDate).format('YYYY-MM-DD')
        )
    }

    useEffect(() => {
        if (!showReports) return;
        if (loadingReports) return;

        setUserReports(null);

        API.getUserReports(
            (apiCall: API_CALL) => {
                setLoadingReports(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    setUserReports(apiCall.data.data.report[0]);
                }
            }, showReports.id, showReports.date
        )
    }, [showReports]);

    const recalc = function () {
        if (loading) return;
        API.recalculatePerformance(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    setReport(apiCall.data.data);
                }
            }, mode || 'mode1', moment(reportDate).format('YYYY-MM-DD')
        )
    }

    useEffect(() => {
        reload()
    }, [reportDate, mode]);


    const extractDataKeys = function (data: any): string[] {
        if (mode === 'mode1')
            return Object.keys(report.total[activeTab])
                .sort((a, b) =>
                    moment(a) > moment(b) ? 1 : (moment(a) < moment(b) ? -1 : 0))

        return Object.keys(report.total[activeTab])
            .sort((a, b) => a > b ? 1 : (a < b ? -1 : 0))
    }

    function showReportsFor(id: number, date: string) {
        setShowReports({id: id, date: date});
    }

    function isGlassVisible(u: any) {
        return activeTab == ((u.oven_type == 'BIG' || u.oven_type == 'SMALL') ? 'hardening' : 'cut');
    }

    return (
        <>
            <Modal onHide={() => onHide()} show={true} dialogClassName={"w-100 p-3 max-w-none"}>
                <ModalHeader>
                    <Row className={"w-100"}>
                        <Col>
                            <ModalTitle>Подробна справка за рязане и закаляване</ModalTitle>
                        </Col>
                        <Col className={"justify-content-end"} xs={"auto"}>
                            <Row className={"flex-nowrap"}>
                                <Col>
                                    <ReadOnlyDatePickerComponent onChange={(date: Date) => {
                                        setReportDate(date)
                                    }} value={dateToString(reportDate)}/>
                                </Col>
                                <Col>
                                    <Button onClick={() => reload()} variant={"secondary"} size="sm" className={"mr-1"}
                                            title={"Презареди таблицата"}><FontAwesomeIcon
                                        icon={faSyncAlt}/></Button>
                                    <Button onClick={() => recalc()} variant={"secondary"} size="sm" className={"mr-1"}
                                            title={"Преизчисли производителноста за периода"}><FontAwesomeIcon
                                        icon={faCalculator}/></Button>

                                    <ExportButton  table={"dataTable"} filename={"report-detailed-" +
                                        (mode === "mode1" ? 'dnevna' : (mode === "mode2" ? 'sedmichna' : 'mesechna')) +
                                        "-" + moment(reportDate).format('DD.MM.YYYY')} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={"auto"}>
                            <CloseButton onClick={() => onHide()}/>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody style={{maxHeight: '70vh', overflow: 'auto'}}>
                    {
                        loading && <Spinner animation={"border"}/>
                    }
                    {
                        report && Object.keys(report.performance).length > 0 && !loading &&
                        <div className={"table-responsive"} style={{maxHeight: '65vh'}}>

                            <Tabs activeKey={activeTab} onSelect={(e) => setActiveTab(e || "Всички отдели")}>
                                <Tab title={"Рязане"} eventKey={"cut"}/>
                                <Tab title={"Закаляване"} eventKey={"hardening"}/>
                            </Tabs>

                            <div className="w-100 overflow-auto" style={{maxHeight: '60vh'}}>
                                <Table striped bordered hover size={"sm"} className={"small"} style={{height: '100%'}}
                                       id="dataTable">
                                    <TableHeader row={report.performance[report.glasses[0].id]}/>
                                    <tbody>
                                    <tr className={'bg-info text-light fw-bold'}>
                                        <td>Всичко</td>
                                        <td className={"text-right font-weight-bold bg-info text-light "}>
                                            {zeroToEmptyStringAsNumber(report.total[activeTab]['За периода'].m2)}</td>
                                        <td className={"text-right font-weight-bold bg-info text-light"}>
                                            {zeroToEmptyString(report.total[activeTab]['За периода'].cnt)}</td>
                                        {
                                            extractDataKeys(report.total[activeTab]).filter(k => k !== 'За периода').map(
                                                (k: any, idx: number) => {
                                                    return (
                                                        <>
                                                            <td key={'p1_' + k}
                                                                className={"text-right " + (idx % 2 === 0 ? "bg-secondary-light" : "")}>
                                                                {zeroToEmptyStringAsNumber(report.total[activeTab][k].m2)}</td>
                                                            <td key={'i1_' + k}
                                                                className={"text-right " + (idx % 2 === 0 ? "bg-secondary-light" : "")}>
                                                                {zeroToEmptyString(report.total[activeTab][k].cnt)}</td>
                                                        </>
                                                    )
                                                }
                                            )
                                        }
                                    </tr>
                                    {
                                        report.glasses
                                            .filter((u: any) => isGlassVisible(u))
                                            .map(
                                                (g: any) => {
                                                    return <>
                                                        <tr key={g.id}>
                                                            <td>{
                                                                getCutMachineName(g.oven_type)
                                                            }</td>
                                                            <td>{g.name}</td>
                                                            <td className={"text-right font-weight-bold bg-info text-light"}>
                                                                {zeroToEmptyStringAsNumber(report.performance[g.id]['За периода'].m2)}</td>
                                                            <td className={"text-right font-weight-bold bg-info text-light"}>
                                                                {zeroToEmptyString(report.performance[g.id]['За периода'].cnt)}</td>
                                                            {
                                                                extractDataKeys(report.performance[g.id])
                                                                .filter(k => k !== 'За периода')
                                                                    .map(
                                                                    (k: any, idx: number) => {
                                                                        return (
                                                                            <>
                                                                                {
                                                                                    <td key={'p_' + k}
                                                                                        className={"text-right " + (idx % 2 === 0 ? "bg-secondary-light" : "")}>
                                                                                        {zeroToEmptyStringAsNumber(report.performance[g.id][k].m2)}</td>
                                                                                }
                                                                                <td key={'i_' + k}
                                                                                    className={"text-right " + (idx % 2 === 0 ? "bg-secondary-light" : "")}>
                                                                                    {zeroToEmptyString(report.performance[g.id][k].cnt)}</td>
                                                                            </>
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </tr>
                                                    </>
                                                }
                                            )
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    }
                    {
                        !loading && !report &&
                        <div><strong>Няма данни</strong></div>
                    }

                </ModalBody>
                <ModalFooter className={"justify-content-center"}>
                    <Tabs variant={"pills"} onSelect={eventKey => setMode(eventKey)}
                          defaultActiveKey={mode ? mode : undefined}>
                        <Tab title={"Дневно / седмична"} eventKey={"mode1"}/>
                        <Tab title={"Седмично / месечна"} eventKey={"mode2"}/>
                        <Tab title={"Месечно / годишен"} eventKey={"mode3"}/>
                    </Tabs>
                </ModalFooter>
            </Modal>


            {
                showReports && userReports &&
                <>
                    {
                        userReports[0].machine_id === 1 &&
                        <InputCutMachineForm machine={{id: 1, name: 'Рязане',  params: null}}
                                             shift={{
                                                 id: userReports[0].shift_id,
                                                 name: getShiftName(userReports[0].shift_id)
                                             }}
                                             report_date={userReports[0].report_date}
                                             viewMode={true}
                                             onReload={() => {
                                                 setShowReports(null)
                                             }}/>


                    }
                    {
                        userReports[0].machine_id === 2 &&
                        <InputVXMachineForm machine={{id: 2, name: 'VX', params: null}}
                                            shift={{
                                                id: userReports[0].shift_id,
                                                name: getShiftName(userReports[0].shift_id)
                                            }}
                                            report_date={userReports[0].report_date}
                                            viewMode={true}
                                            onReload={() => {
                                                setShowReports(null)
                                            }}/>


                    }
                    {
                        userReports[0].machine_id === 3 &&
                        <InputProfileMachineForm machine={{id: 3, name: 'Profile', params: null}}
                                                 shift={{
                                                     id: userReports[0].shift_id,
                                                     name: getShiftName(userReports[0].shift_id)
                                                 }}
                                                 report_date={userReports[0].report_date}
                                                 viewMode={true}
                                                 onReload={() => {
                                                     setShowReports(null)
                                                 }}/>


                    }
                    {
                        userReports[0].machine_id === 4 &&
                        <InputVEMachineForm machine={{id: 4, name: 'VE', params: null}}
                                            shift={{
                                                id: userReports[0].shift_id,
                                                name: getShiftName(userReports[0].shift_id)
                                            }}
                                            report_date={userReports[0].report_date}
                                            viewMode={true}
                                            onReload={() => {
                                                setShowReports(null)
                                            }}/>


                    }
                    {
                        userReports[0].machine_id === 5 &&
                        <InputPackageMachineForm machine={{id: 5, name: 'Стъклопакет', params: null}}
                                                 shift={{
                                                     id: userReports[0].shift_id,
                                                     name: getShiftName(userReports[0].shift_id)
                                                 }}
                                                 report_date={userReports[0].report_date}
                                                 viewMode={true}
                                                 onReload={() => {
                                                     setShowReports(null)
                                                 }}/>


                    }
                    {
                        userReports[0].machine_id === 6 &&
                        <InputOvenMachineForm machine={{id: 6, name: 'Голяма печка', params: null}}
                                              shift={{
                                                  id: userReports[0].shift_id,
                                                  name: getShiftName(userReports[0].shift_id)
                                              }}
                                              report_date={userReports[0].report_date}
                                              viewMode={true}
                                              onReload={() => {
                                                  setShowReports(null)
                                              }}/>


                    }
                    {
                        userReports[0].machine_id === 7 &&
                        <InputOvenMachineForm machine={{id: 7, name: 'Малка печка', params: null}}
                                              shift={{
                                                  id: userReports[0].shift_id,
                                                  name: getShiftName(userReports[0].shift_id)
                                              }}
                                              report_date={userReports[0].report_date}
                                              viewMode={true}
                                              onReload={() => {
                                                  setShowReports(null)
                                              }}/>


                    }
                    {
                        userReports[0].machine_id === 8 &&
                        <InputLaminationMachineForm machine={{id: 8, name: 'Ламиниране', params: null}}
                                                    shift={{
                                                        id: userReports[0].shift_id,
                                                        name: getShiftName(userReports[0].shift_id)
                                                    }}
                                                    report_date={userReports[0].report_date}
                                                    viewMode={true}
                                                    onReload={() => {
                                                        setShowReports(null)
                                                    }}/>


                    }
                    {
                        userReports[0].machine_id === 10 &&
                        <InputVertecMachineForm machine={{id: 10, name: 'Vertec', params: null}}
                                                shift={{
                                                    id: userReports[0].shift_id,
                                                    name: getShiftName(userReports[0].shift_id)
                                                }}
                                                report_date={userReports[0].report_date}
                                                viewMode={true}
                                                onReload={() => {
                                                    setShowReports(null)
                                                }}/>


                    }
                    {
                        userReports[0].machine_id === 11 &&
                        <InputOtherActivitiesForm machine={{id: 11, name: 'Други дейности', params: null}}
                                                  shift={{
                                                      id: userReports[0].shift_id,
                                                      name: getShiftName(userReports[0].shift_id)
                                                  }}
                                                  report_date={userReports[0].report_date}
                                                  viewMode={true}
                                                  onReload={() => {
                                                      setShowReports(null)
                                                  }}/>


                    }
                </>
            }
        </>
    )
}

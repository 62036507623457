import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    Col,
    Container,
    Form,
    ListGroup,
    Row,
    Spinner,
    Tab,
} from "react-bootstrap";
import {faCog, faDollarSign} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {API_CALL} from "../../types";
import {API} from "../../api";
import {
    ARRAY_MONTHS_FULL,
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    showMsgInToast,
    translateError
} from "../../common";
import SalaryConfigModal from "../salary/SalaryConfigModal";
import moment from "moment";
import SalaryReportModal from "../salary/SalaryReportModal";

export function SalaryPage() {
    const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
    const [month, setMonth] = useState(-1);
    const [year, setYear] = useState(-1);
    const [salary, setSalary] = useState<SalaryConfig | null>(null);
    const [loading, setLoading] = useState(false);

    const toggleKey = function (key: string) {
        setActiveKey(activeKey => (activeKey && (activeKey === key)) ? undefined : key);
    }

    const setBaseHours = (newValue: string) => {
        let nh = parseFloat(newValue);
        if (!isNaN(nh)) setSalary({...salary, baseMonthHours: nh} as SalaryConfig);
    }
    const setNormativeHours = (newValue: string) => {
        let nh = parseFloat(newValue);
        if (!isNaN(nh)) setSalary({...salary, normativeMonthHours: nh} as SalaryConfig);
    }
    const setExtraWorkRate = (newValue: string) => {
        let nh = parseFloat(newValue);
        if (!isNaN(nh)) setSalary({...salary, extraWorkRate: nh} as SalaryConfig);
    }

    const setConfigValue = (newValue: SalaryConfigItem) => {
        if (!salary) return;

        const _config = salary.config.map(
            (c: SalaryConfigItem) => (c.employee.id === newValue.employee.id) ? newValue : c
        );

        setSalary({...salary, config: _config});
    }

    const setPeriod = (newMonth: number, newYear: number) => {
        if (month === newMonth && year === newYear) loadData()
        else {
            setMonth(newMonth);
            setYear(newYear);
        }
    }

    const loadData = () => {
        setSalary(null);

        if (year < 2020) return;

        API.getSalaryReport(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    const salary: SalaryConfig =
                        {
                            ...apiCall.data.data.salary,
                            extraWorkRate: apiCall.data.data.salary.extra_work_rate,
                            normativeMonthHours: apiCall.data.data.salary.normative_month_hours,
                            baseMonthHours: apiCall.data.data.salary.base_month_hours
                        } as SalaryConfig;
                    setSalary(salary);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'ГРЕШКА', translateError);
                }
            }, month, year
        );
    }

    useEffect(() => {
        setMonth(moment().subtract(1, "month").month());
        setYear(moment().year());
    }, [])

    let t: any = null;
    useEffect(() => {
        if (t) window.clearTimeout(t);
        t = window.setTimeout(loadData, 250);

        return () => {
            if (t) window.clearTimeout(t);
        }
    }, [month, year])

    const saveSalaryConfig = () => {
        setActiveKey(undefined);


        API.setSalaryReport(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    showMsgInToast('Ставките и нормативните часове за ' + ARRAY_MONTHS_FULL[month] + ', ' + year.toString() + ' са запазени успешно.');
                    loadData()
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'ВЪЗНИКНА ГРЕШКА', translateError);
                    setActiveKey('settings');
                }

            }, {
                month: salary?.month, year: salary?.year, normativeMonthHours: salary?.normativeMonthHours,
                extraWorkRate: salary?.extraWorkRate, baseMonthHours: salary?.baseMonthHours, config: salary?.config
            } as SalaryConfig
        );
    }

    if (API.user?.is_admin !== 'Y' && API.user?.id !== 66) return null;

    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs={"auto"}>
                        <Tab.Container>

                            <ListGroup className={"w-340px ml-3"} activeKey={activeKey}>
                                <ListGroup.Item className={"text-center bg-primary text-light pt-3"}>
                                    <h4>Заплати</h4>
                                </ListGroup.Item>

                                {
                                    loading ?
                                        <ListGroup.Item>
                                            <Row>
                                                <Col className={"w-340px text-center p-5"}>
                                                    <Spinner animation={"border"}></Spinner>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                        :
                                        <>
                                            <ListGroup.Item>
                                                <Form.Group>
                                                    <Row>
                                                        <Col>
                                                            <Form.Control as="select"
                                                                          value={month}
                                                                          onChange={(e: any) => {
                                                                              setMonth(parseInt(e.target.value));
                                                                          }}>

                                                                {
                                                                    ARRAY_MONTHS_FULL.map(
                                                                        (m, idx) =>
                                                                            <option value={idx} key={idx}>{m}</option>
                                                                    )
                                                                }
                                                            </Form.Control>

                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="number"
                                                                          value={year}
                                                                          onChange={(e) => {
                                                                              const y = parseInt(e.target.value);
                                                                              if (!isNaN(y)) setYear(y);
                                                                          }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>

                                            </ListGroup.Item>

                                            <ListGroup.Item
                                                active={activeKey === 'report'}
                                                onClick={() => toggleKey('report')}>
                                                <FontAwesomeIcon icon={faDollarSign} className={"mr-2"}/>Заплати
                                            </ListGroup.Item>

                                            <ListGroup.Item
                                                active={activeKey === 'settings'}
                                                onClick={() => toggleKey('settings')}>
                                                <FontAwesomeIcon icon={faCog} className={"mr-2"}/>Ставки и
                                                нормативни
                                                часове
                                            </ListGroup.Item>
                                        </>
                                }
                            </ListGroup>

                        </Tab.Container>
                    </Col>
                </Row>
            </Container>


            {
                activeKey === 'report' && salary &&
                <SalaryReportModal
                    salary={salary}
                    onSetConfigValue={setConfigValue}
                    onSetPeriod={setPeriod}
                    onSave={saveSalaryConfig}
                    onHide={() => setActiveKey(undefined)}/>

            }

            {
                activeKey === 'settings' && salary &&
                <SalaryConfigModal
                    salary={salary}
                    onSetConfigValue={setConfigValue}
                    onSetBaseHours={setBaseHours}
                    onSetNormativeHours={setNormativeHours}
                    onSetExtraWorkRate={setExtraWorkRate}
                    onSetPeriod={setPeriod}
                    onSave={saveSalaryConfig}
                    onHide={() => setActiveKey(undefined)}/>

            }
        </>
    )
}

export interface SalaryConfigEmployee {
    id: number;
    name: string;
    department: string;
    position: string;
}

export interface SalaryConfigItem {
    employee: SalaryConfigEmployee;
    effectiveHoursRate: number;
    productionBase: number;
    prodIdx: number;
    workHoursCorr: number | null;
    extraHoursCorr: number | null;
    minSallary: number;
}

export interface SalaryDataItem {
    performance: number,
    incidents: number,
    cnt: number,
    work: {
        normal: number,
        extra: number
    },
    efectiveMH: number,
    extraWorkMH: number,
    extraWorkRate: number,
    totalMH: number,
    stopsHM: number,
    effectiveHoursRate: number,
    fixedEffectiveHoursRate: number,
    productionBase: number,
    fixedStopHoursRate: number,
    sallary: number,
    $workHours: number,
    $extraHours: number,
    $extraWorkRate: number
    $stopPerc: number
}

export interface SalaryConfig {
    month: number;
    year: number;
    normativeMonthHours: number;
    baseMonthHours: number;
    extraWorkRate: number;
    config: Array<SalaryConfigItem>;
    data: Array<SalaryDataItem>;
}

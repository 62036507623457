import {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import {LayoutComponent} from './common/LayoutComponent';
import {PATHS} from './paths';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {API} from "./api";
import {API_CALL} from "./types";
import {isSuccess} from "./common";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import bg from 'date-fns/locale/bg';
import HomePage from "./pages/homepage/HomePage";
import {LoginPage} from "./pages/LoginPage";
import {ConfigPage} from "./pages/config/ConfigPage";
import { InputPage } from './pages/input/InputPage';

import "react-datepicker/dist/react-datepicker.css";
import {OutputPage} from "./pages/input/OutputPage";
import {SalaryPage} from "./pages/input/SalaryPage";

toast.configure({
  position: toast.POSITION.BOTTOM_CENTER
});

registerLocale('bg', bg);
setDefaultLocale('bg');


const App = () => {
  const [user, setUser] = useState(null);

  const isLogin = window.location.href.indexOf(PATHS.login) > -1

  useEffect(() => {
    if (!API.user && !isLogin) {
      API.getUserData((apiCall: API_CALL) => {
        if (isSuccess(apiCall)) {
          API.user = apiCall.data.data.user;
          setUser(apiCall.data.data.user);
        }
      })
    }
  })

  if (user || isLogin) return (
      <Router>
        <LayoutComponent>
          <Switch>
            <Route exact path={PATHS.home}>
              <HomePage/>
            </Route>
            <Route exact path={PATHS.login}>
              <LoginPage/>
            </Route>
            <Route exact path={PATHS.input}>
              <InputPage/>
            </Route>
            <Route exact path={PATHS.salary}>
              <SalaryPage/>
            </Route>
            <Route exact path={PATHS.output}>
              <OutputPage/>
            </Route>
            {
                API.user && API.user.isNomenclature &&
                <Route exact path={PATHS.config}>
                    <ConfigPage/>
                </Route>
            }
          </Switch>
        </LayoutComponent>
      </Router>
  ); else return <></>;
}

export default App;

import ModalHeader from "react-bootstrap/ModalHeader";
import {
    Button,
    CloseButton,
    Col,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalTitle,
    Row,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import {ARRAY_MONTHS_FULL, editTextToFloat, textToFloat} from "../../common";
import React, {useEffect, useState} from "react";
import {SalaryConfig, SalaryConfigItem} from "../input/SalaryPage";


export default function SalaryConfigEditItemModal({editItem, onSave, onHide}: SalaryConfigEditItemModalProps) {

    const [effectiveRate, setEffectiveRate] = useState<string>(editItem.effectiveHoursRate?.toString());
    const [productionBase, setProductionBase] = useState<string>(editItem.productionBase?.toString());
    const [prodIdx, setProdIdx] = useState<string>(editItem.prodIdx?.toString());
    const [minSallary, setMinSallary] = useState<string>(editItem.minSallary?.toString() || '');

    const saveSalaryConfigItem = () => {
        const _minSallary = parseFloat(minSallary);
        const _effectiveRate = parseFloat(effectiveRate);
        const _productionBase = parseFloat(productionBase);
        const _prodIdx = parseFloat(prodIdx);


        onSave({...editItem,
            minSallary: isNaN(_minSallary) ? 0 : _minSallary,
            effectiveHoursRate: isNaN(_effectiveRate) ? 0 : _effectiveRate,
            productionBase: isNaN(_productionBase) ? 0 : _productionBase,
            prodIdx: isNaN(_prodIdx) ? 0 : _prodIdx
        });
    }

    return (
        <>
            <div className={"modal-overlay"} style={{zIndex: 9999}}/>
            <Modal onHide={() => onHide()} show={true} dialogClassName={"w-600px p-3 mt-5 max-w-none"}  style={{zIndex: 10000}}>
                <ModalHeader>
                    <Row className={"w-100"}>
                        <Col>
                            <ModalTitle>Ставки за <strong>{editItem.employee.name}</strong>
                                <br/>
                                <small className={"text-black-50"}><i>{editItem.employee.position}, {editItem.employee.department}</i></small>
                            </ModalTitle>
                        </Col>
                        <CloseButton onClick={() => onHide()}/>
                    </Row>
                </ModalHeader>
                <ModalBody style={{maxHeight: '70vh', overflow: 'auto'}}>
                    <Row>
                        <Col className={"pt-2"}>
                            Ставка ефективни часове:
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Control type="number"
                                          className={"w-160px text-right"}
                                          value={effectiveRate}
                                          onChange={(e) => setEffectiveRate(editTextToFloat(e.target.value))}
                            />
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col className={"pt-2"}>
                            Базова производителност (%):
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Control type="number"
                                          className={"w-160px text-right"}
                                          value={productionBase}
                                          onChange={(e) => setProductionBase(editTextToFloat(e.target.value))}
                            />
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col className={"pt-2"}>
                            Коефициент производитленост:
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Control type="number"
                                          className={"w-160px text-right"}
                                          value={prodIdx}
                                          onChange={(e) => setProdIdx(editTextToFloat(e.target.value))}
                            />
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col className={"pt-2"}>
                            Договорен доход:
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Control type="number"
                                          className={"w-160px text-right"}
                                          value={minSallary}
                                          onChange={(e) => setMinSallary(editTextToFloat(e.target.value))}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className={"justify-content-center"}>
                    <Button onClick={saveSalaryConfigItem}>Запази</Button>&nbsp;
                    <Button variant={"secondary"} onClick={() => onHide()}>Затвори</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}


export interface SalaryConfigEditItemModalProps {
    editItem: SalaryConfigItem;
    onSave: (newValue: SalaryConfigItem) => any;
    onHide: () => any;
}
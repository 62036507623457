import React, {Component} from 'react';
import {HeaderComponent} from './HeaderComponent';
import {FooterComponent} from './FooterComponent';
import {ToastContainer} from 'react-toastify';

export class LayoutComponent extends Component {
    render() {
        return (
            <>
                <nav className="page-header navbar fixed-top navbar-light">
                    <HeaderComponent/>
                </nav>

                <div className="page-content p-0 pt-5">
                    {this.props.children}
                </div>

                <nav className="page-footer navbar fixed-bottom">
                    <div className="container-fluid">
                        <FooterComponent/>
                    </div>
                </nav>

                <ToastContainer/>
            </>
        );
    }
}

import React from "react";
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export default function ExportButton({tab, pill, suffix, table, filename} : { tab?: string, pill?: string, suffix?: string, table?: string, filename?: string }) {
    if(!table) table = pill === "list" ? "tableList" : "tableTimes";

    let fileName = filename;
    if(!fileName) {
        switch (tab) {
            case 'Други дейности в Рязане':
                fileName = "рязане-други-дейнсоти";
                break;
            case 'Други спирания':
                fileName = "export-spirania";
                break;
            case 'Планирани ремонти':
                fileName = "export-remonti";
                break;
            case 'Коментари':
                fileName = "export-comentari";
                break;
            default:
                fileName = "export-avarii"
        }

        fileName += (pill ? (pill === "list" ? "-spisak" : "-vremena") : '') + "-" + suffix;
    }

    return (
        <ReactHTMLTableToExcel
            className="btn btn-secondary btn-sm ml-1"
            table = {table}
            filename = {fileName}
            sheet = "report"
            buttonText="XLS"/>
    );
}

import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {
    API_CALL,
    CutMachineOtherActivityValue,
    EMPTY_REPORT,
    Glass,
    GlassSize,
    Incident,
    Order,
    Report,
    Worker
} from "../../types";
import {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess, onlyPozitive,
    showErrorsInToast, textToFloat,
    translateError
} from "../../common";
import {API} from "../../api";
import {InputMachineFormProps} from "./InputPage";
import moment from "moment";
import EditCutMachineReportItemComponent from "./common/EditCutMachineReportItemComponent";
import InputCutMachineOtherActivitiesForm from "./InputCutMachineOtherActivitiesForm";
import {WorkersListComponent} from "../config/common/WorkersListComponent";
import {OrdersListComponent} from "./common/OrdersListComponent";
import {IncidentsListComponent} from "./common/IncidentsListComponent";
import {UpdateConfigurationButton} from "./common/UpdateConfigurationButton";
import {DeleteReportButton} from "./common/DeleteReportButton";
import {MoveReportButton} from "./common/MoveReportButton";


export interface CutMachineReportItem {
    machineId: 'LISEC' | 'LISEC2' | 'BAVELLONI' | null;
    glassId: number | null;
    sizeId: number | null;
    sheetCnt: number | null;
    cutCnt: number | null;
    formCnt: number | null;
}

export const EMPTY_CUTMACHINE_REPORT_ITEM = {
    machineId: null, glassId: null, sizeId: null, sheetCnt: 0, cutCnt: 0, formCnt: 0
}

export default function InputCutMachineForm({viewMode, report_date, shift, machine, onReload}: InputMachineFormProps) {
    const machineType = 'cut';
    const _dateString = moment(report_date).format('DD.MM.YYYY');

    const [working, setWorking] = useState(false);
    const [loading, setLoading] = useState(false);
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [reportData, setReportData] = useState<CutMachineReportItem[]>([]);
    const [report, setReport] = useState<Report>(EMPTY_REPORT);
    const [glasses, setGlasses] = useState<Glass[]>([]);
    const [glassSizes, setGlassSizes] = useState<GlassSize[]>([]);
    const [reportItem, setReportItem] = useState<CutMachineReportItem>(EMPTY_CUTMACHINE_REPORT_ITEM);
    const [others, setOthers] = useState<CutMachineOtherActivityValue[]>([]);
    const [workHoursBavelloni, setWorkHoursBavelloni] = useState<string>('8');
    const [workHoursLisec, setWorkHoursLisec] = useState<string>('8');
    const [comments, setComments] = useState<string>('');
    const [totalSqr, setTotalSqr] = useState<{ sheets: number, sqr: number, forms: number }>({
        sheets: 0,
        sqr: 0,
        forms: 0
    });


    useEffect(() => {
        let sqr = 0;
        let sheets = 0;
        let forms = 0;

        if (reportData) {
            reportData.forEach(g => {
                sheets += g.sheetCnt || 0;
                sqr += g.cutCnt || 0;
                forms += g.formCnt || 0;
            });
        }
        setTotalSqr({sheets: sheets, sqr: sqr, forms: forms});
    }, [reportData]);

    useEffect(() => {
        API.getGlasses(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setGlasses(api_call.data.data.glasses);
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на видовете стъкла!', translateError)
            }, machineType
        )
        API.getGlassSizes(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setGlassSizes(
                        api_call.data.data.sizes.map(
                            (s: GlassSize) => {
                                const area = s.width * s.height;
                                return {
                                    ...s,
                                    area: area,
                                    name: s.width + ' x ' + s.height + ' = ' + area.toFixed(2) + ' кв.м',
                                } as GlassSize
                            }
                        )
                    );
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на видовете размери на стъклата!', translateError)
            }
        )
    }, [])


    useEffect(() => {
        if (!loading) {
            API.getReport(
                (apiCall: API_CALL) => {
                    setLoading(isLoading(apiCall));
                    if (isSuccess(apiCall)) {
                        const r = apiCall.data.data.report;
                        const rd = JSON.parse(r.request_data);
                        setWorkers(rd.workers || []);
                        setIncidents(rd.incidents || []);
                        setOrders(rd.orders || []);
                        setReportData(rd.data);
                        setReport(r);
                        setOthers(rd.others);
                        setComments(rd.comments);
                        setWorkHoursBavelloni(rd.workHoursBavelloni || '8');
                        setWorkHoursLisec(rd.workHoursLisec || '8');
                    }
                    if (isError(apiCall)) {
                        setWorkers([]);
                        setIncidents([]);
                        setOrders([]);
                        setReportData([]);
                        setReport(EMPTY_REPORT);
                        setOthers([]);
                        setComments('');
                    }
                }, machine.id, shift.id, moment(report_date).format('YYYY-MM-DD')
            )
        }
    }, [])


    function saveReport() {
        const _r: any = {
            id: report.id,
            report_date: moment(report_date).format('YYYY-MM-DD'),
            machine: machine,
            shift: shift,
            man_cnt: workers.length,
            request_data: {
                data: reportData,
                others: others,
                workers: workers,
                orders: orders,
                incidents: incidents,
                workHoursLisec: workHoursLisec,
                workHoursBavelloni: workHoursBavelloni,
                comments: comments,
            },
        };

        API.saveReport(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    onReload();
                }
            }, _r
        );
    }


    function addRecord() {
        if (reportItem.glassId && reportItem.glassId > 0) {
            reportData.push(reportItem);
            setReportItem(EMPTY_CUTMACHINE_REPORT_ITEM);
            setReportData([...reportData]);
        }
    }

    function removeRecord(idx: number) {
        reportData.splice(idx, 1);
        setReportData([...reportData]);
    }

    // @ts-ignore
    return machineType === 'NONE' ? null :
        (
            <Modal show={true} size={"xl"}>
                <Modal.Header>
                    <Row className={"w-100"}>
                        <Col>
                            <Modal.Title>
                                <Row>
                                    <Col><span>Отчет за <strong
                                        className={"text-primary"}>Рязане (Lisec 1 + Lisec 2 + Bavelloni)</strong></span></Col>
                                    <Col className={"text-right"} xs={"auto"}>
                                <span className={"font-weight-light small"}><i><b><span
                                    className={"mr-3"}>{_dateString}</span><span>{shift.name}</span></b></i></span></Col>
                                </Row>
                            </Modal.Title>
                        </Col>
                        <Col xs={"auto"}><CloseButton onClick={onReload}/></Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body className={"max-h-60vh scrollable"}>
                            {
                                loading ? <Spinner animation="border"/> :
                                    <>
                                        <Row>
                                            <Col sm={12}>
                                                <Form>
                                                    <Table size={"sm"} bordered>
                                                        <thead>
                                                        <tr className={"text-center"}>
                                                            <th>Машина</th>
                                                            <th>Вид стъкло</th>
                                                            <th>Размер на листа</th>
                                                            <th className={"w-100px"}>Брой<br/>листи</th>
                                                            <th className={"w-100px"}>Рязане<br/>м2</th>
                                                            <th className={"w-100px"}>Форми<br/>бр</th>
                                                            {
                                                                !viewMode && <th></th>
                                                            }
                                                        </tr>
                                                        </thead>
                                                        <tbody className={"small"}>

                                                        {
                                                            glasses && glasses.length > 0 &&
                                                            <>
                                                                {
                                                                    reportData.map(
                                                                        (r, idx) =>
                                                                            <EditCutMachineReportItemComponent
                                                                                reportItem={r} key={idx}
                                                                                onChange={(newReportItem) => {
                                                                                    reportData[idx] = newReportItem;
                                                                                    setReportData([...reportData]);
                                                                                }}
                                                                                glassSizes={glassSizes}
                                                                                glasses={glasses}
                                                                                onRemove={
                                                                                    () => viewMode ? null : removeRecord(idx)
                                                                                }
                                                                            />
                                                                    )
                                                                }
                                                            </>
                                                        }
                                                        <tr className={"font-weight-bold bg-secondary-light"}>
                                                            <td colSpan={3}>
                                                                Всичко
                                                            </td>
                                                            <td className={"text-right"}>{totalSqr.sheets}</td>
                                                            <td className={"text-right"}>{totalSqr.sqr.toFixed(2)}</td>
                                                            <td className={"text-right"}>{totalSqr.forms}</td>
                                                        </tr>
                                                        {
                                                            !viewMode &&
                                                            <EditCutMachineReportItemComponent reportItem={reportItem}
                                                                                               onChange={(newReportItem) => {
                                                                                                   setReportItem(newReportItem)

                                                                                               }}
                                                                                               onAdd={() => addRecord()}
                                                                                               glassSizes={glassSizes}
                                                                                               glasses={glasses}
                                                            />
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </Form>
                                            </Col>

                                            <Col sm={12}>
                                                <InputCutMachineOtherActivitiesForm items={others} viewMode={viewMode}
                                                                                    onChange={(list) => setOthers(list)}/>
                                            </Col>

                                            <Col sm={12} className={"mb-3 pt-0 pr-5 pl-3 pb-3"}>
                                                <Row className={"bg-secondary-light p-3 pr-0 ml-1"}>
                                                    <Col>
                                                        <Row>
                                                            <Col>Часове работа на Lisec</Col>
                                                            <Col sm={3}>
                                                                <Form.Control size="sm" type="number"
                                                                              className={"text-right"}
                                                                              value={workHoursLisec}
                                                                              onChange={(e) => {
                                                                                  const nv = onlyPozitive(textToFloat(e.target.value));
                                                                                  setWorkHoursLisec((nv || 0).toString());
                                                                              }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col>Часове работа на Bavelloni</Col>
                                                            <Col sm={3}>
                                                                <Form.Control size="sm" type="number"
                                                                              className={"text-right"}
                                                                              value={workHoursBavelloni}
                                                                              onChange={(e) => {
                                                                                  const nv = onlyPozitive(textToFloat(e.target.value));
                                                                                  setWorkHoursBavelloni((nv || 0).toString());
                                                                              }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col sm={12} xl={6}>
                                                <WorkersListComponent workers={workers} defWorkHours={8}
                                                                      forDate={moment(report_date).format('YYYY-MM-DD')}
                                                                      showWorkHours={true} viewMode={viewMode}
                                                                      onChange={(workers) => setWorkers(workers)}/>
                                            </Col>

                                            <Col sm={12} xl={6}>
                                                <OrdersListComponent dim={'кв.м.'} orders={orders} viewMode={viewMode}
                                                                     onChange={(orders => setOrders(orders))}/>
                                            </Col>

                                            <Col sm={12} className={"mt-3"}>
                                                <IncidentsListComponent incidents={incidents} machine={machine}
                                                                        viewMode={viewMode}
                                                                        onChange={(incidents) => setIncidents(incidents)}/>
                                            </Col>

                                            <Col sm={12} className={"mt-3"}>
                                                <Form.Group className={" bg-secondary-light p-2"}>
                                                    <Form.Label
                                                        className={"text-primary font-weight-bold text-center w-100"}>Коментари
                                                        и забележки</Form.Label>
                                                    <Form.Control as={"textarea"} readOnly={viewMode}
                                                                  value={comments}
                                                                  onChange={(e) => setComments(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                            }
                        </Card.Body>
                        <Card.Footer>
                            {
                                working ? <Spinner animation="border"/> :
                                    <>
                                        {
                                            !viewMode && <>
                                                <Button onClick={() => saveReport()}><FontAwesomeIcon
                                                    icon={faSave}/> Запази</Button>

                                                <span className={"float-right"}>
                                                    <UpdateConfigurationButton reportId={report.id}/>
                                                    <DeleteReportButton reportId={report.id} onDelete={onReload}/>
                                                    <MoveReportButton reportId={report.id} onMoved={onReload}/>
                                                </span>
                                            </>
                                        }
                                    </>
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>
        )
}

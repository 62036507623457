import {Button, Card, CloseButton, Form, Modal, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFireAlt, faPen, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import {API_CALL, EMPTY_MACHINE_INCIDENT, Machine, MachineIncident} from "../../../types";
import React, {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast, translate,
    translateError
} from "../../../common";
import {API} from "../../../api";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {ConfirmDeleteModal} from "../../../common/ConfirmDeleteModal";

export interface ConfigMachineIncidentsFormProps {
    machine: Machine;
    onReload: () => any;
    onSelect?: (incident: MachineIncident) => any;
}

export default function ConfigIncidentsEditForm({machine, onReload, onSelect}: ConfigMachineIncidentsFormProps) {
    const [working, setWorking] = useState(false);
    const [incidents, setIncidents] = useState<MachineIncident[]>([]);
    const [data, setData] = useState<MachineIncident[] | null>([]);
    const [incident, setIncident] = useState<MachineIncident | null>(null);
    const [deleteIncident, setDeleteIncident] = useState<MachineIncident | null>(null);
    const [activeKey, setActiveKey] = useState('INCIDENT');

    useEffect(() => {
        reload();
    }, [])

    function saveMachineIncident() {
        API.saveMachineIncident(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    reload();
                }
            }, {...incident}
        );
    }


    function reload() {
        API.getMachineIncidents(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setData(api_call.data.data.incidents);
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на списъка с аварии!', translateError)
            }, machine.id
        );

        setIncident(null);
        setDeleteIncident(null);
    }

    function doDeleteMachineIncident(incident: MachineIncident) {
        API.deleteMachineIncident(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    reload()
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при опит да се изтрие аварията!', translateError)
            }, incident ? incident.id : -1
        );
    }

    useEffect(() => {
        const _i = data ? data.filter(i => i.incident_type === activeKey) : [];
        setIncidents(_i);
    }, [activeKey, data]);

    return (
        <>
            <Modal show={true} size={"xl"} onHide={onReload}>
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faFireAlt} className={"mr-3"}/>Списък аварии/друг вид престои за
                        машина <strong
                            className={"text-primary"}>{machine.name}</strong></Modal.Title>
                    <CloseButton onClick={onReload}/>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body className={"max-h-60vh scrollable"}>

                            <Tabs activeKey={activeKey} onSelect={eventKey => setActiveKey(eventKey || 'INCIDENT')}
                            className={"ml-0 mb-1"} >
                                <Tab eventKey="INCIDENT" title="Аварии" />
                                <Tab eventKey="STOP" title="Спирания" />
                                <Tab eventKey="REPAIR" title="Планирани ремонти" />
                            </Tabs>

                            <Table size={"sm"} bordered striped>
                                <tbody>
                                {
                                    working &&
                                    <tr>
                                        <td colSpan={3} className={"text-center"}>
                                            <Spinner animation={"border"}/>
                                        </td>
                                    </tr>
                                }
                                {
                                    incidents && incidents.length === 0 && !working &&
                                    <tr>
                                        <td colSpan={3} className={"fw-bold"}>Списъкът е празен</td>
                                    </tr>
                                }
                                {
                                    incidents?.map(
                                        i =>
                                            <tr key={i.id}>
                                                <td>{i.name}</td>
                                                <td className={"w-100px text-right"}>
                                                    <Button onClick={() => setIncident(i)} size={"sm"}>
                                                        <FontAwesomeIcon icon={faPen}/></Button>&nbsp;
                                                    <Button onClick={() => setDeleteIncident(i)} size={"sm"}>
                                                        <FontAwesomeIcon icon={faTrash}/></Button>
                                                </td>
                                            </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            {
                                working ? <Spinner animation="border"/> :
                                    <Button onClick={() => setIncident({
                                        ...EMPTY_MACHINE_INCIDENT,
                                        machine_id: machine.id
                                    } as MachineIncident)}>
                                        <FontAwesomeIcon icon={faPlus}/> Добави нов престой
                                    </Button>
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>

            {
                incident &&
                <>
                    <div className={"modal-overlay"} style={{zIndex: 9990}}/>
                    <Modal show={true} onHide={() => setIncident(null)} style={{zIndex: 9991}}>
                        <Modal.Header>
                            <Modal.Title>Описание на престоя</Modal.Title>
                            <CloseButton onClick={() => setIncident(null)}/>
                        </Modal.Header>
                        <Modal.Body>
                            <Card>
                                <Card.Body className={"max-h-60vh scrollable"}>
                                    <Form>
                                        <Form.Control value={incident?.name}
                                                      onChange={(e) => setIncident({
                                                          ...incident,
                                                          name: e.target.value
                                                      } as MachineIncident)}
                                        />
                                        <Form.Group className="mt-3">
                                            <Form.Control as={"select"} value={incident?.incident_type}
                                                          onChange={(e) => setIncident({
                                                              ...incident,
                                                              incident_type: e.target.value
                                                          } as MachineIncident)}
                                            >
                                                <option value={"INCIDENT"}>Авария</option>
                                                <option value={"STOP"}>Друго спиране</option>
                                                <option value={"REPAIR"}>Планиран ремонт</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>
                                </Card.Body>
                                <Card.Footer>
                                    {
                                        working ? <Spinner animation="border"/> :
                                            <Button onClick={() => saveMachineIncident()}><FontAwesomeIcon
                                                icon={faSave}/> Запази</Button>
                                    }
                                </Card.Footer>
                            </Card>
                        </Modal.Body>
                    </Modal>

                </>
            }

            {
                deleteIncident &&
                <ConfirmDeleteModal working={working}
                                    onConfirm={() => doDeleteMachineIncident(deleteIncident)}
                                    onReject={() => setDeleteIncident(null)}/>
            }

        </>
    )
}

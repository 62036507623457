import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {API_CALL, EMPTY_REPORT, IdName, Incident, Order, Report, Worker} from "../../types";
import {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess, mapToObject,
    onlyPozitive,
    showErrorsInToast,
    textToFloat,
    translateError
} from "../../common";
import {API} from "../../api";
import {VXMachineParams} from "../config/machines/ConfigVXMachineForm";
import {InputMachineFormProps} from "./InputPage";
import moment from "moment";
import {WorkersListComponent} from "../config/common/WorkersListComponent";
import {OrdersListComponent} from "./common/OrdersListComponent";
import {IncidentsListComponent} from "./common/IncidentsListComponent";
import {OvenMachineParams} from "../config/machines/ConfigOvenMachineForm";
import {UpdateConfigurationButton} from "./common/UpdateConfigurationButton";
import {DeleteReportButton} from "./common/DeleteReportButton";
import {MoveReportButton} from "./common/MoveReportButton";


export interface OvenMachineReportItem {
    normalCnt: number | null; // Нормален режим на работа sec за цикъл
    speedCnt: number | null; //  Ускорен режим на работа sec за цикъл
    avgCnt: number | null; // м2 среднодневно зареждане на маса
}

export const EMPTY_OVENMACHINE_REPORT_ITEM = {
    normalCnt: 0, speedCnt: 0, avgCnt: 0
}

export default function InputOvenMachineForm({viewMode, report_date, shift, machine, onReload}: InputMachineFormProps) {
    const machineType = machine.name === 'Малка печка' ? 'SMALL' : (machine.name === 'Голяма печка' ? 'BIG' :'NONE');
    const _dateString = moment(report_date).format('DD.MM.YYYY');

    const [working, setWorking] = useState(false);
    const [loading, setLoading] = useState(false);
    const [workHours, setWorkHours] = useState('');
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [reportData, setReportData] = useState<Map<number, OvenMachineReportItem>>(new Map());
    const [report, setReport] = useState<Report>(EMPTY_REPORT);
    const [glasses, setGlasses] = useState<IdName[]>([]);
    const [comments, setComments] = useState<string>('');

    useEffect(() => {
        if(machineType !== 'NONE') {
            API.getGlasses(
                (api_call: API_CALL) => {
                    setWorking(isLoading(api_call));
                    if (isSuccess(api_call)) {
                        setGlasses(api_call.data.data.glasses);
                    }
                    if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на видовете стъкла!', translateError)
                }, machineType
            )
        }
    }, [machineType])


    useEffect(() => {
        let wh = workHours ? workHours.toString() : '8';
        if(!wh) wh = '8';
        setReport({
            ...report,
            workHours: onlyPozitive(textToFloat(wh) || 8) || 8
        });
    }, [workHours])


    useEffect(() => {
        if(!loading && glasses.length > 0) {
            API.getReport(
                (apiCall: API_CALL) => {
                    setLoading(isLoading(apiCall));
                    if (isSuccess(apiCall)) {
                        const r = apiCall.data.data.report;
                        const rd = JSON.parse(r.request_data);
                        setWorkers(rd.workers || []);
                        setIncidents(rd.incidents || []);
                        setOrders(rd.orders || []);
                        setWorkHours(rd.workHours);
                        r.workHours = rd.workHours;

                        if(glasses) {
                            const m = new Map();
                            glasses.forEach(
                                g => {
                                    if(rd.data[g.id]) {
                                        m.set(g.id, rd.data[g.id]);
                                    }
                                }
                            )

                            setReportData(m);
                        }
                        setReport(r);
                        setComments(rd.comments);
                    }
                    if (isError(apiCall)) {
                        setWorkers([]);
                        setIncidents([]);
                        setOrders([]);
                        setReportData(new Map());
                        setReport(EMPTY_REPORT);
                        setComments('');
                    }
                }, machine.id, shift.id, moment(report_date).format('YYYY-MM-DD')
            )
        }
    }, [glasses])


    function saveReport() {
        const _r: any = {
            id: report.id,
            report_date: moment(report_date).format('YYYY-MM-DD'),
            machine: machine,
            shift: shift,
            man_cnt: workers.length,
            request_data: {workHours: report.workHours, data: mapToObject(reportData), workers: workers, orders: orders,
                incidents: incidents, comments: comments},
        };

        API.saveReport(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    onReload();
                }
            }, _r
        );
    }


    const updateTime = (id: number, value: string, fld: string) => {
        value = value.replace(',', '.');
        const _v = (parseFloat(value) > 0) ? onlyPozitive(textToFloat(value)) : 0;
        const _p = {...reportData.get(id) || EMPTY_OVENMACHINE_REPORT_ITEM};
        switch (fld) {
            case 'NORMAL':
                _p.normalCnt = _v;
                break;
            case 'SPEED':
                _p.speedCnt = _v;
                break;
            case 'LOADING':
                _p.avgCnt = _v;
                break;
        }
        reportData.set(id, _p);

        const newMap = new Map(reportData);
        setReportData(newMap);
    }


    return machineType === 'NONE' ? null :
        (
            <Modal show={true} size={"xl"} >
                <Modal.Header>
                    <Row className={"w-100"}>
                        <Col>
                            <Modal.Title>
                                <Row>
                                    <Col><span>Отчет за <strong
                                        className={"text-primary"}>{machine.name}</strong></span></Col>
                                    <Col className={"text-right"} xs={"auto"}>
                                <span className={"font-weight-light small"}><i><b><span
                                    className={"mr-3"}>{_dateString}</span><span>{shift.name}</span></b></i></span></Col>
                                </Row>
                            </Modal.Title>
                        </Col>
                        <Col xs={"auto"}><CloseButton onClick={onReload}/></Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body className={"max-h-60vh scrollable"}>
                            {
                                loading ? <Spinner animation="border"/> :
                                    <>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Form>
                                                            <Table size={"sm"} bordered>
                                                                <thead>
                                                                <tr className={"text-center"}>
                                                                    <th>Вид стъкло</th>
                                                                    <th>Брой цикли<br/>нормален режим</th>
                                                                    <th>Брой цикли<br/>ускорен режим</th>
                                                                    <th>Зареждане<br/>(кв.м.)</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    glasses.map(
                                                                        g =>
                                                                            <tr key={"g_" + g.id}>
                                                                                <td className={"font-weight-bold small text-nowrap"}>{g.name}</td>
                                                                                <td>
                                                                                    <Form.Control size="sm" type="number"
                                                                                                  value={(reportData.get(g.id)?.normalCnt) +  ''}
                                                                                                  readOnly={viewMode}
                                                                                                  onChange={(e) => updateTime(g.id, e.target.value, 'NORMAL')}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Form.Control size="sm" type="number"
                                                                                                  value={(reportData.get(g.id)?.speedCnt) + ''}
                                                                                                  readOnly={viewMode}
                                                                                                  onChange={(e) => updateTime(g.id, e.target.value, 'SPEED')}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Form.Control size="sm" type="number"
                                                                                                  value={reportData.get(g.id)?.avgCnt + ''}
                                                                                                  readOnly={viewMode}
                                                                                                  onChange={(e) => updateTime(g.id, e.target.value, 'LOADING')}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                    )
                                                                }
                                                                </tbody>
                                                            </Table>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Продължителност на смяната, Часове</Form.Label>
                                                                <Form.Control type="number"
                                                                              value={workHours}
                                                                              readOnly={viewMode}
                                                                              onChange={(e) => setWorkHours(e.target.value)}
                                                                />
                                                            </Form.Group>
                                                        </Form>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <WorkersListComponent workers={workers} defWorkHours={8}
                                                                                      forDate={moment(report_date).format('YYYY-MM-DD')}
                                                                                      showWorkHours={true} viewMode={viewMode}
                                                                                      onChange={(workers) => setWorkers(workers)}/>
                                                            </Col>
                                                            <Col sm={12}>
                                                                <OrdersListComponent dim={'кв.м.'} orders={orders} viewMode={viewMode}
                                                                                     onChange={(orders => setOrders(orders))}/>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <IncidentsListComponent incidents={incidents} machine={machine} viewMode={viewMode}
                                                                        onChange={(incidents) => setIncidents(incidents)}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className={"mt-3"}>
                                                <Form.Group className={" bg-secondary-light p-2"}>
                                                    <Form.Label className={"text-primary font-weight-bold text-center w-100"}>Коментари и забележки</Form.Label>
                                                    <Form.Control as={"textarea"}
                                                                  value={comments || ''}
                                                                  readOnly={viewMode}
                                                                  onChange={(e) => setComments(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                            }
                        </Card.Body>
                        <Card.Footer>
                            {
                                !viewMode && (
                                    working ? <Spinner animation="border"/> :
                                        <>
                                            <Button onClick={() => saveReport()}><FontAwesomeIcon
                                                icon={faSave}/> Запази</Button>

                                            <span className={"float-right"}>
                                                <UpdateConfigurationButton reportId={report.id}/>
                                                <DeleteReportButton reportId={report.id} onDelete={onReload} />
                                                <MoveReportButton reportId={report.id} onMoved={onReload} />
                                            </span>
                                        </>
                                )
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>
        )
}

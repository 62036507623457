import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Spinner} from "react-bootstrap";
import React, {useState} from "react";
import {ConfirmModal} from "../../../common/ConfirmModal";
import {API} from "../../../api";
import {API_CALL} from "../../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, showMsgInToast, translateError} from "../../../common";
import moment from "moment";

export interface DeleteReportButtonProps {
    reportId: number
    onDelete: () => any
}

export function DeleteReportButton({reportId, onDelete}: DeleteReportButtonProps) {
    const [working, setWorking] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const updateConfig = function(btn: string) {
        setShowConfirm(false);
        if(btn === "Потвърждавам") {
            API.updateReportConfig(
                (apiCall: API_CALL) => {
                    if(isSuccess(apiCall)) {
                        showMsgInToast("Цонфигурацията е актуализирана успешно!");
                    }
                    if(isError(apiCall)) {
                        showErrorsInToast(apiCall, "Възникна грешка", translateError);
                    }
                }, reportId
            )
        }
    }

    const deleteReport = (btn: string) => {
        if(btn === "Потвърждавам")
            API.removeReport(
                (apiCall: API_CALL) => {
                    setWorking(isLoading(apiCall));

                    if(isSuccess(apiCall)) {
                        showMsgInToast('Изтриването е успешно!');
                        onDelete();
                    }

                    if(isError(apiCall)) {
                        showErrorsInToast(apiCall, 'Грешка', translateError);
                    }
                }, reportId
            );

        setShowConfirm(false)
    }


    if(reportId === -1) return null;

    if(working) return <Spinner animation={"border"} />

    return (
        <>
            &nbsp;<Button onClick={() => setShowConfirm(true)}><FontAwesomeIcon
            icon={faTrash}/>&nbsp;&nbsp;Изтрий&nbsp;</Button>

            {
                showConfirm &&
                <>
                    <ConfirmModal
                        title={"Внимание"}
                        message={"Моля, потвърдете изтриването на този отчет!"}
                        buttons={["Потвърждавам", "Отказ"]}
                        primaryBtn={"Отказ"}
                        onButtonClick={deleteReport}
                        onClose={() => setShowConfirm(false)}
                    />
                </>
            }
        </>

    )
}
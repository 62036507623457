import ModalHeader from "react-bootstrap/ModalHeader";
import {
    Button,
    CloseButton,
    Col, Dropdown,
    Modal,
    ModalBody,
    ModalFooter,
    ModalTitle,
    Row, Spinner,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import {ReadOnlyDatePickerComponent} from "../../common/ReadOnlyDatePickerComponent";
import {
    dateToString,
    getCutShiftName,
    getShiftName,
    isLoading,
    isSuccess, zeroToEmptyString,
    zeroToEmptyStringAsNumber
} from "../../common";
import React, {useEffect, useState} from "react";
import {faCalculator, faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../../api";
import {API_CALL} from "../../types";
import moment from "moment";
import InputCutMachineForm from "../input/InputCutMachineForm";
import InputVXMachineForm from "../input/InputVXMachineForm";
import InputVEMachineForm from "../input/InputVEMachineForm";
import InputPackageMachineForm from "../input/InputPackageMachineForm";
import InputLaminationMachineForm from "../input/InputLaminationMachineForm";
import InputProfileMachineForm from "../input/InputProfileMachineForm";
import InputOvenMachineForm from "../input/InputOvenMachineForm";
import InputVertecMachineForm from "../input/InputVertecMachineForm";
import InputOtherActivitiesForm from "../input/InputOtherActivitiesForm";
import InputAlpaMachineForm from "../input/InputAlpaMachineForm";
import ExportButton from "../../common/ExportButton";


export interface OutputProductivityModalProps {
    onHide: () => any;
}


export default function OutputProductivityModal({onHide}: OutputProductivityModalProps) {

    const [reportDate, setReportDate] = useState<Date>(new Date());
    const [mode, setMode] = useState<string | null>("mode1");
    const [loading, setLoading] = useState(false);
    const [loadingReports, setLoadingReports] = useState(false);
    const [report, setReport] = useState<any>(null);
    const [userReports, setUserReports] = useState<any>(null);
    const [departments, setDepartments] = useState<string[]>([]);
    const [activeTab, setActiveTab] = useState<string>("Всички отдели");
    const [showReports, setShowReports] = useState<{ id: number, date: string } | null>(null);


    function tableHeaderCell(k: string) {
        if (mode === "mode2") {
            const w = parseInt(k);
            if (isNaN(w)) return k;

            return moment(reportDate).startOf('year').add(parseInt(k), 'weeks').startOf('week').add(1, 'day').format('DD.MM') + ' - ' +
                moment(reportDate).startOf('year').add(parseInt(k), 'weeks').endOf('week').add(1, 'day').format('DD.MM.YYYY');
        } else return k;
    }

    function TableHeader({row}: any) {
        return (
            <thead>
            <tr>
                <th rowSpan={2}>Служител</th>
                <th rowSpan={2}>Длъжност</th>
                {
                    extractDataKeys(row).map(
                        (k, idx) => <th key={k}
                                        className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}
                                        colSpan={3}>{tableHeaderCell(k)}</th>
                    )
                }
            </tr>
            <tr>
                {
                    extractDataKeys(row).map(
                        (k, idx) =>
                            <>
                                <th className={"text-center " + (idx % 2 === 0 ? "bg-secondary-light" : "")}>Пр.%</th>
                                <th className={"text-center text-danger " + (idx % 2 === 0 ? "bg-secondary-light" : "")}>Ав.%</th>
                                <th className={"text-center " + (idx % 2 === 0 ? "bg-secondary-light" : "")}>ЧТ</th>
                            </>
                    )
                }
            </tr>
            </thead>
        )
    }

    const reload = function () {
        if (loading) return;
        API.getPerformance(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    setReport(apiCall.data.data);
                }
            }, mode || 'mode1', moment(reportDate).format('YYYY-MM-DD')
        )
    }

    useEffect(() => {
        if (!showReports) return;
        if (loadingReports) return;

        setUserReports(null);

        API.getUserReports(
            (apiCall: API_CALL) => {
                setLoadingReports(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    setUserReports(apiCall.data.data.report[0]);
                }
            }, showReports.id, showReports.date
        )
    }, [showReports]);

    const recalc = function () {
        if (loading) return;
        API.recalculatePerformance(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    setReport(apiCall.data.data);
                }
            }, mode || 'mode1', moment(reportDate).format('YYYY-MM-DD')
        )
    }

    useEffect(() => {
        reload()
    }, [reportDate, mode]);

    useEffect(() => {
        if (report?.departments) {
            setDepartments(Object.keys(report.departments));
        } else {
            setDepartments([]);
        }
    }, [report])


    let currentDep = "";

    const printDepartment = function (uDep: string) {
        if (currentDep !== uDep) {
            currentDep = uDep;

            return <>
                <tr>
                    <td colSpan={Object.keys(report.departments[uDep]['performance']).length}>&nbsp;</td>
                </tr>
                <tr className={"text-white bg-secondary font-weight-bold"}>
                    <td colSpan={2}>{uDep}</td>
                    {
                        extractDataKeys(report.departments[uDep]['performance'])
                            .map(
                                (k: any, idx: number) => {
                                    return (
                                        <>
                                            <td key={'dp_' + k} className={"text-right"}>
                                                {zeroToEmptyStringAsNumber(report.departments[uDep]['performance'][k]?.performance)}</td>
                                            <td key={'di_' + k} className={"text-right text-danger-light"}>
                                                {zeroToEmptyStringAsNumber(report.departments[uDep]['performance'][k]?.incidents)}</td>
                                            <td></td>
                                        </>
                                    )
                                }
                            )
                    }
                </tr>
            </>
        }

        return null;
    }

    const OtherActivities = function () {
        const keys = extractDataKeys(report.others.total);

        return <>
            <tr>
                <td colSpan={keys.length}>&nbsp;</td>
            </tr>
            <tr className={"text-white bg-secondary font-weight-bold"}>
                <td colSpan={2}>Други дейности</td>
                {
                    keys
                        .filter(k => k !== "За периода")
                        .map(
                        (k: any, idx: number) => {
                            return (
                                    <td key={'dp_oth_' + k} className={"text-right"} colSpan={3}>
                                        {printWorkTime(report.others.total[k])}</td>
                            )
                        }
                    )
                }
                <td className={"text-right"} colSpan={3}>
                    {printWorkTime(report.others.total['total'])}</td>
            </tr>

                {
                    report.users
                        .filter(
                            (u: any) => report.others[u.id].total > 0
                        )
                        .map(
                        (u: any) => <tr key={'ur_' + u.id}>
                            <td>{ u.name }</td>
                            <td>{ u.position }</td>
                            {
                                extractDataKeys(report.others[u.id])
                                    .filter(k => k !== "За периода")
                                    .map(
                                        i => <td key={"ur_u_" + i} colSpan={3} className={"text-right"}>
                                            { isNaN(report.others[u.id][i]) ?
                                                (report.others[u.id][i] && mode === "mode1" ?
                                                <a href={""}
                                                   onClick={e => {
                                                        e.preventDefault();
                                                        showReportsFor(report.others[u.id][i].id, report.others[u.id][i].for_date)
                                                   }}>
                                                    { printWorkTime(report.others[u.id][i]?.work_hours * 60 + report.others[u.id][i]?.work_minutes * 1) }
                                                </a>
                                                : printWorkTime(report.others[u.id][i]?.value)) : '' }
                                        </td>
                                    )
                            }
                            <td colSpan={3} className={"text-right text-light font-weight-bold bg-secondary"}>
                                { printWorkTime(report.others[u.id]['total']) }
                            </td>
                        </tr>
                    )
                }
        </>
    }

    const extractDataKeys = function (data: any): string[] {
        if (mode === 'mode1')
            return Object.keys(report.total)
                .sort((a, b) =>
                    moment(a) > moment(b) ? 1 : (moment(a) < moment(b) ? -1 : 0))

        return Object.keys(report.total)
            .sort((a, b) => a > b ? 1 : (a < b ? -1 : 0))
    }

    const printTotal = function () {

        return <>
            <tr>
                <td colSpan={Object.keys(report.total).length}>&nbsp;</td>
            </tr>
            <tr className={"bg-primary-light font-weight-bold"}
                style={{lineHeight: "2rem", fontSize: "1rem"}}>
                <td colSpan={2} style={{border: "solid 1px silver"}}>ВСИЧКИ ОТДЕЛИ</td>
                {
                    extractDataKeys(report.total).map(
                        (k: any, idx: number) => {
                            return (
                                <>
                                    <td key={'tp_' + k} className={"text-right"} style={{border: "solid 1px silver"}}>
                                        {zeroToEmptyStringAsNumber(report.total[k].performance)}</td>
                                    <td key={'ti_' + k} className={"text-right text-danger"}
                                        style={{border: "solid 1px silver"}}>
                                        {zeroToEmptyStringAsNumber(report.total[k].incidents)}</td>
                                    <td></td>
                                </>
                            )
                        }
                    )
                }
            </tr>
        </>
    }

    function showReportsFor(id: number, date: string) {
        setShowReports({id: id, date: date});
    }


    function printWorkTime(t: number) {
        const h = Math.floor(t / 60);
        const m = t % 60;
        let r = '';

        if (h > 0) r += h + 'ч.';
        if (m > 0) r += m + 'мин. ';

        return <>{r}</>;
    }

    return (
        <>
            <Modal onHide={() => onHide()} show={true} dialogClassName={"w-100 p-3 max-w-none"}>
                <ModalHeader>
                    <Row className={"w-100"}>
                        <Col>
                            <ModalTitle>Справка производителност</ModalTitle>
                        </Col>
                        <Col className={"justify-content-end"} xs={"auto"}>
                            <Row className={"flex-nowrap"}>
                                <Col>
                                    <ReadOnlyDatePickerComponent onChange={(date: Date) => {
                                        setReportDate(date)
                                    }} value={dateToString(reportDate)}/>
                                </Col>
                                <Col>
                                    <Button onClick={() => reload()} variant={"secondary"} size="sm" className={"mr-1"}
                                            title={"Презареди таблицата"}><FontAwesomeIcon
                                        icon={faSyncAlt}/></Button>
                                    <Button onClick={() => recalc()} variant={"secondary"} size="sm" className={"mr-1"}
                                            title={"Преизчисли производителноста за периода"}><FontAwesomeIcon
                                        icon={faCalculator}/></Button>

                                    <ExportButton  table={"dataTable"} filename={"report-productivity-" +
                                        (mode === "mode1" ? 'dnevna' : (mode === "mode2" ? 'sedmichna' : 'mesechna')) +
                                        "-" + moment(reportDate).format('DD.MM.YYYY')} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={"auto"}>
                            <CloseButton onClick={() => onHide()}/>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody style={{maxHeight: '70vh', overflow: 'auto'}}>
                    {
                        loading && <Spinner animation={"border"}/>
                    }
                    {
                        report && Object.keys(report.performance).length > 0 && !loading &&
                        <div className={"table-responsive"} style={{maxHeight: '65vh'}}>

                            <Tabs activeKey={activeTab} onSelect={(e) => setActiveTab(e || "Всички отдели")}>
                                <Tab title={"Всички отдели"} eventKey={"Всички отдели"}/>
                                {departments.map((k: string) => <Tab key={k} title={k} eventKey={k}/>)}
                            </Tabs>

                            <div className="w-100 overflow-auto" style={{maxHeight: '60vh'}}>
                                <Table striped bordered hover size={"sm"} className={"small"} style={{height: '100%'}}
                                       id="dataTable">
                                    <TableHeader row={report.performance[report.users[0].id]}/>
                                    <tbody>
                                    {printTotal()}
                                    {
                                        report.users
                                            .filter(
                                                (u: any) =>
                                                    ((activeTab === "Всички отдели") ||
                                                        (u.department == activeTab))
                                            )
                                            .map(
                                                (u: any) => {
                                                    return <>
                                                        {
                                                            printDepartment(u.department)
                                                        }
                                                        <tr key={u.id}>
                                                            <td>{u.name}</td>
                                                            <td>{u.position}</td>
                                                            {
                                                                extractDataKeys(report.performance[u.id]).map(
                                                                    (k: any, idx: number) => {
                                                                        return (
                                                                            <>
                                                                                {
                                                                                    mode === "mode1" ?
                                                                                        <td key={'p_' + k}
                                                                                            className={"text-right " + (idx % 2 === 0 ? "bg-secondary-light" : "")}>
                                                                                            {
                                                                                                k === "За периода" &&
                                                                                                zeroToEmptyStringAsNumber(report.performance[u.id][k].performance)
                                                                                            }
                                                                                            {
                                                                                                report.performance[u.id][k].performance * 1 <= 0 &&
                                                                                                report.performance[u.id][k].incidents * 1 <= 0
                                                                                                    ? null
                                                                                                    :
                                                                                                    (
                                                                                                        report.performance[u.id][k].reports?.length > 0 ?
                                                                                                            (
                                                                                                                report.performance[u.id][k].reports.length === 1 ?
                                                                                                                    <a href=""
                                                                                                                       onClick={(e) => {
                                                                                                                           e.preventDefault();
                                                                                                                           showReportsFor(report.performance[u.id][k].reports[0].id, k);
                                                                                                                       }}>{zeroToEmptyStringAsNumber(report.performance[u.id][k].performance)}</a>
                                                                                                                    :
                                                                                                                    <Dropdown>
                                                                                                                        <Dropdown.Toggle
                                                                                                                            variant="secondary"
                                                                                                                            style={{fontSize: "12px"}}>
                                                                                                                            {zeroToEmptyStringAsNumber(report.performance[u.id][k].performance)}
                                                                                                                        </Dropdown.Toggle>

                                                                                                                        <Dropdown.Menu>
                                                                                                                            {
                                                                                                                                report.performance[u.id][k].reports.map(
                                                                                                                                    (r: any) =>
                                                                                                                                        <Dropdown.Item
                                                                                                                                            onClick={(e) => {
                                                                                                                                                e.preventDefault();
                                                                                                                                                showReportsFor(r.id, k)
                                                                                                                                            }}>
                                                                                                                                            {r.machine_name} - &nbsp;
                                                                                                                                            {getShiftName(r.shift_id)}&nbsp;
                                                                                                                                            ({zeroToEmptyString(r.performance) }%)
                                                                                                                                        </Dropdown.Item>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </Dropdown.Menu>
                                                                                                                    </Dropdown>
                                                                                                            ) : null
                                                                                                    )

                                                                                            }
                                                                                        </td>
                                                                                        :
                                                                                        <td key={'p_' + k}
                                                                                            className={"text-right " + (idx % 2 === 0 ? "bg-secondary-light" : "")}>
                                                                                            {zeroToEmptyStringAsNumber(report.performance[u.id][k].performance)}</td>
                                                                                }
                                                                                <td key={'i_' + k}
                                                                                    className={"text-right text-danger " + (idx % 2 === 0 ? "bg-secondary-light" : "")}>{zeroToEmptyStringAsNumber(report.performance[u.id][k].incidents)}</td>
                                                                                <td className={"text-right bg-primary-light"}>
                                                                                    <span>{printWorkTime(report.performance[u.id][k].work.normal)}</span>
                                                                                    {
                                                                                        report.performance[u.id][k].work.extra > 0 &&
                                                                                        <>
                                                                                            | <span
                                                                                            className={"text-danger"}>{printWorkTime(report.performance[u.id][k].work.extra)}</span>
                                                                                        </>
                                                                                    }
                                                                                </td>
                                                                            </>
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </tr>
                                                    </>
                                                }
                                            )
                                    }
                                    <OtherActivities/>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    }
                    {
                        !loading && !report &&
                        <div><strong>Няма данни</strong></div>
                    }

                </ModalBody>
                <ModalFooter className={"justify-content-center"}>
                    <Tabs variant={"pills"} onSelect={eventKey => setMode(eventKey)}
                          defaultActiveKey={mode ? mode : undefined}>
                        <Tab title={"Дневно / седмична"} eventKey={"mode1"}/>
                        <Tab title={"Седмично / месечна"} eventKey={"mode2"}/>
                        <Tab title={"Месечно / годишен"} eventKey={"mode3"}/>
                    </Tabs>
                </ModalFooter>
            </Modal>


            {
                showReports && userReports &&
                <>
                    {
                        userReports[0].machine_id === 12 &&
                        <InputAlpaMachineForm machine={{id: 12, name: 'Alpa', params: null}}
                                             shift={{
                                                 id: userReports[0].shift_id,
                                                 name: getShiftName(userReports[0].shift_id)
                                             }}
                                             report_date={userReports[0].report_date}
                                             viewMode={true}
                                             onReload={() => {
                                                 setShowReports(null)
                                             }}/>


                    }
                    {
                        userReports[0].machine_id === 1 &&
                        <InputCutMachineForm machine={{id: 1, name: 'Рязане', params: null}}
                                             shift={{
                                                 id: userReports[0].shift_id,
                                                 name: getCutShiftName(userReports[0].shift_id)
                                             }}
                                             report_date={userReports[0].report_date}
                                             viewMode={true}
                                             onReload={() => {
                                                 setShowReports(null)
                                             }}/>


                    }
                    {
                        userReports[0].machine_id === 2 &&
                        <InputVXMachineForm machine={{id: 2, name: 'VX', params: null}}
                                            shift={{
                                                id: userReports[0].shift_id,
                                                name: getShiftName(userReports[0].shift_id)
                                            }}
                                            report_date={userReports[0].report_date}
                                            viewMode={true}
                                            onReload={() => {
                                                setShowReports(null)
                                            }}/>


                    }
                    {
                        userReports[0].machine_id === 3 &&
                        <InputProfileMachineForm machine={{id: 3, name: 'Profile', params: null}}
                                                 shift={{
                                                     id: userReports[0].shift_id,
                                                     name: getShiftName(userReports[0].shift_id)
                                                 }}
                                                 report_date={userReports[0].report_date}
                                                 viewMode={true}
                                                 onReload={() => {
                                                     setShowReports(null)
                                                 }}/>


                    }
                    {
                        userReports[0].machine_id === 4 &&
                        <InputVEMachineForm machine={{id: 4, name: 'VE', params: null}}
                                            shift={{
                                                id: userReports[0].shift_id,
                                                name: getShiftName(userReports[0].shift_id)
                                            }}
                                            report_date={userReports[0].report_date}
                                            viewMode={true}
                                            onReload={() => {
                                                setShowReports(null)
                                            }}/>


                    }
                    {
                        userReports[0].machine_id === 5 &&
                        <InputPackageMachineForm machine={{id: 5, name: 'Стъклопакет', params: null}}
                                                 shift={{
                                                     id: userReports[0].shift_id,
                                                     name: getShiftName(userReports[0].shift_id)
                                                 }}
                                                 report_date={userReports[0].report_date}
                                                 viewMode={true}
                                                 onReload={() => {
                                                     setShowReports(null)
                                                 }}/>


                    }
                    {
                        userReports[0].machine_id === 6 &&
                        <InputOvenMachineForm machine={{id: 6, name: 'Голяма печка', params: null}}
                                              shift={{
                                                  id: userReports[0].shift_id,
                                                  name: getShiftName(userReports[0].shift_id)
                                              }}
                                              report_date={userReports[0].report_date}
                                              viewMode={true}
                                              onReload={() => {
                                                  setShowReports(null)
                                              }}/>


                    }
                    {
                        userReports[0].machine_id === 7 &&
                        <InputOvenMachineForm machine={{id: 7, name: 'Малка печка', params: null}}
                                              shift={{
                                                  id: userReports[0].shift_id,
                                                  name: getShiftName(userReports[0].shift_id)
                                              }}
                                              report_date={userReports[0].report_date}
                                              viewMode={true}
                                              onReload={() => {
                                                  setShowReports(null)
                                              }}/>


                    }
                    {
                        userReports[0].machine_id === 8 &&
                        <InputLaminationMachineForm machine={{id: 8, name: 'Ламиниране', params: null}}
                                                    shift={{
                                                        id: userReports[0].shift_id,
                                                        name: getShiftName(userReports[0].shift_id)
                                                    }}
                                                    report_date={userReports[0].report_date}
                                                    viewMode={true}
                                                    onReload={() => {
                                                        setShowReports(null)
                                                    }}/>


                    }
                    {
                        userReports[0].machine_id === 10 &&
                        <InputVertecMachineForm machine={{id: 10, name: 'Vertec', params: null}}
                                                shift={{
                                                    id: userReports[0].shift_id,
                                                    name: getShiftName(userReports[0].shift_id)
                                                }}
                                                report_date={userReports[0].report_date}
                                                viewMode={true}
                                                onReload={() => {
                                                    setShowReports(null)
                                                }}/>


                    }
                    {
                        userReports[0].machine_id === 11 &&
                        <InputOtherActivitiesForm machine={{id: 11, name: 'Други дейности', params: null}}
                                                shift={{
                                                    id: userReports[0].shift_id,
                                                    name: getShiftName(userReports[0].shift_id)
                                                }}
                                                report_date={userReports[0].report_date}
                                                viewMode={true}
                                                onReload={() => {
                                                    setShowReports(null)
                                                }}/>


                    }
                </>
            }
        </>
    )
}

import React, {forwardRef} from "react";
import bg from "date-fns/locale/bg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import {Col, Form, FormGroup, Row} from "react-bootstrap";
import ReactDatePicker, {registerLocale} from "react-datepicker";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

export interface ReadOnlyDatePickerProps {
    classes?: string
    onChange: any;
    value: string;
    hideIcon?: boolean;
    hideDayControls?: boolean;
}

// @ts-ignore
const ExampleCustomInput = forwardRef(
// @ts-ignore
    ({value, onClick}, ref: any) => (
        <button className="form-control w-100 text-left" onClick={onClick} ref={ref} type={"button"}>
            {value}
        </button>
    ),
);


export const ReadOnlyDatePickerComponent = function (props: ReadOnlyDatePickerProps) {
    const handleChange = (date: any, e: any) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }

        const {onChange} = props;

        if (onChange) {
            onChange(date);
        }
    };

    const addDay = () => {
        handleChange(moment(props.value, 'DD.MM.YYYY').add(1, 'day').toDate(), null);
    }
    const subDay = () => {
        handleChange(moment(props.value, 'DD.MM.YYYY').subtract(1, 'day').toDate(), null);
    }

    registerLocale("bg", bg);
    return (
        <Row className={"flex-nowrap m-0 p-0"}>
            <Col className={"mr-0 pr-0 " + (props.classes || "")}>
                <FormGroup>
                    <Form.Label>
                        <Row className={"flex-nowrap"}>
                            <Col className={"p-0"} style={{zIndex: 1001}}>
                                <ReactDatePicker
                                    locale="bg"
                                    className="w-100"
                                    id="selectedDate"
                                    value={props.value}
                                    onChange={handleChange}
                                    customInput={<ExampleCustomInput/>}
                                    shouldCloseOnSelect={true}
                                    todayButton={true}
                                />
                            </Col>
                            {
                                !props.hideIcon &&
                                <Col sm={"auto"} className={"ml-0 pl-0"}>
                                    <span className={"btn btn-sm btn-secondary ml-0"}><FontAwesomeIcon icon={faCalendarAlt}
                                                                                                       size="lg"/></span>
                                </Col>
                            }
                        </Row>


                    </Form.Label>
                </FormGroup>


            </Col>

            {
                !props.hideDayControls &&
                <div className={"col-auto"}>
                    <a className={'btn btn-secondary btn-sm'} onClick={() => subDay()}>&#8882;</a>
                    <a className={'btn btn-secondary btn-sm'} onClick={() => addDay()}>&#8883;</a>
                </div>
            }
        </Row>
    )
}

import {AxiosResponse} from "axios";
import {Moment} from "moment";

export interface API_CALL {
    status: NET_STATUS;
    message?: string;
    data: AxiosResponse<any>;
    error: any;
    onChange: any;
}

export enum NET_STATUS {
    'NOT_STARTED',
    'LOADING',
    'SUCCESS',
    'ERROR'
}

export enum PARAMETERS {
    'SHIFTS' = 2,
    'PELET_TYPE' = 3,
    'BAG_TYPE' = 4,
    'FADROMI' = 5,
    'MOTOCARI' = 6,
    'BRICKS' = 7,
    'EXTRUDER_REASONS' = 8,
    'ROBOT_REASONS' = 9,
    'WASTE_REASONS' = 10,
    'NOT_GOOD_REASONS' = 11,
    'EXTR_WASTE_REASONS' = 12,
    'CLAY_REASONS' = 13,
    'CLIENTS' = 14,
}

export enum SALE_TYPE {
    'bricks_w_env'= 'тухли с фактура',
    'bricks_wo_env' = 'тухли с касов бон',
    'peleti_w_env' = 'пелети с фактура',
    'peleti_wo_env' = 'пелети с касов бон',
    'others' = 'други',
}

export enum PAY_TYPE {
    'BANKA' = 'BANKA',
    'CASH' = 'CASH',
    'CREDIT' = 'CREDIT',
    'PALET' = 'PALET',
}

export interface UserModule {
    access_rights: 'NONE' | 'READ' | 'WRITE',
    app_modules_id: number,
    module: AppModule
}

export enum CALNDAR_MODE {
    'MACHINES',
    'ADMISITRATIVE'
}

export const MONTHS_NAMES: string[] = ['Януари', 'Февруари', 'Март', 'Април', 'Май', 'Юни', 'Юли', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември'];


export interface AppModule {
    id: number,
    name: string,
    url: string
    top_modul?: number,
    owner?: number,
    group_name: string,
    order_pos: number,
    deleted_at?: number
}

export interface IdName {
    id: number,
    name: string
}

export interface Glass extends IdName {
    oven_type: string;
}
export interface GlassSize {
    id: number;
    width: number;
    height: number;
    area: number;
    name: string;
}

export interface Shift extends IdName {
}

export const EMPTY_SHIFT: Shift = {
    id: -1, name: ''
}

export interface Account extends IdName{
    email: string;
    machine_id: number;
    department: string;
    position: string;
    is_admin: string;
    is_operator: string;
    is_super_operator: string;
    is_employee: string;
    employee_type: 0 | 1; // 0 - помощник; 1 - оператор
    modules: UserModule[];
    data: any[];
    isNewPassword: boolean;
    password: string;
    active_to: string;
    isNomenclature: number | boolean;
}

export const EMPTY_ACCOUNT: Account = {
    id: -1, name: '', email: '', machine_id: -1, position: '', department: '', is_admin: 'N', is_operator: 'N', is_super_operator: 'N',
    is_employee: 'N', employee_type: 0, modules: [], data: [], isNewPassword: false, password: '', active_to: '', isNomenclature: false
}

export interface Team extends IdName{
    users: string;
    usersList: Worker[];
}


export interface Worker extends IdName{
    workHours: number | null | undefined;
    workMinutes: number | null | undefined;
}

export interface WorkerActivity extends IdName{
}

export interface Order{
    id: string;
    value: number;
    dim: string;
}

export interface Incident{
    from: string;
    to: string;
    description: string;
    comment: string;
}
export const EMPTY_INCIDENT = {
    from: '', to: '', description: '', comment: ''
}

export interface Machine {
    id: number;
    name: string;
    params: any;
}
export const EMPTY_MACHINE: Machine = {
    id: -1, name: '', params: []
}

export interface MachineIncident {
    id: number;
    incident_type: 'INCIDENT' | 'STOP' | 'REPAIR';
    name: string;
    machine_id: number;
}
export const EMPTY_MACHINE_INCIDENT: MachineIncident = {
    id: -1, name: '', machine_id: -1, incident_type: 'INCIDENT'
}

export interface PropertyValue {
    id: number,
    value: string,
    description: any,
}

export enum PropertyDescriptorType {
    string = "string",
    number = "number",
    boolean = "boolean",
    date = "date",
    timestamp = "timestamp",
}

export interface PropertyDescriptor {
    name: string,
    title: string,
    type: PropertyDescriptorType,
}

export interface Property extends IdName{
    valuesJSON: string,
    descriptorJSON: string,
    values: PropertyValue[],
    descriptor: PropertyDescriptor[],
}

export interface Pelet {
    id: number,
    value: string
}

export interface Bag {
    id: number,
    value: string
}

export interface Brick {
    id: number,
    value: string
}

export interface Charging {
    chargeTime: string,
    pelet: number,
    bagType: number,
    bagQty: number,
    notes: string
}

export interface OvenProduction {
    brick_id: number,
    wagon_qty: number,
}
export interface OvenTemp {
    wagon: number,
    time: string,
    temp_1_min: number,
    temp_1_max: number,
    temp_2_min: number,
    temp_2_max: number,
    temp_3_min: number,
    temp_3_max: number,
    temp_4_min: number,
    temp_4_max: number,
    temp_5_min: number,
    temp_5_max: number,
    temp_6_min: number,
    temp_6_max: number,
}

export interface Repair {
    id?: number,
    repairType: REPAIR_TYPE,
    fromTime: string,
    toTime: string,
    comments: string
}


export interface BaseReportInfo {
    report_date: string;
    shift_id: number;
    from_time: string;
    to_time: string;
    man_cnt: number;
    comments: string;
}


export interface Report {
    id: number;
    created_by: Account;
    machine: Machine;
    report_date: string;
    shift: Shift;
    man_cnt: number;
    workHours: number;
    request_data: string;
}

export const EMPTY_REPORT: Report = {
    id: -1,
    created_by: EMPTY_ACCOUNT,
    machine: EMPTY_MACHINE,
    report_date: '',
    man_cnt: 0,
    shift: EMPTY_SHIFT,
    workHours: 8,
    request_data: '',
}


export interface HeaterReport extends Report{
    heater_number: number,
    cleanups: string[],
    chargings: Charging[],
}

export interface OrderingReport extends Report {
}

export interface ExtruderReport extends Report{
    production: ExtruderProduction[],
    mushtukCorrections: ExtruderMushtukCorrection[],
}

export enum REPAIR_TYPE {
    'HEATER'= 'Печка',
    'GRINDER'='Мелене',
    'EXTRUDER'='Екструдер',
    'ORDERING'='Редене',
    'OVEN'='Пещ',
    'UNLOADING'='Разтоварване',
    'DRIER'='Сушилня',
}

export interface RepairsReport extends Report{
    repair_type: REPAIR_TYPE,
    repairs: Repair[],
}

export interface OvenReport extends Report{
    temps: OvenTempsRecord[],
    chargings: Charging[],
    heater_chargings: Charging[],
    production: OvenProduction[],
}


export interface GrindignLoading {
    id: number,
    loader_type: LoaderType,
    loaderIdx: number,
    qty: number
}

export enum LoaderType {
    'FADROMA' = 'FADROMA', 'MOTOCAR' = 'MOTOCAR'
}

export interface ExtruderQuantity {
    brick1: Brick,
    brick2: Brick,
    wagonsQty: number,
    wasteQty: number,
    reason1: PropertyValue,
    reason1_percent: number,
    reason2: PropertyValue,
    reason2_percent: number,
    reason3: PropertyValue,
    reason3_percent: number,
}

export interface ExtruderProduction {
    time: string,
    brick: Brick,
    vacuum: number,
    pressure_from: number,
    pressure_to: number,
    wagonsQty: number,
    waste: number,
    reason1: number,
    reason1_percent: number,
    reason2: number,
    reason2_percent: number,
    reason3: number,
    reason3_percent: number,
}

export interface OvenTempsRecord {
    time: string,
    wagonNum: number,
    temp_1_1: number,
    temp_1_2: number,
    temp_2_1: number,
    temp_2_2: number,
    temp_3_1: number,
    temp_3_2: number,
    temp_4_1: number,
    temp_4_2: number,
    temp_5_1: number,
    temp_5_2: number,
    temp_6_1: number,
    temp_6_2: number,
}

export interface ExtruderMushtukCorrection {
    time: string,
    description: string,
}

export interface ExtruderReport {

}

export interface Sale {
    id: number | null,
    sale_type: SALE_TYPE | null,
    sales_number: number | null,
    sales_date:	Moment | null,
    client_id: number | null,
    client: string | null,
    client_city: string | null,
    returned: number | null,
    driver: string | null,
    driver_phone: string | null,
    license_plate: string | null,
    store_manager: string | null,
    currier: string | null,
    loader_name: string | null,
    items?: SaleItem[] | null,
    price: number | null,
    incl_logistic: boolean | null,
    inv_number: string | null,
    pay_type: PAY_TYPE | null,
    inv_price: number | null,
    inv_payed: number | null,
    payed_by_avans: boolean | number,
    payed_by_credit: boolean | number,
    request_data: string | null,
}


export interface Logistic {
    id: number,
    name: string,
    drivers: Driver[];
}

export interface Driver {
    name: string;
    phone: string;
    reg_nomer: string;
}

export interface Client {
    id: number,
    name: string,
    cities: City[];
    balance_bank: number,
    balance_cash: number,
    balance_credit: number,
    balance_palet: number,
}

export interface City {
    city: string;
    phone: string;
}

export interface AccountOperation {
    id: number;
    sales_id: number;
    timestamp: string;
    account_type: PAY_TYPE;
    operation: OPERATION_TYPE;
    description: string;
    amount: number;
}

export enum OPERATION_TYPE {
    DEPOSIT = 'DEPOSIT',
    WITHDRAW = 'WITHDRAW'
}

export interface SaleItem {
    id: number,
    sales_id: number,
    property_id: number,
    bag_type: number,
    palet_qty: number,
    qty_in_palet: number,
    pelet_type: number,
    bag_qty: number,
    total: number
}

export interface Standarts {
    grinding_bucket_clay_kg: number;
    grinding_bucket_sludge_kg: number;

}

export interface CutMachineOtherActivity {
    id: number;
    title: string
    dimens: string
}

export interface CutMachineOtherActivityValue {
    id: number;
    value: number;
}

export interface MachineClearing {
    firstShift: number;
    secondShift: number;
    regularShift: number;
}

export const EMPTY_MACHINE_CLEARING: MachineClearing = { firstShift: 0, secondShift: 0, regularShift: 0 };

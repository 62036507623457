import ModalHeader from "react-bootstrap/ModalHeader";
import {
    Button,
    CloseButton,
    Col,
    Modal,
    ModalBody,
    ModalTitle,
    Row, Spinner, Tab,
    Table, Tabs
} from "react-bootstrap";
import {
    isError,
    isLoading,
    isSuccess,
    minutesToHours,
    showErrorsInToast,
    translateError,
    zeroToEmptyStringAsCurrency,
    zeroToEmptyStringAsNumber,
} from "../../common";
import React, {useEffect, useState} from "react";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../../api";
import {Account, API_CALL, Machine} from "../../types";
import moment from "moment";
import {SelectMonthYear} from "../../common/SelectMonthYear";
import ExportButton from "../../common/ExportButton";


export interface OutputIncidentsModalProps {
    onHide: () => any;
}


export default function OutputIncidentsModal({onHide}: OutputIncidentsModalProps) {

    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState<any>(null);

    const [month, setMonth] = useState(moment().month());
    const [year, setYear] = useState(moment().year());
    const [monthDays, setMonthDays] = useState(30);
    const [machines, setMachines] = useState<Array<Machine>>([]);
    const [users, setUsers] = useState<Array<Account>>([]);

    const DAYS_IN_MONTH = moment().day(1).month(month).year(year).daysInMonth();

    useEffect(() => {
        setMonth(moment().month());
        setYear(moment().year());
    }, [])

    useEffect(() => {
        setMonthDays(DAYS_IN_MONTH);
        loadData()
    }, [month, year, DAYS_IN_MONTH])


    const loadData = () => {

        setReport(null);
        setMachines([]);

        if (year < 2020) return;

        setLoading(true);
        let _report: any = null;

        API.getQuantitiesReport(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setMachines(apiCall.data.data.machines);
                    setUsers(apiCall.data.data.users);
                    setReport(apiCall.data.data.report);

                    // API.getSalaryReport(
                    //     (apiCall: API_CALL) => {
                    //
                    //         if (isSuccess(apiCall)) {
                    //
                    //             const cfg = apiCall.data.data.salary.config;
                    //             const sal = apiCall.data.data.salary.data;
                    //
                    //             Object.keys(sal).map(
                    //                 (userId: string) => {
                    //                     const machineId = users.find(u => u.id.toString() === userId)?.machine_id || -1;
                    //
                    //                     if(_report[machineId]) {
                    //                         _report[machineId]['Всичко'][monthDays+1] += sal[userId].totalMH;
                    //                         _report[machineId]['Всичко'][monthDays+2] += sal[userId].sallary;
                    //                     }
                    //                 }
                    //             )
                    //
                    //             setReport({..._report});
                    //             setLoading(false);
                    //         }
                    //
                    //         if (isError(apiCall)) {
                    //             showErrorsInToast(apiCall, 'ГРЕШКА', translateError);
                    //         }
                    //     }, month, year
                    // );
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'ГРЕШКА', translateError);
                }
            }, month, year
        );
    }

    function TableHeader() {
        return (
            <thead>
            <tr>
                <th>Машина</th>
                {
                    Array(monthDays).fill(1)
                        .map((k, idx) => {
                            return (<th key={idx}
                                        className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}
                            >{(idx + 1).toString().padStart(2, '0')}</th>)
                        })

                }
                <th className={"text-right"}>Всичко</th>
                {/*<th className={"text-right"}>ЧЧ</th>*/}
                {/*<th className={"text-right"}>Заплати</th>*/}
            </tr>
            </thead>
        )
    }

    function getMachineName(id: number) {
        let name = '';
        machines.forEach(i => {
            if (i.id === id) {
                name = i.name;
            }
        })

        let me = '';
        switch (name) {
            case 'Рязане': me = ' (кв.м. с.)'; break;
            case 'VX': me = ' (л.м.)'; break;
            case 'Profile': me = ' (л.м.)'; break;
            case 'VE': me = ' (л.м.)'; break;
            case 'Стъклопакет': me = ' (кв.м.)'; break;
            case 'Голяма печка': me = ' (кв.м.)'; break;
            case 'Малка печка': me = ' (кв.м.)'; break;
            case 'Ламиниране': me = ' (кв.м.)'; break;
        }

        return name.toUpperCase() + me;
    }

    function Machine({machineId}: { machineId: number }) {
        if (!report || !report[machineId]) return null;

        // console.log(monthDays);
        // console.log(report);

        return (
            <>
                <tr>
                    <td colSpan={monthDays + 4}></td>
                </tr>
                <tr className={"bg-secondary text-light"} style={{fontSize: "14px"}}>
                    <th>{getMachineName(machineId)}</th>
                    {
                        report[machineId]['Всичко'].map(
                            (d: any, idx: any) => <td key={machineId + '_total_' + idx}
                                                      className={"text-right"}>{zeroToEmptyStringAsNumber(d.toFixed(2))}</td>
                        )
                    }
                </tr>
                <tr>
                    <td>Първа смяна</td>
                    {
                        report[machineId][1].map(
                            (d: any, idx: any) => <td key={machineId + '_1_' + idx}
                                                      className={"text-right"}>{zeroToEmptyStringAsNumber(d.toFixed(2))}</td>
                        )
                    }
                </tr>
                <tr>
                    <td>Втора смяна</td>
                    {
                        report[machineId][2].map(
                            (d: any, idx: any) => <td key={machineId + '_2_' + idx} className={"text-right"}>{zeroToEmptyStringAsNumber(d.toFixed(2))}</td>
                        )
                    }
                </tr>
                <tr>
                    <td>Редовна смяна</td>
                    {
                        report[machineId][3].map(
                            (d: any, idx: any) => <td key={machineId + '_3_' + idx} className={"text-right"}>{zeroToEmptyStringAsNumber(d.toFixed(2))}</td>
                        )
                    }
                </tr>
            </>
        )
    }

    return (
        <>
            <Modal onHide={() => onHide()} show={true} dialogClassName={"w-100 p-3 max-w-none"}>
                <ModalHeader>
                    <Row className={"w-100"}>
                        <Col>
                            <ModalTitle>Количествени справки</ModalTitle>
                        </Col>
                        <Col className={"justify-content-end"} xs={"auto"}>
                            <Row className={"flex-nowrap"}>
                                <Col>
                                    <SelectMonthYear month={month} year={year} onChange={
                                        (month, year) => {
                                            setMonth(month);
                                            setYear(year);
                                        }
                                    }/>
                                </Col>
                                <Col>
                                    <Button onClick={() => loadData()} variant={"secondary"} size="sm" className={"mr-1"}
                                            title={"Презареди таблицата"}><FontAwesomeIcon
                                        icon={faSyncAlt}/></Button>

                                    <ExportButton  table={"dataTable"} filename={"report-quantity-" +
                                        + (month+1).toString().padStart(2, '0') + '-' + year.toString()} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={"auto"}>
                            <CloseButton onClick={() => onHide()}/>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody style={{maxHeight: '70vh', overflow: 'auto'}}>
                    {
                        loading && <Spinner animation={"border"}/>
                    }
                    {
                        !loading && report && machines &&
                        <div className={"table-responsive"} style={{maxHeight: '65vh'}}>
                            <Table striped bordered hover size={"sm"} className={"small"}
                                   style={{height: '100%'}}
                                   id="dataTable">
                                <TableHeader/>
                                <tbody>
                                {
                                    machines.map(
                                        m => <Machine machineId={m.id} key={m.id}/>
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                    }
                </ModalBody>
            </Modal>
        </>
    )
}
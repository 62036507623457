import {Glass} from "../../../types";

export interface GlassNameComponentProps {
    glasses: Glass[];
    glassId: number|null;
}

export default function GlassNameComponent({glasses, glassId}: GlassNameComponentProps) {
    if(glasses.length > 0) {
        if(glassId) {
            const g = glasses.find(i => i.id === glassId);
            if(g) return (<>{g.name}</>)
        }
    }
    return null
}
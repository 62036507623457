import ModalHeader from "react-bootstrap/ModalHeader";
import {
    Button,
    CloseButton,
    Col,
    Modal,
    ModalBody,
    ModalTitle,
    Row, Spinner, Tab,
    Table, Tabs
} from "react-bootstrap";
import {
    isError,
    isLoading,
    isSuccess, minutesToHours, showErrorsInToast, translateError, zeroToEmptyStringAsCurrency,
} from "../../common";
import React, {useEffect, useState} from "react";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../../api";
import {API_CALL, Machine} from "../../types";
import moment from "moment";
import {SelectMonthYear} from "../../common/SelectMonthYear";
import ExportButton from "../../common/ExportButton";


export interface OutputIncidentsModalProps {
    onHide: () => any;
}

interface CommentMachine {
    id: string;
    name: string;
}


export default function OutputIncidentsModal({onHide}: OutputIncidentsModalProps) {

    const [loading, setLoading] = useState(false);
    const [incidents, setIncidents] = useState<any>(null);
    const [iComments, setIComments] = useState<any>(null);

    const [stops, setStops] = useState<any>(null);
    const [sComments, setSComments] = useState<any>(null);

    const [repairs, setRepairs] = useState<any>(null);
    const [rComments, setRComments] = useState<any>(null);

    const [report, setReport] = useState<any>(null);
    const [comments, setComments] = useState<any>(null);
    const [refModeKey, setRefModeKey] = useState<string>("times");
    const [activeTab, setActiveTab] = useState<string>("Аварии");
    const [cActiveTab, setCActiveTab] = useState<string>("-1");
    const [commentsTabs, setCommentsTabs] = useState<CommentMachine[]>([{id: "-1", name: "Всички"}]);


    const [month, setMonth] = useState(moment().month());
    const [year, setYear] = useState(moment().year());
    const [monthDays, setMonthDays] = useState(30);
    const [machines, setMachines] = useState<Array<Machine>>([]);

    useEffect(() => {
        setMonth(moment().month());
        setYear(moment().year());
    }, [])

    useEffect(() => {
        setActiveTab("Аварии");
        const DAYS_IN_MONTH = moment().day(1).month(month).year(year).daysInMonth();
        setMonthDays(DAYS_IN_MONTH);
        loadData()
    }, [month, year])


    const loadData = () => {
        setReport(null);
        setMachines([]);

        if (year < 2020) return;

        API.getIncidentsReport(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setIncidents(apiCall.data.data.report);
                    setIComments(apiCall.data.data.comments);
                    setMachines(apiCall.data.data.machines);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'ГРЕШКА', translateError);
                }
            }, month, year
        );

        API.getStopsReport(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setStops(apiCall.data.data.report);
                    setSComments(apiCall.data.data.comments);
                    setMachines(apiCall.data.data.machines);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'ГРЕШКА', translateError);
                }
            }, month, year
        );

        API.getRepairsReport(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setRepairs(apiCall.data.data.report);
                    setRComments(apiCall.data.data.comments);
                    setMachines(apiCall.data.data.machines);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'ГРЕШКА', translateError);
                }
            }, month, year
        );
    }

    useEffect(() => {
        if (activeTab === 'Аварии' && incidents) {
            setReport(incidents);
        }

        if (activeTab === 'Други спирания' && stops) {
            setReport(stops);
        }

        if (activeTab === 'Планирани ремонти' && repairs) {
            setReport(repairs);
        }

        if (activeTab === "Коментари") {
            const _comments = {...iComments};
            const _sComments = {...sComments};
            const _rComments = {...rComments};

            const cTabs: Array<CommentMachine> = [{id: "-1", name: "Всички"}];

            Object.keys(_sComments).forEach(
                d => {
                    if (_comments[d]) {
                        Object.keys(_sComments[d]).forEach(
                            id => {

                                if (!_comments[d][id]) {
                                    _comments[d][id] = _sComments[d][id];
                                }
                            }
                        )
                    } else {
                        _comments[d] = _sComments[d];
                    }
                }
            );

            Object.keys(_rComments).forEach(
                d => {
                    if (_comments[d]) {
                        Object.keys(_rComments[d]).forEach(
                            id => {
                                if (!_comments[d][id]) {
                                    _comments[d][id] = _rComments[d][id];
                                }
                            }
                        )
                    } else {
                        _comments[d] = _rComments[d];
                    }
                }
            );

            Object.keys(_comments).forEach(
                dt => Object.keys(_comments[dt]).forEach(
                    mId => {
                        const name = getMachineName(mId);
                        if (name) {
                            let idx = 0;
                            for (idx = 0; idx < cTabs.length; idx++) {
                                if (cTabs[idx].id.toString() === mId) break;
                            }
                            if (idx === cTabs.length) {
                                cTabs.push({id: mId, name: name});
                            }
                        }
                    }
                )
            )

            let _c: any = [];
            if (cActiveTab === "-1") _c = {..._comments}
            else {
                Object.keys(_comments).map(
                    d => {
                        Object.keys(_comments[d]).map(
                            mId => {
                                if (mId === cActiveTab) {
                                    if (!_c[d]) _c[d] = [];
                                    _c[d][mId] = _comments[d][mId];
                                }
                            }
                        )
                    }
                )
            }

            setCommentsTabs(cTabs);
            setComments({..._c});
        }

    }, [activeTab, cActiveTab, incidents, stops, repairs, machines, iComments, sComments, rComments, loading]);

    function TableHeader() {
        return (
            <thead>
            <tr>
                <th>Авария</th>
                <th className={"text-center"}>Всичко</th>
                {
                    Array(monthDays).fill(1)
                        .map((k, idx) => {
                            return (<th key={idx}
                                        className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}
                            >{(idx + 1).toString().padStart(2, '0')}</th>)
                        })

                }
            </tr>
            </thead>
        )
    }


    function getMachineName(id: string) {
        let name = '';
        machines.forEach(i => {
            if (i.id.toString() == id) {
                name = i.name;
            }
        })

        return name;
    }


    function getDefaultWh(wh: number) {
        return (wh <= 0) ? 480 : wh
    }

    function Machine({machineId}: { machineId: string }) {
        if (!report || !report[machineId] || machineId === "comments") return null;

        let wH = 0;
        for (let i = 0; i < monthDays; i++) {
            wH += report[machineId]['Всичко']['work'][i];
        }

        const incidents = Object.keys(report[machineId]).filter(i => i !== 'comments');
        incidents.forEach(
            (incident: any, idx: number) => {
                let total = 0;
                for (let i = 0; i < monthDays; i++) {
                    total += report[machineId][incident]['inc'][i];
                }
                report[machineId][incident]['Всичко'] = [];
                report[machineId][incident]['Всичко']['inc'] = total;
                const totalwH = (total / wH * 100);
                report[machineId][incident]['Всичко']['perc'] = isFinite(totalwH) ? zeroToEmptyStringAsCurrency(totalwH.toString(), '%') : '';
            }
        );

        return (
            <>
                <tr>
                    <td colSpan={monthDays + 2}></td>
                </tr>
                <tr className={"bg-secondary text-light"} style={{fontSize: "14px"}}>
                    <th>{getMachineName(machineId).toUpperCase()}</th>
                    <th className={"text-center"}>{minutesToHours(report[machineId]['Всичко']['Всичко']['inc'], 'ч')}<br/>
                        {report[machineId]['Всичко']['Всичко']['perc']}</th>
                    {
                        Array(monthDays).fill(1)
                            .map((k, idx) => {
                                return (
                                    <th key={idx}
                                        className={"text-center"}>{minutesToHours(report[machineId]['Всичко']['inc'][idx], 'ч')}<br/>
                                        {
                                            zeroToEmptyStringAsCurrency((report[machineId]['Всичко']['inc'][idx] /
                                                getDefaultWh(report[machineId]['Всичко']['work'][idx]) * 100).toString(), '%')
                                        }
                                    </th>
                                )
                            })
                    }
                </tr>
                {
                    incidents.filter(i => i !== 'Всичко')
                        .map(
                            (d: any, idx) => <tr key={report[d] + '_' + idx}>
                                <td>{d}</td>
                                <td className={"text-center bg-secondary text-light"}>
                                    {minutesToHours(report[machineId][d]['Всичко']['inc'], 'ч')}<br/>
                                    {report[machineId][d]['Всичко']['perc']}
                                </td>
                                {
                                    Array(monthDays).fill(1).map(
                                        (day: any, idx1: number) =>
                                            <td key={report[d] + '_' + idx + '_' + idx1} className={"text-center"}>
                                                {minutesToHours(report[machineId][d]['inc'][idx1], 'ч')}<br/>
                                                {
                                                    zeroToEmptyStringAsCurrency((report[machineId][d]['inc'][idx1] /
                                                        getDefaultWh(report[machineId]['Всичко']['work'][idx1]) * 100).toString(), '%')
                                                }
                                            </td>
                                    )

                                }
                            </tr>
                        )
                }
            </>
        )
    }


    function MachineList({machineId}: { machineId: string }) {
        if (!report || !report[machineId] || machineId === "comments") return null;

        return (
            <>
                <tr>
                    <td colSpan={5}></td>
                </tr>
                <tr className={"bg-secondary text-light"} style={{fontSize: "14px"}}>
                    <th colSpan={5}>{getMachineName(machineId).toUpperCase()}</th>
                </tr>
                {
                    Object.keys(report[machineId]['comments'])?.sort()
                    .map(
                        (d: any, idx) => <>
                            {
                                report[machineId]['comments'][d].map(
                                    (i: any, idx1: number) => <tr key={report[d] + '_' + idx}>
                                        <td>{d}</td>
                                        <td>{i.from}</td>
                                        <td>{i.to}</td>
                                        <td>{i.description}</td>
                                        <td>{i.comment}</td>
                                    </tr>
                                )
                            }
                        </>
                    )
                }
            </>
        )
    }

    function TotalRow() {
        let total = 0;
        let wH = 0;
        for (let i = 0; i < monthDays; i++) {
            total += report['Всичко']['inc'][i];
            wH += report['Всичко']['work'][i];
        }

        return (
            <>
                <tr>
                    <th colSpan={monthDays + 2}></th>
                </tr>
                <tr className={"bg-primary-light"} style={{fontSize: "15px"}}>
                    <th>Всичко</th>
                    <th className={"text-center"}>
                        {minutesToHours(total, 'ч')}<br/>
                        {
                            zeroToEmptyStringAsCurrency((total / wH * 100).toString(), '%')
                        }
                    </th>
                    {
                        Array(monthDays).fill(1)
                            .map((k, idx) => {
                                return (<th key={idx}
                                            className={"text-center " + ((idx % 2 === 0 ? "bg-secondary-light" : ""))}
                                >
                                    {minutesToHours(report['Всичко']['inc'][idx], 'ч')}<br/>
                                    {
                                        zeroToEmptyStringAsCurrency((report['Всичко']['inc'][idx] /
                                            report['Всичко']['work'][idx] * 100).toString(), '%')
                                    }
                                </th>)
                            })
                    }
                </tr>
            </>
        )
    }

    const shouldRender = function (comment: any) {
        const machines = comment ? Object.keys(comment) : [];
        let mCnt = machines.length;
        //
        // if (cActiveTab === "-1") mCnt = machines.length
        // else {
        //     machines.forEach(
        //         m => {
        //             if(m === cActiveTab) mCnt++;
        //         }
        //     )
        // }

        return mCnt;
    }

    return (
        <>
            <Modal onHide={() => onHide()} show={true} dialogClassName={"w-100 p-3 max-w-none"}>
                <ModalHeader>
                    <Row className={"w-100"}>
                        <Col>
                            <ModalTitle>Справка аварии</ModalTitle>
                        </Col>
                        <Col className={"justify-content-end"} xs={"auto"}>
                            <Row className={"flex-nowrap"}>
                                <Col>
                                    <SelectMonthYear month={month} year={year} onChange={
                                        (month, year) => {
                                            setMonth(month);
                                            setYear(year);
                                        }
                                    }/>
                                </Col>
                                <Col>
                                    <Button onClick={() => loadData()} variant={"secondary"} size="sm"
                                            title={"Презареди таблицата"}><FontAwesomeIcon
                                        icon={faSyncAlt}/></Button>

                                    <ExportButton tab={activeTab} pill={refModeKey} suffix={month.toString() + '.' + year.toString()} />

                                </Col>
                            </Row>
                        </Col>
                        <Col xs={"auto"}>
                            <CloseButton onClick={() => onHide()}/>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody style={{maxHeight: '70vh', overflow: 'auto'}}>
                    {
                        loading && <Spinner animation={"border"}/>
                    }
                    {
                        !loading &&
                        <div className={"table-responsive"} style={{maxHeight: '65vh'}}>
                            <Row className={"w-100"}>
                                <Col xs={12}>
                                    <Tabs activeKey={activeTab}
                                          onSelect={(e) => setActiveTab(e || "Аварии и спирания")}>
                                        <Tab title={"Аварии"} eventKey={"Аварии"}/>
                                        <Tab title={"Други спирания"} eventKey={"Други спирания"}/>
                                        <Tab title={"Планирани ремонти"} eventKey={"Планирани ремонти"}/>
                                        <Tab title={"Коментари"} eventKey={"Коментари"}/>
                                    </Tabs>
                                </Col>
                            </Row>

                            {
                                <>
                                    {
                                        activeTab !== "Коментари" && report &&
                                        <div className="w-100 overflow-auto"
                                             style={{maxHeight: '60vh'}}>
                                            <Tabs defaultActiveKey={"times"} variant={"pills"} activeKey={refModeKey}
                                                  onSelect={(k) => setRefModeKey(k || "times")}
                                                  className={"mt-2 mb-2"}
                                            >
                                                <Tab title={"Времена"} eventKey={"times"}>
                                                    <Table striped bordered hover size={"sm"} className={"small"}
                                                           style={{height: '100%'}}
                                                           id="tableTimes">
                                                        <TableHeader/>
                                                        <tbody>
                                                        <TotalRow/>
                                                        {
                                                            Object.keys(report).filter(i => i !== 'Всичко').map(
                                                                m => <Machine machineId={m} key={m}/>
                                                            )
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </Tab>

                                                <Tab title={"Списък"} eventKey={"list"}>
                                                    <Table striped bordered hover size={"sm"} className={"small"}
                                                           style={{height: '100%', marginTop: "1rem"}}
                                                           id="tableList">
                                                        <thead>
                                                        <tr>
                                                            <th>Ден</th>
                                                            <th>От час</th>
                                                            <th>До час</th>
                                                            <th>Група</th>
                                                            <th>Описание</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            Object.keys(report).filter(i => i !== 'Всичко').map(
                                                                m => <MachineList machineId={m} key={m}/>
                                                            )
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    }

                                    {
                                        activeTab === "Коментари" && comments &&
                                        <div className="w-100 overflow-auto" style={{maxHeight: '60vh'}}>
                                            <Tabs defaultActiveKey={'Всички'} variant={"pills"} className={"mt-2 mb-2"}
                                                  activeKey={cActiveTab}
                                                  onSelect={(e) => setCActiveTab(e || commentsTabs[0].id)}
                                            >
                                                {
                                                    commentsTabs.map(
                                                        t => <Tab key={t.id} eventKey={t.id} title={t.name}/>
                                                    )
                                                }
                                            </Tabs>
                                            <Table bordered hover size={"sm"} className={"small"}
                                                   style={{height: '100%'}}
                                                   id="tableComments">
                                                <thead>
                                                <tr>
                                                    <th>Дата</th>
                                                    <th>Машина</th>
                                                    <th>Коментари</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    comments &&
                                                    Object.keys(comments)
                                                        .sort((d1, d2) => d1 > d2 ? 1 : (d1 < d2 ? -1 : 0))
                                                        .map((day, idx) => {
                                                            const mCnt = shouldRender(comments[day]);
                                                            return <>
                                                                {
                                                                    mCnt > 0 &&
                                                                    <>
                                                                        <tr key={day}>
                                                                            <td rowSpan={mCnt}
                                                                                className={"w-100px"}>{day}</td>
                                                                            <td style={{
                                                                                maxWidth: "200px",
                                                                                minWidth: "150px"
                                                                            }}>{
                                                                                getMachineName(Object.keys(comments[day])[0])
                                                                            }</td>
                                                                            <td>{
                                                                                comments[day][Object.keys(comments[day])[0]][0]
                                                                            }</td>
                                                                        </tr>
                                                                        {
                                                                            mCnt > 1 &&
                                                                            Object.keys(comments[day]).slice(1).map(
                                                                                machineId => <tr
                                                                                    key={'mach' + machineId}>
                                                                                    <td style={{maxWidth: "200px"}}>{getMachineName(machineId)}</td>
                                                                                    <td>
                                                                                        {comments[day][machineId].map((i: string) =>
                                                                                            <p>{i}</p>)}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        })
                                                }
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    }
                </ModalBody>
            </Modal>
        </>
    )
}